import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setFlyout, setPanel } from '@Src/Global/Actions';
import { clearParams, setParams } from '@Src/Catalog/Actions';

// Components =>
import Button from '@Shared/Button';
import Keyword from '@Src/Search/Keyword';
import Categories from '@Src/Search/Categories';
import Prices from '@Src/Search/Prices';
import Materials from '@Src/Search/Materials';
import Colors from '@Src/Search/Colors';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Search = ({ clearParams, setFlyout, setPanel, setParams, catalog: { perpage } }) => {
    const navigate = useNavigate();
    const handleClear = () => {
        localStorage.removeItem('scrollY');
        setFlyout(false);
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            });
        });
        setPanel(false);
    }

    const handleSearch = () => {
        localStorage.removeItem('scrollY');
        setParams({
            keyword: document.getElementsByName('keyword')[0].value,
            start: 0,
            end: perpage
        }).then((nparams) => {
            setFlyout(false);
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams(nparams)}`
            });
            setPanel(false);
        });
    }

    return (
        <div className='flyout-search'>
            <div className='content-area'>
                <Keyword/>
                <Categories/>
                {/*<Prices/>*/}
                <div className='materials-and-colors'>
                    <Materials/>
                    {/*<Colors/>*/}
                </div>
                <div className='buttons-holder'>
                    <Button onClick={handleSearch} variant={'primary'}>Buscar</Button>
                    <Button onClick={handleClear} variant={'link'}>Borrar búsqueda avanzada</Button>
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setFlyout,
        clearParams,
        setParams,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Search);
