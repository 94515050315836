import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Components =>

const Colors = () => {
    const handleSelection = () => {

    }

    return (
        <div className='search-colors'>
            <h2>Filtrar por color</h2>
            <div className='product-variants'>
                <div className='variants'>
                    {['#001427','#FFC5EF','#0976AD','#5200FF','#FF005C','#001427','#FFC5EF','#0976AD','#5200FF','#FF005C','#001427','#FFC5EF'].map((c) => {
                        let selected = ''; //(variant === c) ? 'selected' : '';
                        return <button key={UUID()} className={'color ' + selected} onClick={() => handleSelection(c)} style={{ backgroundColor: c }} type="button"></button>;
                    })}
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Colors);
