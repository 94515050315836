import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Component

const Radio = ({ ...props }) => {
    let radioStyle = (props.checked) ? 'custom-radio checked' : 'custom-radio';
    return (
        <label className={radioStyle} onClick={props.callBack}>
            <div className='radio'></div>
            <span>{props.text}</span>
        </label>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Radio);
