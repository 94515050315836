import { API } from "@Src/Global/Actions"

export function getStores(getAll = false){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var state = getState();
            var { client, store, guest } = state.global;
            let cid = (client) ? client.data.id : guest;

            dispatch({
                type : 'MERGE-STORES-STATES',
                payload : {
                    'items' : false,
                }
            });

            // Getting stores from user location
            if(!getAll){
                API(`cart/get_region_stores.json?client=${cid}&store=${store}`).then((res) => {

                    dispatch({
                        type : 'MERGE-STORES-STATES',
                        payload : {
                            'items' : res,
                        }
                    });
                    resolve(res);
                });
            } else {
                // Getting stores in CDMX and GDL:
                let items = {};
                API(`cart/get_region_stores.json?client=${cid}&store=1`).then((res) => {
                    items.CDMX = res;
                    API(`cart/get_region_stores.json?client=${cid}&store=10`).then((res) => {
                        items.GDL = res;
                        dispatch({
                            type : 'MERGE-STORES-STATES',
                            payload : {
                                'items' : items,
                            }
                        });
                        resolve(items);
                    });
                });
            }
        });
    }
}