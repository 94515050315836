import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Icons
import { IoLogoFacebook } from 'react-icons/io';
import { FiInstagram } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';

const Social = ({ global: { phones }}) => {
    return (
        <div className='contact-area'>
            <h3>Contáctanos y síguenos</h3>
            <a href={`tel:${phones.tel.clean}`} className='phone'>Tel: {phones.tel.format}</a>
            <div className='social-links'>
                <a target="_blank" href="https://www.facebook.com/349110281827301/" className='social-link'>
                    <IoLogoFacebook color={'#898989'} size={26}/>
                </a>
                <a target="_blank" href="https://instagram.com/elements.steel?igshid=caennyqo3njj" className='social-link'>
                    <FiInstagram color={'#898989'} size={26}/>
                </a>
                <a target={'_blank'} href={`https://wa.me/${phones.whatsapp.clean}`} className='social-link'>
                    <FaWhatsapp color={'#898989'} size={26}/>
                </a>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Social);
