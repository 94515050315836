import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Components =>
import Search from '@Src/Faq/Search';

// Icons
import { HiOutlineChevronRight } from 'react-icons/hi';
import { IoLogoWhatsapp } from 'react-icons/io5';

// Constants
import { faqs } from '@Src/Faq/Constants';

const Faq = ({ global: { phones }}) => {
    const [keyword, setKeyword] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState(faqs[0].questions[0]);

    const handleKeyword = (v) => {
        setKeyword(v.toLowerCase());
    }

    const answerToString = (a) => {
        var answer = "";
        var children = a?.props?.children; //.props.children;
        if(children){
            if(typeof children === "string"){
                answer = children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            } else {
                children.forEach((pos) => {
                    if(typeof  pos === 'string')
                    answer += pos.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")+" ";
                });
            }
        }
        return answer;
    }

    return (
        <div id="faqs">
            <Search callBack={handleKeyword} keyword={keyword}/>
            <div className='questions-area'>
                <aside>
                    <h2>Preguntas frecuentes</h2>
                    {faqs.map((g) => {
                        return (
                            <div className='group' key={UUID()}>
                                <h3>{g.icon} {g.title}</h3>
                                {g.questions.map((q, idx) => {
                                    let isCurrent = (currentQuestion.q === q.q) ? 'q-item current' : 'q-item';
                                    return (
                                        (q.q.toLowerCase().search(keyword) !== -1 || answerToString(q.a).search(keyword) !== -1) ?
                                            <React.Fragment key={'q-'+idx}>
                                                <div className={isCurrent} onClick={() => setCurrentQuestion(q) }>
                                                    {q.q} <HiOutlineChevronRight color={'#505050'} size={18}/>
                                                </div>
                                                <div className={'a-item'}>{q.a}</div>
                                            </React.Fragment>
                                        : null
                                    );
                                })}
                            </div>
                        )
                    })}
                </aside>
                <div className='answer-holder'>
                    <h4>{currentQuestion.q}</h4>
                    {currentQuestion.a}
                    <div className='contact-holder'>
                        <h2>¿No encontraste lo que buscas?</h2>
                        <a href={`https://wa.me/${phones.whatsapp.clean}`} className='whatsapp'><IoLogoWhatsapp size={22}/> Envíanos un WhatsApp</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Faq);
