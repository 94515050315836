import React, { useState, useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal, currencyFormat } from '@Src/Global/Actions';
import { setCartQty } from '@Src/Cart/Actions';

// Toastify:
import { toast } from "react-toastify";

// Icons
import { HiMinusSm } from 'react-icons/hi';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { BsCartCheck } from 'react-icons/bs';

// Components
import Loading from '@Shared/Loading';

// Routing
import { useLocation } from 'react-router-dom';

const Increaser = ({ product, setModal, global: { discounts, materialgroups, wsize, modal }, setCartQty, cart: { basket },  debounce = false  }) => {
    const location = useLocation();
    const [adding, setAdding] = useState(false);
    const match = (basket) ? basket.items.filter((item) => item.CII === product.CII || item.CII === product.id )[0] : false; // Find the product inside the basket:

    const [qty, setQty] = useState((match) ? match.quantity : (product.quantity) ? product.quantity : 1);

    // Decides the discount depending on the product's material:
    var percent;
    var discount;
    var matDiscount = (materialgroups["steel"].includes(product.material)) ? 'steel' : 'lamin';
    if(!product.discount){
        percent = 0;
        discount = product["price"];
    } else {
        percent = discounts[matDiscount];
        discount = (product.price - (product.price * (percent / 100 )))
    }

    // Cuando existe esta propiedad, el descuento se evalua diferente:
    if(product.disc_prct !== undefined){
        percent = product.disc_prct;
        discount = (product.price - (product.price * (percent / 100 )))
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Trigger debounced cart qty update if qty is different to what we already have in cart:
            //if(!modal && ['/cart','/catalog'].includes(location.pathname) && match && (match.quantity !== qty)){
            if(debounce && match && (match.quantity !== qty)){
                handleAddCart();
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn)
    }, [qty]);


    const handleQty = (op) => {
        var newQty;
        if(op === '-'){
            newQty = (qty >= 1) ? (qty - 1) : 0;
        } else {
            if(product.cur_stock){
                newQty = ((qty + 1) <= product.cur_stock) ?  (qty + 1) : product.cur_stock;
            } else {
                newQty = (qty + 1);
            }
        }
        setQty(newQty);
    }

    const handleAddCart = () => {
        setAdding(true);

        // Let this to be processed in background
        setCartQty(product, qty).then((res) => {
            toast[res.type](res.message);
            setAdding(false);
        });

        // Closes the modal without waiting for the response:
        //setModal(false);
    }

    return (
        <React.Fragment>
            <div className='product-increaser'>
                <label>Cantidad:</label>
                <div className='container'>
                    <button onClick={() => handleQty('-')}><HiMinusSm color={'#C4C4C4'} size={32}/></button>
                    <span>{qty}</span>
                    <button onClick={() => handleQty('+')}><BiPlus color={'#C4C4C4'} size={32}/></button>
                </div>
            </div>
            { !adding ?
                <div className='product-buttons'>
                    <button type={'button'} className={'btn secondary'} onClick={handleAddCart}>
                        <span>
                            {(!match) ?
                                'Añadir al carrito'
                            :
                                <React.Fragment><BsCartCheck color={'#FFF'} size={18} style={{ marginRight: '5px' }}/>Actualizar en canasta</React.Fragment>
                            }
                        </span>
                        <small>{currencyFormat(discount * qty)}</small>
                    </button>
                    <a onClick={() => setModal(false) }>Cancelar</a>
                    <legend><span>{(location.pathname.search('/product') !== -1) ? 'Total a añadir' : 'Total' }</span> <b>{currencyFormat(discount * qty)}</b></legend>
                </div>
            : <Loading size={16}/> }
        </React.Fragment>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
        setCartQty,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Increaser);
