import { API } from "@Src/Global/Actions";

// Get product data:
export function getProduct(id){
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            var state = getState();
            var client = (state.global.client) ? state.global.client.data.id : 2;
            API(`product/read.json?rid=${id}&client=${client}&store=${state.global.store}`).then((res) => {
                if(res.res_value && res.res_value === -1){
                    window.location = '/catalog';
                } else {
                    // Default Variant:
                    if(res.item.grouping){
                        Object.keys(res.item.grouping.groups[0].items).forEach((i) => {
                            var v = res.item.grouping.groups[0].items[i];
                            if(v.is_main)
                            res.item.variant = v;
                        });
                        //res.item.variant = res.item.grouping.groups[0].items[0];
                    }

                    // Default variant for related products:
                    if(typeof res.related === 'object' && Object.keys(res.related).length > 0){
                        var related;
                        Object.keys(res.related).forEach((key) => {
                            related = res.related[key];
                            if(related.grouping){
                                Object.keys(related.grouping.groups[0].items).forEach((i) => {
                                    var v = related.grouping.groups[0].items[i];
                                    if(v.is_main)
                                    res.related[key].variant = v;
                                });
                                //res.related[key].variant = related.grouping.groups[0].items[0];
                            }
                            res.related[key].idx = key;
                        });
                    }
                    resolve(res);
                }
            });
        });
    }
}