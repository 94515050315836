import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { logOut } from '@Src/Login/Actions';
import { setPanel, getInitials } from '@Src/Global/Actions';

// Components =>
import Button from '@Shared/Button';

// Icons:
import { IoDiamond } from 'react-icons/io5';

// Toastify
import { toast } from 'react-toastify';

// Routing
import { useNavigate } from 'react-router-dom';

const Card = ({ logOut, setPanel, global: { client }, getInitials }) => {
    const navigate = useNavigate();
    const [initials, setInitials] = useState(false);

    useEffect(() => {
        setInitials(getInitials(client.alias));
    }, []);

    const handleLogOut = () => {
        logOut().then(() => {
            setPanel(false);
            navigate('/');
            toast.info('Se ha cerrado tu sesión de cliente.');
        });
    }

    return (
        client ?
            <div className='user-card'>
                <div className='img-holder'>
                    {initials ? <div className='initials'>{initials}</div> : null }
                    <div className='icon-holder'>
                        <IoDiamond color={'#F78721'} size={18}/>
                    </div>
                </div>
                <div className='info-holder'>
                    <div className='userid-and-tag'>
                        <div className='titles'>
                            <h2>{client?.alias}</h2>
                            <h3>ID: 0000-{client?.data?.id}</h3>
                            <Button variant={'link'} onClick={handleLogOut}>Cerrar sesión</Button>
                        </div>
                        <div className='tag'><IoDiamond color={'#F78721'} size={14}/>  Premium</div>
                        {/*<Button variant={'link btn-edit'}>Editar datos</Button> */}
                    </div>
                </div>
                <div className='table-row'>
                    <div className='table-col'>
                        <label>Estatus</label>
                        <span>activo</span>
                    </div>
                    <div className='table-col'>
                        <label>E-mail</label>
                        <span>{client.data.email}</span>
                    </div>
                    <div className='table-col'>
                        <label>Teléfono</label>
                        <span>{client.data.phone}</span>
                    </div>
                    <div className='table-col'>
                        <label>Descuento</label>
                        <span>{client.data.steel_discount}%</span>
                    </div>
                </div>
            </div>
        : null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        logOut,
        setPanel,
        getInitials,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Card);
