import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { clearParams } from '@Src/Catalog/Actions'

// Components =>
import Button from '@Shared/Button';

// Assets
import Banner from '@Assets/home/promote/banner.jpg';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Promote = ({ clearParams }) => {
    const navigate = useNavigate();

    const handleNavigate = (c) => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            });
        });
    }

    return (
        <div className='home-promote'>
            <img src={Banner}/>
            <div className='texts'>
                <h2>Detalles que marcan la diferencia</h2>
                <p>Encuentra el regalo ideal para cada ocasión.</p>
                <Button onClick={handleNavigate} variant={'tertiary'}>Ver más productos</Button>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        clearParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Promote);
