import React, { useRef } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setPanel } from '@Src/Global/Actions';

// Swipeable
import { useSwipeable } from 'react-swipeable';

// Icons
import { BiUser } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';

const Panel = ({ setPanel, global: { panel, client } }) => {
    let overlayRef = useRef(null);

    const swiperHandlers = useSwipeable({
        onSwipedLeft: () => {
            setPanel(false);
        },
    });

    const handlOverlayClick = (e) => {
        if(e.target === overlayRef.current){
            setPanel(false);
        }
    }
    return (
        <div id='side-panel' ref={overlayRef} onClick={handlOverlayClick}>
            <button type="button" className='button-helper'>
                <BiUser size={22} color={'#505050'}/>
                <span>{!client ? 'Iniciar sesión' : 'Mi cuenta' }</span>
            </button>
            <div className='side-content' {...swiperHandlers}>
                {panel}
                {/*
                    Close button panel is put after the content, so we can control with CSS
                    (sibling selector) when it needs to be hidden (like in Product panel)
                */}
                <button className={'close-btn'} onClick={() => setPanel(false) }>
                    <MdClose size={24}/>
                </button>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Panel);
