import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

// Assets
import Tiempo from '@Assets/business/why/icon-tiempo.png';
import Recupera from '@Assets/business/why/icon-recupera.png';
import Atencion from '@Assets/business/why/icon-atencion.png';
import Crece from '@Assets/business/why/icon-crece.png';

const Why = ({ }) => {
    return (
        <div className='business-why'>
            <h2>¿Por qué vender joyería?</h2>
            <div className='why-block'>
                <section>
                    <img src={Tiempo}/>
                    <p>Puedes hacerlo en tu tiempo libre</p>
                </section>
                <section>
                    <img src={Recupera}/>
                    <p>Rápida recuperación de tu inversión</p>
                </section>
                <section>
                    <img src={Atencion}/>
                    <p>Serás siempre el centro de atención</p>
                </section>
                <section>
                    <img src={Crece}/>
                    <p>Puedes obtener ganancias de más de 100%</p>
                </section>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Why);
