import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Icons
import Mision from '@Assets/about/vmv/mision.png';
import Valores from '@Assets/about/vmv/valores.png';
import Vision from '@Assets/about/vmv/vision.png';

const VMV = ({ }) => {
    return (
        <div className='about-vmv'>
            <div className='item'>
                <img src={Vision}/>
                <div className='texts'>
                    <h2>Visión</h2>
                    <p>Ser La Marca que lidere las tendencias en el mundo de la Joyería independientemente de las temporadas, las fronteras o las culturas.</p>
                </div>
            </div>
            <div className='item'>
                <img src={Mision}/>
                <div className='texts'>
                    <h2>Misión</h2>
                    <p>Crear constantemente joyería que refleje identidad y autenticidad, que marque tendencia. Ser considerados una herramienta sólida de crecimiento para nuestros clientes, personal y socios por largo tiempo.</p>
                </div>
            </div>
            <div className='item'>
                <img src={Valores}/>
                <div className='texts'>
                    <h2>Valores</h2>
                    <p>Identidad, Autenticidad, Integridad, Empatía, Versatilidad, Compromiso, Excepcionales.</p>
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(VMV);
