import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal } from '@Src/Global/Actions';
import { setCartQty } from '@Src/Cart/Actions';

// Components =>
import Popup from '@Src/Product/Popup';
import Discount from '@Src/Product/Discount';
import Variants from '@Src/Product/Variants';
import Prices from '@Src/Product/Prices';
import Texts from '@Src/Product/Texts';
import Gallery from '@Src/Product/Gallery';
import PopDetail from '@Src/Product/PopDetail';
import Loading from '@Shared/Loading';
import Increaser from '@Src/Product/Increaser';

const Item = ({ setModal, setCartQty, product, cart: { basket }, global: { wsize } }) => {
    const [variant, setVariant] = useState(product);
    const [adding, setAdding] = useState(false);

    const callBack = (replacement) => {
        // Clean rid from object
        if(replacement.rid){
            replacement.id = replacement.rid;
            delete replacement.rid;
        }
        setVariant(Object.assign({}, variant,  replacement));
    }

    const match = (basket) ? basket.items.filter((item) => item.CII === variant.CII || item.CII === variant.id )[0] : false; // Find the product inside the basket:

    const handleAddProduct = () => {
        setAdding(true);
        if(!match){
            // Product is not in cart:
            setCartQty(product, 1).then(() => {
                setAdding(false);
            });
        } else {
            // Product is in cart:
        }
        /*
        if(wsize.w > 1080){
            setModal(<Popup product={variant} callBack={callBack}/>)
        } else {
            // By closing the modal first we achieve the effect for mobile when we are already in modal.
            setModal(false);
            setTimeout(() => {
                setModal(<PopDetail pid={variant.id}/>);
            }, 100);
        }
        */
    }

    const increaserStyles = (match) ? 'product-increaser-holder in-cart' : 'product-increaser-holder';


    return (
        <div className='product-item'>
            <Gallery product={variant} zoom={false}/>
            <Discount product={variant}/>
            { product.grouping && product.grouping.groups.length > 0 ? <Variants product={variant} callBack={callBack}/> : null }
            <Texts product={variant}/>
            <Prices product={variant}/>
            <div className={increaserStyles}>
                {!adding ?
                    <Increaser product={variant} debounce={true}/>
                : <Loading size={16}/> }
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
        setCartQty,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Item);
