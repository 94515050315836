import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, setPanel } from '@Src/Global/Actions';
import { clearParams } from '@Src/Catalog/Actions';
import { getOrders } from '@Src/Orders/Actions';

// Components =>
import Loading from '@Shared/Loading';
import Button from '@Shared/Button';
import Template from '@Src/User/Template';
import Order from '@Src/Orders/Order';
import Keyword from '@Src/Search/Keyword';
import PanelUser from '@Src/Panels/User';

// Routing
import { createSearchParams, useNavigate } from 'react-router-dom';

// Icons
import { BsChevronLeft } from 'react-icons/bs';

const Orders = ({ getOrders, orders: { items }, catalog: { params }, global: { wsize }, setPanel,  }) => {
    const navigate = useNavigate();

    useEffect(() => {
        getOrders();
    }, []);

    const handleSearch = () => {
        navigate({
            pathname: '/catalog',
            search: `?${createSearchParams(params)}`
        });
    }

    const handleBack = () => {
        if(wsize.w < 1080){
            setPanel(<PanelUser/>);
        } else {
            return false;
        }
    }

    return (
        <Template>
            <h1 onClick={handleBack}>Mis pedidos <BsChevronLeft size={18}/></h1>
            { items ?
                items.length > 0 ?
                    items.map((order) => {
                        return (
                            <Order key={UUID()} order={order}/>
                        )
                    })
                :
                    <div className='nothing'>
                        <h2>¡Realiza tu primer pedido!</h2>
                        <p>Comienza a navegar en nuestra tienda y conoce nuestros productos</p>
                        <Keyword/>
                        <Button variant={'primary'} onClick={handleSearch}>Buscar</Button>
                    </div>
            : <Loading/>}
        </Template>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getOrders,
        clearParams,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Orders);
