import { logOut } from '@Src/Login/Actions';

// API Call Wrapper:
export function API(url, method, body, headers = true){
    return new Promise((resolve) => {
        var obj = {'method' : method};
        const token = (localStorage.getItem('token')) ? localStorage.getItem('token') : false;
        const fingerprint = (localStorage.getItem('fingerprint')) ? localStorage.getItem('fingerprint') : false;

        // Add headers:
        if (headers){
            obj.headers = {
                'EOS-TOKEN' : token,
                'EOS-FINGERPRINT' : fingerprint,
                'EOS-DOMAIN' : document.location.host, // <= This defines the domain url replacement for mails ...
            };
        }

        let ENV = (window.location.hostname === 'elements-of-steel.com.mx' || window.location.hostname === 'elements.style') ? 'PROD' : 'TEST';
        //ENV = 'PROD'; // Forcing to prod since test_api is already changed...
        var ENDPOINT = (ENV === 'PROD') ? 'https://businext.com.mx/api/' : 'https://businext.mx/api_test/';

        // Build url for debugging:
        var urlParams = '';
        if(body && typeof body === 'object'){
            Object.keys(body).forEach((key) => {
                urlParams += key + '=' + body[key] + '&';
            });
        }

        if(url.search('catalog') !== -1){
            //console.log('API =========>', ENDPOINT + url + '?' + urlParams);
        }

        // Add method, or GET by default:
        if (method === 'POST'){
            obj.body = JSON.stringify(body);
            obj.headers['Content-type'] = 'application/json; charset=UTF-8';
        }

        fetch(ENDPOINT + url, obj).then(res => res.json()).then(json => {
            resolve(json);
        }).catch((error) => {
            let errorObj = {
                'api-error' : error,
                'endpoint' : ENDPOINT + url + '?' + urlParams,
                'params'   : obj,
            };

            console.error('Error ===>', errorObj.endpoint);
            resolve(errorObj);
        });
    });
}



// UUID Generator:
export function UUID() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}


// windowSize
export function windowSize(){
    return (dispatch) => {
        let scrollHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight,document.body.offsetHeight, document.documentElement.offsetHeight,document.body.clientHeight, document.documentElement.clientHeight);

        dispatch({
            type 	: 'MERGE-GLOBAL-STATES',
            payload	: {
                'wsize' : {
                    'w' : window.innerWidth,
                    'h' : scrollHeight,
                },
            },
        });
    };
}


// Currency format:
export function currencyFormat(num, decimals = 2) {
    return (num) ? '$' + num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '$0.00';
}



// Set Device FingerPrint
export function setFingerprint(fingerprint){
    return (dispatch) => {
        localStorage.setItem('fingerprint', fingerprint);
        dispatch({
            type 	: "MERGE-GLOBAL-STATES",
            payload	: {
                "fingerprint": fingerprint
            }
        });
    }
}



// Debounce function helper:
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};




// Get client information by its token
// Normally the token will be in Storage, but when is recieved (comming from login action), then we proceed to store it:
export function isValidToken(token = false){
    return (dispatch) => {
        return new Promise((resolve, reject) => {

            if(token)
            localStorage.setItem('token', token);

            if(token){
                API(`client/isvalidtoken.json`).then((res) => {
                    if((res.error && res.token) || res["api-error"]){
                        dispatch(logOut())
                        resolve(res);
                    } else {
                        const client = {
                            "token": res.token,
                            "alias": res.alias,
                            "data" : res.client,
                        };
                        localStorage.setItem("client", JSON.stringify(client));
                        dispatch({
                            type 	: "MERGE-GLOBAL-STATES",
                            payload	: {
                                "client" : client,
                            }
                        });
                        resolve(res);
                    }
                });
            } else {
                resolve({});
            }
        });
    }
}


// Set the current route:
export function setRoute(route){
    return (dispatch) => {
        localStorage.setItem('route', route);
        dispatch({
            type 	: "MERGE-GLOBAL-STATES",
            payload	: {
                "route" : route,
            }
        });
    }
}


// Resuelve el path de imágenes dependiendo de si tienen o no diagonal al final
export function imagePathResolver(path, large = false){
    let fileSrc;

    if(path.search('NO_IMAGE.jpg') !== -1){
        fileSrc = path;
    } else if(path.endsWith('/')){
        fileSrc = path+'0_medium.webp';
    } else {
        fileSrc = path+'/0_medium.webp';
    }

    if(large){
        fileSrc = fileSrc.replace('0_medium.webp', '0_large.webp');
    }
    return fileSrc;
}


// Get store name by its id:
export function storeById(id = false){
    return (dispatch, getState) => {
        var state = getState();
        var storeName = "UNKNOWN";

        id = (id) ? id : state.global.store;
        id = parseInt(id);

        if(id === 1)
        storeName = "CDMX";

        if(id === 6)
        storeName = "OUTLET CDMX";

        if(id === 10)
        storeName = "GDL";

        if(id === 9)
        storeName = "OUTLET GDL";

        return storeName;
    }
}


// Get store name by its id:
export function changeStore(id){
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            id = parseInt(id);
            localStorage.setItem('store', id);
            dispatch({
                type 	: "MERGE-GLOBAL-STATES",
                payload	: {
                    "store" : id
                }
            });
            resolve(id);
        });
    }
}

// Random String
export function randStr(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


// Set distances from  current location to GDL and CDMX stores:
export function setStoreDistances(gdl, cdmx){
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type 	: "MERGE-GLOBAL-STATES",
                payload	: {
                    "store-distances": {
                        "GDL"   : gdl,
                        "CDMX"  : cdmx
                    }
                }
            });

            resolve();
        });
    }
}


// Check if the user has already selected a Store preference:
export function hasStore(){
    return (dispatch, getState) => {
        var state = getState();
        var client = state.global.client;
        // Rules, the pop should appear when:
        /*
            1) When is a guest user & there is no preferred-store value in localStorage
            2) When is a customer that doesn't have a region assigned & there is no preferred-store value in localStorage
        */

        /*
        if(!localStorage.getItem('preferred-store')){
            if(!client){
                dispatch(toggleDialogStore());
            } else {
                if(!client.region || client.region === null)
                dispatch(toggleDialogStore());
            }
        }
        */
    }
}

export function currentCatalog(){
    return (dispatch, getState) => {
        var state = getState();
        let { params } = state.catalog;

        let catalog = 'Catálogo General';

        if(params.store === 9)
        catalog = 'Outlet GDL';

        if(params.store === 6)
        catalog = 'Outlet CDMX';

        if(params.web_catalog && params.web_catalog === 2)
        catalog = 'Última colección';


        if(params.web_catalog && params.web_catalog === 3)
        catalog = 'Ofertas Flash';

        return catalog;
    }
}


export function setModal(component) {
    return (dispatch) => {
        dispatch({
            type : 'MERGE-GLOBAL-STATES',
            payload : {
                'modal' : component,
            }
        });
    }
}

export function setFlyout(component) {
    return (dispatch) => {
        dispatch({
            type : 'MERGE-GLOBAL-STATES',
            payload : {
                'flyout' : component,
            }
        });
    }
}

export function setPanel(component) {
    return (dispatch) => {
        dispatch({
            type : 'MERGE-GLOBAL-STATES',
            payload : {
                'panel' : component,
            }
        });
    }
}


export function getInitials(name){
    return () => {
        let i = 0;
        let initials = '';
        let words = name?.split(' ');
        if(typeof words === 'object'){
            words.forEach((w) => {
                i ++;
                if(i <= 2){
                    initials += w.charAt(0).toUpperCase();
                }
            });
        } else {
            initials = ':)'
        }
        return initials;
    }
}


export function setHeaderVisibility(visibility){
    return (dispatch) => {
        dispatch({
            type : 'MERGE-GLOBAL-STATES',
            payload : {
                hideHeader : visibility,
            }
        });
    }
}


export function setPreivousRoute(route){
    return (dispatch) => {
        dispatch({
            type : 'MERGE-GLOBAL-STATES',
            payload : {
                'prevRoute' : route,
            }
        });
    }
}