import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>
import Holder from '@Src/Cart/Methods/Holder';

// Assets
import SevenEleven from '@Assets/logo-7eleven.png';
import Oxxo from '@Assets/logo-oxxo.png';
import BBVA from '@Assets/logo-bbva.png';
import Santander from '@Assets/logo-santander.png';

// Icon
import { IoDiamond } from 'react-icons/io5';

const Cash = ({ ...props }) => {
    return (
        <Holder
            {...props}
            header={
                <h2><IoDiamond color={'#EB6B2F'}/> Efectivo en establecimientos</h2>
            }
            content={
                <React.Fragment>
                    <div className='method-block'>
                        <h2>Establecimientos donde puedes pagar:</h2>
                        <div className='logos-holder'>
                            <img src={SevenEleven}/>
                            <img src={Oxxo}/>
                        </div>
                        <p>En ambos establecimientos te pedirán un cobro extra por comisión del servicio.</p>
                        <h3>En caja menciona que harás un deposito y elige una de las siguientes cuentas:</h3>
                        <div className='banks-holder'>
                            <div className='bank-row'>
                                <img src={BBVA}/> <span>4555 1030 0691 8935</span>
                            </div>
                            <div className='bank-row'>
                                <img src={Santander}/> <span>5579 0700 3802 8943</span>
                            </div>
                        </div>
                    </div>
                    <div className='method-block'>
                        <div className={'gray-box'}>
                            <h2>Información importante</h2>
                            <p>· Asegurate de hacer el pago de tu pedido antes de las 18:00 hrs del mismo día</p>
                            <p>· Una vez hecha la reservación de tu pedido este no puede cancelarce.</p>
                        </div>
                    </div>
                </React.Fragment>
            }
        />
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Cash);
