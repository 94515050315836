import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { clearParams } from '@Src/Catalog/Actions';

// Components =>
import Button from '@Shared/Button';

// Assets
import Banner from '@Assets/business/subanner.png';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Subanner = ({ clearParams }) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            });
        });
    }
    return (
        <div className='business-subanner'>
            <div className='texts'>
                <h2>¡No esperes más!</h2>
                <p>Estaremos contigo siempre para que identifiques los productos de tus clientes potenciales</p>
                <div className='buttons-holder'>
                    <Button onClick={() => navigate('/register') } variant={'tertiary'}>Registrarme</Button>
                    <Button onClick={handleNavigate} variant={'primary'}>Empezar a comprar</Button>
                </div>
            </div>
            <img src={Banner}/>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        clearParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Subanner);
