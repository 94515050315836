import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { setParams } from '@Src/Catalog/Actions';

// Components =>
import Checkbox from '@Shared/Checkbox';

// Routing
import { useSearchParams } from 'react-router-dom';

// Icons
import { TbChevronRight } from 'react-icons/tb';

const Item = ({ item, setParams, catalog: { params, perpage } }) => {
    const [urlParams, setUrlParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    let checked, text;

    const handleOpen = () => {
        localStorage.removeItem('scrollY');
        setOpen(!open);
        if(open){
            let checkboxes = document.querySelectorAll('[type="checkbox"][name*="filters."]:checked');
            let key = item.group;
            let val = '';
            checkboxes.forEach((chk) => {
                key = chk.getAttribute('name').split('.')[1];
                val += chk.getAttribute('value') + ',';
            });
            val =  val.replace(/(^,)|(,$)/g, ""); // Remove trailing commas

            setParams({
                [key]: val,
                start: 0,
                end: perpage
            }).then((nparams) => {
                setUrlParams(nparams);
            });
        }
    }

    const checks = (params[item.group]) ? params[item.group].split(',') : [];

    return (
        <React.Fragment>
            { open ? <div className='overlay-layer' onClick={handleOpen}></div> : null }
            <div className={open ? 'item open' : 'item'}>
                <button onClick={handleOpen}>
                    <span>{item.label} ({checks.length})</span> <TbChevronRight size={22} color={'#1289C5'}/>
                </button>
                {open ?
                    <div className={'box'}>
                        {item.options.map((opt) => {
                            text = opt?.label || opt?.ext_name || opt?.name;
                            checked = (params[item.group] && checks.includes(text)) ? true : false;
                            return <Checkbox checked={checked} name={`filters.${item.group}`}  key={UUID()} text={text}/>
                        })}
                    </div>
                : null}
            </div>
        </React.Fragment>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Item);
