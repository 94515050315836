import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions =>
import { getInitials, UUID } from '@Src/Global/Actions';
import { saveOpinion } from '@Src/Opinions/Actions';

// Swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// Toastify:
import { toast } from "react-toastify";

// Components =>
import Textarea from '@Shared/Textarea';
import Stars from '@Src/Product/Stars';
import Loading from '@Shared/Loading';

// Icons
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

const Opinions = ({ product, global: { wsize, client }, getInitials, saveOpinion, callBack }) => {
    const [stars, setStars] = useState(0);
    const [open, setOpen] = useState(false);
    const limit = 500;

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        rating: Yup.number().required('Requerido.'),
        comment: Yup.string()
            .max(limit, 'La cantidad de caracteres excede el límite (100 caracteres)')
            .required('Requerido'),
    });


    const renderOpinions = () => {
        if (wsize.w > 1080){
            return (
                <div className='opinions'>{renderItems()}</div>
            )
        } else {
            return (
                <Swiper
                    className='opinions'
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplay={true}
                    modules={[Navigation]}
                    navigation={{ nextEl: "#opinion-next", prevEl: "#opinion-back" }}
                >{renderItems()}</Swiper>
            )
        }
    }


    const renderItems = () => {
        let items = [];
        const renderItem = (opinion) => {
            return (
                <React.Fragment>
                    <div className='img-holder'>
                    </div>
                    <div className='info-holder'>
                        <h2>{opinion.client.username}</h2>
                        <Stars callBack={null} stars={opinion.client_product_rating.rating}/>
                        <p>{opinion.client_product_rating.comment}</p>
                    </div>
                </React.Fragment>
            )
        }
        product.item.ratings.map((opinion) => {
            let item = (wsize.w > 1080) ?
                <div className='opinion' key={UUID()}>
                    {renderItem(opinion)}
                </div>
            :
                <SwiperSlide className={'opinion'} key={UUID()}>
                    {renderItem(opinion)}
                </SwiperSlide> ;

            items.push(item);
        })
        return items;
    }


    const renderButtons = (isSubmitting, handleSubmit) => {
        return(
            client ?
                !open ?
                    <button className={'btn tertiary'} onClick={() => setOpen(!open) }>Añadir comentario</button>
                :
                    !isSubmitting ?
                        <React.Fragment>
                            <button className={'btn link'} onClick={() => setOpen(!open) }>Cancelar</button>
                            <button className={'btn secondary'} onClick={handleSubmit}>Publicar</button>
                        </React.Fragment>
                    : <Loading/>
            : null
        );
    }

    return (
        <Formik
            initialValues={{
                rating: '',
                comment: '',
                item: product.item.id, // El id del producto para el cual se otorga la calificación
            }}
            validationSchema={YupSchema}
            onSubmit={(values,  { setSubmitting }) =>{
                saveOpinion(values).then((res) => {
                    // Retrieve product again to show new opinion
                    callBack().then(() => {
                        toast[res.type](res.message);
                        setSubmitting(false);
                        setOpen();
                    });
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <div className='holder-opinions'>
                    <div className='content'>
                        <div className='title-and-button'>
                            <h2>Opiniones de compradores</h2>
                            <div className='tools'>
                                { renderButtons(isSubmitting, handleSubmit) }
                                {product.ratings ?
                                    <div className='controllers'>
                                        <button id={'opinion-back'}>
                                            <HiOutlineChevronLeft color={'#C4C4C4'} size={32}/>
                                        </button>
                                        <button id={'opinion-next'}>
                                            <HiOutlineChevronRight color={'#C4C4C4'} size={32}/>
                                        </button>
                                    </div>
                                : null }
                            </div>
                        </div>
                        {/* Form */}
                        {open ?
                            <div className={'opinion-form box'}>
                                <div className={'img-holder'}>{getInitials(client.alias)}</div>
                                <div className={'opinion-area'}>
                                    <h2>¿Cuántas estrellas le otorgas?</h2>
                                    <Stars stars={stars} callBack={(s) => {
                                        setStars(s);
                                        setFieldValue('rating', s);
                                    }}/>
                                    <Textarea
                                        placeholder  = {'Ingresa tu comentario:'}
                                        name         = {'comment'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        onFocus      = {handleBlur}
                                        errors       = {errors}
                                        touched      = {touched}
                                    />
                                </div>
                            </div>
                        : null }
                    </div>

                    {product.item.ratings ?
                        !open ? renderOpinions() : null
                    : null }

                    <div className='bottom-button'>
                        { renderButtons(isSubmitting, handleSubmit) }
                    </div>
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getInitials,
        saveOpinion,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Opinions);
