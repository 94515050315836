import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, storeById } from '@Src/Global/Actions';

// Components =>
import Item from '@Src/Cart/Item';

// Icons
import { TiLocationOutline } from 'react-icons/ti';

const List = ({ cart: { basket }, global: { store }, storeById }) => {
    return (
        <div className='cart-list'>
            <div className='cart-list-header'>
                <TiLocationOutline size={18} color={'#CCC'}/>
                <h2>Productos de <b>Tienda {storeById(store)}</b></h2>
            </div>
            <div className='cart-list-content'>
                { basket && basket.items ?
                    basket.items.map((product) => {
                        return <Item key={UUID()} product={product}/>
                    })
                : null }
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        storeById,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(List);
