import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const Loading = ({ size = 32, fullsize = false }) => {
    return (
        <div id="loading" className={(fullsize) ? 'fullsize' : '' }>
            <i className="dash-spinner-32x32" style={{ backgroundSize: size+'px', width: size+'px', height: size+'px' }}/>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Loading);
