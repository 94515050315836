import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { getCleanCatalog, setParams } from '@Src/Catalog/Actions';

// Components =>
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

const Categories = ({ catalog: { categories, params }, getCleanCatalog, setParams }) => {
    const handleSelection = (cat) => {
        let selections = (params.category) ? params.category.split(',') : [];
        if(selections.includes(cat)){
            var index = selections.indexOf(cat);
            if (index !== -1) {
                selections.splice(index, 1);
            }
        } else {
            selections.push(cat);
        }
        setParams({
            category: selections.join(',')
        });
    }

    useEffect(() => {
        if(!categories){
            getCleanCatalog();
        }
    }, []);

    return (
        <div className='search-categories'>
            <h2>Sólo buscar en:</h2>
            { categories ?
                categories.map((c) => {
                    let variant = 'default';
                    if(params.category && params.category.search(c.ext_name) !== -1){
                        variant = 'secondary';
                    }
                    return <Button onClick={() => handleSelection(c.ext_name) } key={UUID()} variant={variant}>{c.ext_name}</Button>
                })
            : <Loading/> }
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getCleanCatalog,
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Categories);
