import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions
import { setModal, setPanel } from '@Src/Global/Actions';
import { passRecover } from '@Src/Login/Actions';

// Components =>
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

// Toast
import { toast } from 'react-toastify';

const Forgot = ({ passRecover, setModal, setPanel }) => {
    const [modalStyles, setModalStyles] = useState('forgot-password');

    useEffect(() => {
        setModalStyles('forgot-password slide');
    }, []);

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        username: Yup.string().required('Es necesario indicar un e-mail o celular.'),
    });

    return (
        <Formik
            initialValues={{
                username: '',
            }}
            validationSchema={YupSchema}
            onSubmit={(values, { setSubmitting, resetForm }) =>{
                passRecover(values).then((res) => {
                    setSubmitting(false);
                    resetForm();
                    if(!res.error){
                        toast.success(res.message);
                        setModal(false);
                        setPanel(false);
                    } else {
                        toast.error(res.message);
                    }
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div className={modalStyles}>
                    <h2>Reestablecer contraseña</h2>
                    <p>Escribe tu e-mail ó número de celular y te enviaremos por email y/o SMS los pasos para reestablecer tu contraseña.</p>
                    <Input
                        label        = {'E-mail o celuar'}
                        placeholder  = {'E-mail o celuar:'}
                        name         = {'username'}
                        values       = {values}
                        onChange     = {handleChange}
                        errors       = {errors}
                        onFocus      = {handleBlur}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    { !isSubmitting ? <Button variant={'secondary'} onClick={handleSubmit}>Recuperar acceso</Button> : <Loading/> }
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        passRecover,
        setModal,
        setPanel
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Forgot);
