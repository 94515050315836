import update from 'immutability-helper';

export const orders = (state = {
    items: false,
    fields: [],
    error: false,
    current: false,
}, action) => {
    switch (action.type) {
		case "MERGE-ORDERS-STATES":
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state
};