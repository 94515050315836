import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, imagePathResolver, setModal } from '@Src/Global/Actions';
import { getCatalog, clearParams } from '@Src/Catalog/Actions';

// Components =>
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import PopDetail from '@Src/Product/PopDetail';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Special = ({ catalog: { products }, global: { wsize }, getCatalog, clearParams, setModal }) => {
    const navigate = useNavigate();
    const [shuffled, setShuffled] = useState(false);

    useEffect(() => {
        new Promise((resolve) => {
            if(products){
                resolve(products);
            } else {
                getCatalog().then((res) => {
                    resolve(res.items);
                });
            }
        }).then((items) => {
            setShuffled(items.sort(() => 0.5 - Math.random()).slice(0, 3));
        });
    }, []);


    const handleNavigate = (c) => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            });
        });
    }


    const handleNavigateProductDetail = (product) => {
        let pid = (product.rid) ? product.rid : product.id;
        setModal(false);
        if(wsize.w > 1080){
            navigate(`/product/${pid}`, {state: {product: product}});
        } else {
            setTimeout(() => {
                setModal(<PopDetail pid={pid} data={product}/>);
            }, 100);
        }
    }

    return (
        <div className='home-special'>
            <div className='column'>
                <h2>Haciendo tendencia</h2>
                <p>Que tu presencia sea palpable a donde quiera que vayas.</p>
                <Button onClick={handleNavigate} variant="tertiary">Ver más productos</Button>
            </div>
            <div className='column'>
                <div className='random-products'>
                    { shuffled ?
                        shuffled.map((product) => {
                            return (
                                <div className='product-holder' key={UUID()} onClick={() => handleNavigateProductDetail(product)}>
                                    <div className='img-holder' style={{ backgroundImage: `url("${imagePathResolver(product.image_url, false)}")` }}>
                                    </div>
                                </div>
                            )
                        })
                    : <Loading/> }
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getCatalog,
        clearParams,
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Special);
