import { API } from '@Src/Global/Actions';
import { defaultAddress } from '@Src/Cart/Actions';

export function getAddresses(){
    return (dispatch, getState) => {
        var state = getState();
        var {client, guess } = state.global;
        let cid = (client) ? client.data.id : guess;
        return new Promise((resolve) => {
            API(`/client_address/read.json?client=${cid}`).then((res) => {

                dispatch({
                    type : 'MERGE-ADDRESSES-STATES',
                    payload : {
                        'items' : res.addresses,
                    },
                });

                // Define la dirección preferida del usuario y se la asigna al pedido actual:
                dispatch(defaultAddress());

                resolve(res.addresses);
            });
        });
    };
}


// Handle address submit:
export function saveAddress(values){
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            var state = getState();
            var {client, route} = state.global;
            var redirect = false;

            if (route  === 'AddDeliveryAddress'){
                redirect = 'Cart-Step-1';
            }

            values.client = client.data.id;
            values.default_address = 'F';

            if(values.id){
                values.rid = values.id;
            }

            let title = 'Mis Direcciones';
            API('client/validate_address.json', 'POST', values).then((res) => {
                if (res.error){
                    resolve({
                        type: 'Error',
                        title,
                        message: res.error,
                        redirect,
                    });
                } else {
                    var action = (values.rid) ? 'client_address/update.json' : 'client_address/create.json';
                    API(`${action}`, 'POST', values).then((a) => {
                        // Vuelve a obtener las direcciones:
                        dispatch(getAddresses()).then(() => {
                            //dispatch(defaultAddress()).then(() => {
                                resolve({
                                    type: 'Success',
                                    title,
                                    message: `La dirección "${values.locality}" ha sido almacenada corréctamente y seleccionada como dirección de entrega.`,
                                    redirect,
                                });
                            //});
                        });
                    });
                }
            });
        });
    };
}


export function deleteAddress(addressID){
    return (dispatch, getState) => {
        var state = getState();
        var { client } = state.global;
        return new Promise((resolve) => {
            API(`client_address/delete.json?client=${client.data.id}&rid=${addressID}`).then(() => {
                dispatch(getAddresses()).then(() => {
                    resolve();
                });
            });
        });
    };
}


export function setCurrent(address){
    return (dispatch) => {
        localStorage.setItem('address', JSON.stringify(address));
        dispatch({
            type : 'MERGE-ADDRESSES-STATES',
            payload : {
                'current' : address,
            }
        });
    }
}