import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal } from '@Src/Global/Actions';
import { clearCart, handleExpandPanel } from '@Src/Cart/Actions';

// Components =>
import Button from '@Shared/Button';
import Confirm from '@Shared/Confirm';
import Stepper from '@Src/Cart/Stepper';

// Routing
import { useNavigate } from 'react-router-dom';

// Swipeable
import { useSwipeable } from 'react-swipeable';

// Icons
import { BsChevronLeft } from 'react-icons/bs';

const Template = ({ setModal, clearCart, handleExpandPanel, cart: { expandedPanel }, global: { wsize }, ...props }) => {
    const navigate = useNavigate();

    const handleEmptyCart = () => {
        clearCart().then(() => {
            setModal(false);
            navigate('/cart');
        });
    }

    const handleConfirm = () =>{
        setModal(<Confirm title={'Confirma tu acción'} subtitle={'Estás a punto de eliminar todos los productos apartados en tu canasta. ¿Deseas continuar?'} callBack={handleEmptyCart}/>);
    }

    // Template Styles:
    let cartTemplateClass = 'cart-template';
    if(props.className){
        cartTemplateClass += ' '+props.className;
    }
    if(expandedPanel){
        cartTemplateClass += ' expanded';
    }

    const swiperHandlers = useSwipeable({
        onSwipedDown: () => {
            handleExpandPanel();
        },
    });

    const handleBack = () => {
        navigate(-1);
    }

    // Force scroll top on start:
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            document.querySelector('main').scrollTo(0, 0);
            var mainHeight = document.querySelector('main').offsetHeight;
            if(mainHeight > 700){
                //document.querySelector('main').style.maxHeight = (mainHeight - 110)+'px';
            }
        }, 100);
    }, []);

    return (
        <div className={cartTemplateClass}>
            {expandedPanel ? <div className={'bg'} onClick={handleExpandPanel} {...swiperHandlers}></div> : null}
            <section>
                <div className='content-header'>
                    <h1 onClick={handleBack}><BsChevronLeft size={18}/> {props.title}</h1>
                    <Button variant={'link'} onClick={handleConfirm}>Vaciar carrito</Button>
                    <Button variant={'tertiary'} onClick={handleExpandPanel}>Ver resumen</Button>
                    <Stepper step={props.step}/>
                </div>
                <div className='content-area'>
                    {props.left}
                </div>
            </section>
            <section>
                {props.right}
            </section>
        </div>
    );
};


// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
        clearCart,
        handleExpandPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Template);
