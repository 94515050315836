import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>
import Holder from '@Src/Cart/Methods/Holder';

// Assets
import SevenEleven from '@Assets/logo-7eleven.png';
import Oxxo from '@Assets/logo-oxxo.png';
import BBVA from '@Assets/logo-bbva.png';
import Santander from '@Assets/logo-santander.png';

// Icon
import { RiCoinsFill } from 'react-icons/ri';
import { FiCopy } from 'react-icons/fi';

// Toastify:
import { toast } from "react-toastify";

const Deposit = ({ ...props }) => {

    const handleClipboard = (clip) => {
        window.navigator.clipboard.writeText(clip).then(() => {
            toast.info('El valor ha sido copiado al portapapeles.', {
                position: 'bottom-left',
            });
        });
    }
    return (
        <Holder
            {...props}
            header={
                <h2><RiCoinsFill size={22} color={'#EB6B2F'}/> Deposito y/o transferencia bancaria</h2>
            }
            content={
                <React.Fragment>
                    <div className='method-block'>
                        <h2>Bancos donde puedes depositar:</h2>
                        <div className='deposit-holder'>
                            <img src={BBVA}/>
                            <div className={'deposit-row'}>
                                <p><span>Cuenta:</span> 0463238903</p>
                                <button onClick={() => handleClipboard('0463238903') }>
                                    <FiCopy color={'#CCD2E3'}/>
                                </button>
                            </div>
                            <div className={'deposit-row'}>
                                <p><span>CLABE:</span> 012180004632389032</p>
                                <button onClick={() => handleClipboard('012180004632389032') }>
                                    <FiCopy color={'#CCD2E3'}/>
                                </button>
                            </div>
                        </div>

                        <div className='deposit-holder'>
                            <img src={Santander}/>
                            <div className={'deposit-row'}>
                                <p><span>Cuenta:</span> 60549473082</p>
                                <button onClick={() => handleClipboard('60549473082') }>
                                    <FiCopy color={'#CCD2E3'}/>
                                </button>
                            </div>
                            <div className={'deposit-row'}>
                                <p><span>CLABE:</span> 014180605494730828</p>
                                <button onClick={() => handleClipboard('014180605494730828') }>
                                    <FiCopy color={'#CCD2E3'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='method-block'>
                        <div className={'gray-box'}>
                            <h2>Información importante</h2>
                            <p>· Asegurate de hacer el pago de tu pedido antes de las 18:00 hrs del mismo día</p>
                            <p>· Una vez hecha la reservación de tu pedido este no puede cancelarce.</p>
                        </div>
                    </div>
                </React.Fragment>
            }
        />
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Deposit);
