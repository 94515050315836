import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Increaser from '@Src/Product/Increaser';
import Discount from '@Src/Product/Discount';
import Loading from '@Shared/Loading';

// Actions
import { currencyFormat } from '@Src/Global/Actions';
import { setCartQty } from '@Src/Cart/Actions';

// Icons
import { AiOutlineDelete } from 'react-icons/ai';

const Item = ({ product, setCartQty }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleRemove = () => {
        setIsDeleting(true);
        setCartQty(product, 0).then(() => {})
    }

    const imgUrlResolver = (path) => {
        return `${path}0_medium.webp`;
        /*
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            return `${path}0_medium.jpg`;
            if (ua.indexOf('chrome') > -1) {
                return `${path}0_medium.webp`;
            } else {
                return `${path}0_medium.jpg`;
            }
        }
        */
    }

    return (
        <div className='cart-item'>
            <div className='img-holder' style={{ backgroundImage: `url(${imgUrlResolver(product.image_url)})`}}></div>
            <div className='content-holder'>
                <h2>{product.description}</h2>
                <div className='column'>
                    <small>SKU: {product.sale_code}</small>
                    <span>{currencyFormat(product.discount)}<b>por pieza</b> <Discount product={product}/></span>
                </div>
                <div className='column'>
                    <Increaser product={product} debounce={true}/>
                </div>
                <button className='icon-detelete' onClick={handleRemove}>
                    {!isDeleting ? <AiOutlineDelete size={26}/> : <Loading size={16}/>}
                </button>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setCartQty,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Item);
