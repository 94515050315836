import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

const Motivation = ({ catalog: { untilnextdiscount }, }) => {


    return (
        untilnextdiscount && untilnextdiscount.next_step_diff > 0 ?
            <div className='cart-motivation'>
                <h2>¡Sigue comprando!</h2>
                <h3>Agrega {untilnextdiscount.next_step_diff} piezas más a tu compra y recibe</h3>
                <h4><big>{untilnextdiscount.next_step_disc_one}%</big> de descuento</h4>
            </div>
        : null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Motivation);
