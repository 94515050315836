import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions =>
import { handleRegister } from '@Src/Register/Actions';

// Toastify:
import { toast } from "react-toastify";

// Components:
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

// Routing
import { useNavigate, useLocation } from 'react-router-dom';

// Assets
import Banner from '@Assets/register/banner.png';

const Register = ({ handleRegister }) => {
    const navigate = useNavigate();
    const {state} = useLocation();

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        username: Yup.string().required(' '),
        phone: Yup.string().test('phone', function(value) {
            const phoneValidation = Yup.string().required("Es necesario proporcionar una forma de comuncación e-mail o teléfono").min(10, 'Es necesario ingresar un teléfono válido de 10 dígitos').max(10, 'Es necesario ingresar un teléfono válido de 10 dígitos');
            if(this.parent.email && this.parent.email.length > 0){
                // Si el campo de correo ya fue proporcionado, el teléfono sólo se valida si no corresponde a 10 dígitos:
                if (!!value) {
                    return phoneValidation.isValidSync(value);
                }
            } else {
                // Si no se ha proporcionado campo de correo, el teléfono es requerido:
                return phoneValidation.isValidSync(value);
            }
            return true;
        }),
        email: Yup.string().test('email', function(value) {
            const emailValidation = Yup.string().required().email('Es necesario indicar una cuenta de correo válida');
            if(!this.parent.phone || this.parent.phone.length > 0){
                // Si el campo de teléfono ya fue proporcionado, se valida el e-mail como válido sólo si es proporcionado
                if (!!value) {
                    return emailValidation.isValidSync(value);
                }
            } else {
                // Si no se ha proporcionado campo de teléfono, el e-mail es requerido:
                return emailValidation.isValidSync(value);
            }
            return true;
        }),
        password: Yup.string().required('Es necesario indicar una contraseña válida.'),
        cpassword: Yup.string().required().oneOf([Yup.ref('password'), null], 'La confirmación de contraseña no coincide'),
    });

    let initialValues = {
        username :  (state && state.alias) ? state.alias : '',
        phone    : '',
        email    : '',
        password : '',
        cpassword: '',
    };

    return (
        <div id="register">
            <div className='content'>
                <div className='column'>
                    <h1>Crear cuenta</h1>
                    <p>Por favor ingresa los datos a continuación para crear una cuenta. ¡Una cuenta puede darte acceso a descuentos especiales!</p>
                    <img src={Banner}/>
                </div>
                <div className='column'>
                    <h1>&nbsp;</h1>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={YupSchema}
                        onSubmit={(values,  {setSubmitting}) =>{
                            setSubmitting(true);
                            handleRegister(values).then((res) => {
                                setSubmitting(false);
                                if(res.type === 'Error'){
                                    toast.error(res.message);
                                } else {
                                    toast.success(res.message);
                                    navigate('/register/success');
                                }
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <React.Fragment>
                                <div className='form-area box'>
                                    <Input
                                        label        = {'Ingresa un nombre de usuario o alias'}
                                        placeholder  = {'Ingresa un nombre de usuario o alias:'}
                                        name         = {'username'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        errors       = {errors}
                                        onFocus      = {handleBlur}
                                        touched      = {touched}
                                    />
                                    <Input
                                        label        = {'Teléfono (10 dígitos)'}
                                        placeholder  = {'Teléfono (10 dígitos):'}
                                        name         = {'phone'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        errors       = {errors}
                                        onFocus      = {handleBlur}
                                        touched      = {touched}
                                        pattern      = "\d*"
                                    />
                                    <Input
                                        label        = {'E-mail'}
                                        placeholder  = {'E-mail:'}
                                        name         = {'email'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        errors       = {errors}
                                        onFocus      = {handleBlur}
                                        touched      = {touched}
                                    />
                                    <Input
                                        label        = {'Contraseña'}
                                        placeholder  = {'Contraseña:'}
                                        name         = {'password'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        errors       = {errors}
                                        onFocus      = {handleBlur}
                                        touched      = {touched}
                                    />
                                    <Input
                                        label        = {'Confirmar contraseña'}
                                        placeholder  = {'Confirmar contraseña:'}
                                        name         = {'cpassword'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        errors       = {errors}
                                        onFocus      = {handleBlur}
                                        touched      = {touched}
                                    />

                                    <div className='button-holder'>
                                        {isSubmitting ? <Loading/> : <Button variant={'primary'} onClick={handleSubmit}>Crear cuenta</Button> }
                                    </div>
                                </div>
                                <legend>Al crear tu cuenta estás de acuerdo en aceptar nuestros <a href="https://elements-of-steel.com.mx/files/TERMINOS-Y-CONDICIONES.pdf" target="_blank">Términos y condiciones</a></legend>
                                <legend>Conoce nuestra <a href="https://elements-of-steel.com.mx/files/AVISO-DE-PRIVACIDAD.pdf" target="_blank">Política de privacidad y protección de datos</a></legend>
                            </React.Fragment>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleRegister,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Register);
