import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

const Holder = ({ ...props }) => {
    let isChosen = (props.chosen && props.chosen === props.type) ? 'chosen' : '';
    return (
        <div className={'method-holder ' + isChosen}>
            <div className='method-header' onClick={() => props.callBack(props.type) }>
                {props.header}
            </div>
            <div className='method-content'>
                {props.content}
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Holder);
