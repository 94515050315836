import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions =>
import { logIn } from '@Src/Login/Actions';
import { setPanel, setModal } from '@Src/Global/Actions';

// Toastify:
import { toast } from "react-toastify";

// Components:
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import Forgot from '@Src/Login/Forgot';
import Pos from '@Src/Login/Pos';

// Router
import { useNavigate } from 'react-router-dom';

// Icons
import { FaUser } from 'react-icons/fa';

const Login = ({ logIn, setPanel, setModal }) => {
    const navigate = useNavigate();

    const handleRegister = () => {
        setPanel(false);
        navigate('/register');
    }

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        username: Yup.string()
          .required('Es necesario indicar un email o celular.'),
        password: Yup.string()
          .required('Es necesario indicar una contraseña válida.')
    });

    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            validationSchema={YupSchema}
            onSubmit={(values,  {setSubmitting, setFieldValue}) =>{
                logIn(values).then((res) => {
                    if(res.error || res.type === 'Error'){
                        toast.error(res.message);
                    } else {
                        navigate('/catalog');
                        setPanel(false);
                        toast.success(res.message);
                    }
                    setSubmitting(false);
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div id="login">
                    <div className='area-login'>
                        <h2><FaUser size={18}/> Iniciar sesión</h2>
                        <Input
                            label        = {'Número de cliente, email o celular'}
                            placeholder  = {'Número de cliente, email o celular:'}
                            name         = {'username'}
                            values       = {values}
                            onChange     = {handleChange}
                            onFocus      = {handleBlur}
                            errors       = {errors}
                            touched      = {touched}
                            autoComplete = 'new-password'
                        />
                        <Input
                            label        = {'Contraseña'}
                            placeholder  = {'Contraseña:'}
                            name         = {'password'}
                            type         = {'password'}
                            values       = {values}
                            onChange     = {handleChange}
                            errors       = {errors}
                            onFocus      = {handleBlur}
                            touched      = {touched}
                            autoComplete = 'new-password'
                        />
                        <div className='buttons-holder'>
                            {!isSubmitting ?
                                <Button onClick={handleSubmit} variant={'primary'}>
                                    Iniciar sesión
                                </Button>
                            :
                                <Loading/>
                            }

                            <Button onClick={() => setModal(<Forgot/>) } variant={'link'}>Olvidé mi contraseña</Button>
                        </div>
                    </div>
                    <div className='area-register'>
                        <h2>¿Aún no estás registrado?</h2>
                        <p>Crea una cuenta para comprar en línea, además de obtener acceso a promociones exclusivas, increíbles descuentos y hasta envíos gratuitos.</p>
                        <Button onClick={handleRegister}>Crear una cuenta</Button>
                        <legend onClick={() => setModal(<Pos/>) }>¿Ya eres un cliente? Completa tu registro</legend>
                    </div>
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        logIn,
        setPanel,
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Login);
