import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions =>
import { setPassword } from '@Src/Login/Actions';
import { setPanel } from '@Src/Global/Actions';

// Toastify:
import { toast } from "react-toastify";

// Components:
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import PanelLogin from '@Src/Panels/Login';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Icons
import { RiLockPasswordLine } from 'react-icons/ri';

const Recover = ({ setPassword, setPanel, global: { client } }) => {
    const params = useParams();
    const navigate = useNavigate();

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        password: Yup.string().required(' ').min(5),
        cpassword: Yup.string().required().oneOf([Yup.ref('password'), null], ' '),
    });

    useEffect(() => {
        if(client){
            navigate('/profile');
            toast.info('Si necesitas reestablecer tu contraseña, es necesario cerrar tu sesión primero.');
        }
    }, []);

    return (
        <Formik
            initialValues={{
                password: '',
                cpassword: '',
                token: params.token,
            }}
            validationSchema={YupSchema}
            onSubmit={(values,  {setSubmitting, resetForm }) =>{
                setPassword(values).then((res) => {
                    resetForm();
                    toast[res.type](res.message);
                    setSubmitting(false);
                    navigate('/catalog');
                    setPanel(<PanelLogin/>);
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div className='recover-password'>
                    <div className="box">
                        <h1><RiLockPasswordLine size={18}/> Establecer nueva contraseña</h1>
                        <Input
                            label        = {'Ingresa tu nueva contraseña:'}
                            placeholder  = {'Ingresa tu nueva contraseña:'}
                            name         = {'password'}
                            values       = {values}
                            onChange     = {handleChange}
                            onFocus      = {handleBlur}
                            errors       = {errors}
                            touched      = {touched}
                            autoComplete = 'new-password'
                            maxLength    = {10}
                        />
                        <Input
                            label        = {'Confirmación de contraseña:'}
                            placeholder  = {'Confirmación de contraseña:'}
                            name         = {'cpassword'}
                            type         = {'password'}
                            values       = {values}
                            onChange     = {handleChange}
                            errors       = {errors}
                            onFocus      = {handleBlur}
                            touched      = {touched}
                            autoComplete = 'new-password'
                            maxLength    = {10}
                        />
                        <div className='buttons-holder'>
                            {!isSubmitting ?
                                <Button onClick={handleSubmit} variant={'primary'}>
                                    Guardar nueva contraseña
                                </Button>
                            :
                                <Loading size={16}/>
                            }

                            <Button onClick={() => navigate('/catalog') } variant={'link'}>Volver al catálogo</Button>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setPassword,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Recover);
