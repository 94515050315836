import {API, isValidToken} from "@Src/Global/Actions";
import { transferCart } from "@Src/Cart/Actions";

export function handleRegister(values){
    return () => {
        return new Promise((resolve) => {
            var postdata = '';
            Object.keys(values).forEach((field) => {
                postdata += field+'='+values[field]+'&';
            });
            postdata = postdata.substring(0, postdata.length - 1);

            console.log(`client/register.json?${postdata}`);
            API(`client/register.json?${postdata}`).then((res) => {
                if(res.error){
                    resolve({
                        success: false,
                        type: 'Error',
                        title: 'Error en registro',
                        message: res.error,
                    });
                } else {
                    localStorage.setItem('verify-token', res.token);
                    resolve({
                        success: true,
                        type: 'Success',
                        title: '¡Gracias por registrarte!',
                        message: 'Se ha creado tu cuenta corréctamente, por favor verifica tu correo o teléfono para activar tu cuenta.',
                    });
                }
            });
        });
    }
}



export function verifyAccount(token){
    return (dispatch) => {
        return new Promise((resolve) => {
            API(`client/verify.json?token=${token}`).then((res) => {
                if(!res.verified){
                    resolve({
                        type: 'error',
                        message: 'No ha sido posible verificar la cuenta, por favor intenta ponerte en contacto con nosotros.',
                    });
                } else {
                    dispatch(isValidToken(res.verified)).then((tokenRes) => {
                        // Transfer cart items from guest to client
                        dispatch(transferCart()).then(() => {
                            localStorage.removeItem('verify-token');
                            resolve({
                                type: 'success',
                                message: `Hola ${tokenRes.alias}, te damos la bienvenida`,
                            });
                        });
                    });
                }
            });
        });
    }
}




// Reset states:
export function resetStates(states = {"submitting" : false, "registered" : false , "verified" : null }){
    return (dispatch) => {
        dispatch({
            type 	: "MERGE-REGISTER-STATES",
            payload	: states
        });
    }
}