import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { storeById } from '@Src/Global/Actions';

// Icons
import { IoLocationSharp } from 'react-icons/io5';

const Region = ({ global: { store }, storeById }) => {
    return (
        <div className='card region-card'>
            <div className='icon-holder'>
                <IoLocationSharp color={'#EB6B2F'} size={32}/>
            </div>
            <label>{storeById(store)}</label>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        storeById,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Region);
