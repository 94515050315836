import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, imagePathResolver, setFlyout, setPanel } from '@Src/Global/Actions';
import { clearParams, setParams } from '@Src/Catalog/Actions';

// Components
import PanelMenu from '@Src/Panels/Menu';

// Icons
import { IoDiamond } from 'react-icons/io5';
import { BsChevronLeft } from 'react-icons/bs';

// Routing:
import { createSearchParams, useNavigate } from 'react-router-dom';

const Collections = ({ catalog: { collections, params }, setParams, clearParams, setFlyout, setPanel }) => {
    const navigate = useNavigate();

    const handleCollectionFilter = (c) => {
        let brand = params.brand;
        clearParams().then(() => {
            setParams({
                'web_catalog': c.id,
                'brand': brand
            }).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    searhc: `?${createSearchParams(nparams)}`
                })
                setFlyout(false);
                setPanel(false);
            });
        });
    }

    return (
        <div className='flyout-collections'>
            <h2 onClick={() => setPanel(<PanelMenu/>) }><IoDiamond color={'#F78721'} size={18}/> Nuestras colecciones  <BsChevronLeft size={18}/></h2>
            <p>Fusionamos creatividad, técnica y pasión para transformar metales, y así darle vida a colecciones únicas de joyería.</p>
            <div className='items'>
                { collections && collections.map((c) => {
                    return(
                        <button onClick={() => handleCollectionFilter(c) } key={UUID()} type="button" style={{ backgroundImage: 'url('+imagePathResolver(c.image_url)+')'}}>
                            <span>{c.name}</span>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        clearParams,
        setParams,
        setFlyout,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Collections);
