import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>
import Texts from '@Src/Product/Texts';
import Prices from '@Src/Product/Prices';
import Variants from '@Src/Product/Variants';
import Increaser from '@Src/Product/Increaser';
import Gallery from '@Src/Product/Gallery';

const Popup = ({ product }) => {
    // For some reason we need to re-create this callBack inside popup
    const [variant, setVariant] = useState(product);
    const callBack = (replacement) => {
        // Clean rid from object
        if(replacement.rid){
            replacement.id = replacement.rid;
            delete replacement.rid;
        }
        setVariant(Object.assign({}, variant,  replacement));
    }

    return (
        <div className={'product-popup'}>
            <div className={'gallery'}>
                <Gallery product={variant}/>
            </div>
            <div className={'info'}>
                <Texts product={variant}/>
                <Prices product={variant}/>
                <Variants product={variant} callBack={callBack}/>
                <Increaser product={variant}/>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Popup);
