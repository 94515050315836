import React, { useState, useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { currencyFormat } from '@Src/Global/Actions';
import { handleExpandPanel } from '@Src/Cart/Actions';

// Components =>
import Button from '@Shared/Button';
import Motivation from '@Src/Cart/Motivation';

// Routing
import { useNavigate } from 'react-router-dom';

const Summary = ({ cart: { basket, expandedPanel }, handleExpandPanel }) => {
    const  [minicart, setMinicart] = useState(false);
    const navigate = useNavigate();

    const handleNavigate = () => {
        if(expandedPanel){
            handleExpandPanel();
        }
        navigate('/cart/step-2');
    }

    useEffect(() => {
        if(parseInt(basket.total) > 0){
            setMinicart(basket)
        } else {
            if(localStorage.getItem('lastBasket')){
                setMinicart(JSON.parse(localStorage.getItem('lastBasket')))
            } else {
                setMinicart(false)
            }
        }
    }, []);

    return (
        minicart ?
            <div className='cart-summary'>
                <h2>Resumen de compra</h2>
                {/*
                <article>
                    <label>Costo de envío</label>
                    <span>GRATIS</span>
                </article>
                */}
                <article>
                    <label>Subtotal de tu compra</label>
                    <span>{ currencyFormat(minicart.sum_price) }</span>
                </article>
                <article>
                    <label>Descuento total</label>
                    <span>{ (minicart.sum_discount) ? currencyFormat(minicart.sum_discount): '$0.00' }</span>
                </article>
                <article>
                    <label>Total de compra</label>
                    <span>{ currencyFormat(minicart.sum_primary) }</span>
                </article>

                <Motivation/>
                <Button variant={'primary'} onClick={handleNavigate}>Seleccionar dirección</Button>
            </div>
        : null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleExpandPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Summary);
