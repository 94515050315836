import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { clearParams, getCatalog, loadMore } from '@Src/Catalog/Actions';
import { UUID, changeStore, setHeaderVisibility } from '@Src/Global/Actions';

// Components =>
import Button from '@Shared/Button';
import Banner from '@Src/Catalog/Banner';
import Product from '@Src/Product/Product';
import Empty from '@Src/Catalog/Empty';
import FiltersSorters from './FiltersSorters';
import End from '@Src/Catalog/End';

// Routing
import { useLocation } from 'react-router-dom';

// Icons
import { MdDownloading } from 'react-icons/md';

const Catalog = ({ getCatalog, global: { flyout }, catalog: { products, end }, loadMore,  }) => {
    const location = useLocation();

    // Ask for catalog items everytime location search params changes:
    useEffect(() => {
        getCatalog();
        // Return to previous scroll position
        if(localStorage.getItem('scrollY') > 0){
            setTimeout(() => {
                if(location.pathname.search('/catalog') !== -1)
                document.getElementsByTagName('main')[0].scrollTo({top: localStorage.getItem('scrollY'), behavior: 'smooth'});
            }, 200);
        }
    }, [location]);


    const trackScrollY = (e) => {
        localStorage.setItem('scrollY', e.target.scrollTop);
    }

    useEffect(() => {
        let main = document.getElementsByTagName('main')[0];
        main.addEventListener('scroll', trackScrollY);
        return () => main.removeEventListener("scroll", trackScrollY)
    }, []);

    return (
        <div id="catalog">
            <Banner/>
            {products ?
                products.length > 0 ?
                    <React.Fragment>
                        {!flyout ? <FiltersSorters/> : null }
                        <div className={'catalog-items'}>
                            {products.map((product) => {
                                return <Product product={product} key={UUID()}/>
                            })}
                            { end ? <End/> : null }
                        </div>
                    </React.Fragment>
                : <Empty/>
            : null }

            { !end && products && products.length > 0 ?
                <div className='load-more-holder'>
                    <Button onClick={loadMore} variant={'secondary'}><MdDownloading/> Ver más productos</Button>
                </div>
            : null }
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getCatalog,
        changeStore,
        clearParams,
        loadMore,
        setHeaderVisibility,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Catalog);
