import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { currencyFormat } from '@Src/Global/Actions';
import { investCalculator } from '@Src/Business/Actions';

// Components =>
import Button from '@Shared/Button';

// Input Mask
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const Calculator = ({ investCalculator, calculator: { investment, percent, earnings, discounts} }) => {
    const numberMask = createNumberMask({
        prefix: '$',
        suffix: '' // This will put the dollar sign at the end, with a space.
    });

    useEffect(() => {
        investCalculator(investment);
    }, []);

    const handleOnChange = (e) => {
        let inv = e.target.value.replace(/\D/g,'');
        investCalculator(inv);
    }


    const downloadArea = () => {
        return (
            <div className='download-area'>
                <h3>Obten nuestro tabulador y conoce tus ganancias</h3>
                <a className={'btn'} href="https://elements-of-steel.com.mx/files/Tu-Ganancia.pdf" target="_blank">Descargar tabulador de ganacias</a>
            </div>
        )
    }

    return (
        <div className='business-calculator' id="calculator">
            <section>
                <h2>¡Descubre tu ganancia!</h2>
                <p>Utiliza la siguiente herramienta para obtener un ejemplo de las ganancias y beneficios que puedes obtener al invertir en Elements.</p>
                { downloadArea() }
            </section>
            <aside>
                <h2>Calcula tus ganancias</h2>
                <fieldset>
                    <label>Calcula tu inversión</label>
                    <MaskedInput
                        value={investment}
                        onChange={handleOnChange}
                        mask={numberMask}
                        maxLength={10}
                        pattern="\d*"
                    />
                </fieldset>
                <p>Obtienes un <b>descuento del {percent}%</b> en cada una de tus compras, tu ganancia aproximada más tu inversión inicial sería de:</p>
                <big>{currencyFormat(earnings, 0)}</big>
                <small>* Utilidad estimada vendiendo el total de los productos a precio etiqueta.</small>
            </aside>

            { downloadArea() }
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        investCalculator
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Calculator);
