import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal } from '@Src/Global/Actions';

// Components =>
import Detail from '@Src/Product/Detail';

//Icons
import { MdClose } from 'react-icons/md';

const PopDetail = ({ pid, data, setModal }) => {
    return (
        <div className='pop-detail'>
            <button className={'close-btn'} onClick={() => setModal(false) }>
                <MdClose/>
            </button>
            <Detail pid={pid} data={data}/>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(PopDetail);
