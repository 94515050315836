import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { setParams, getCatalog } from '@Src/Catalog/Actions';

// Components =>
import Radio from '@Shared/Radio';

// Icons
import { TbChevronRight } from 'react-icons/tb';
import { MdOutlineSort } from 'react-icons/md';
import { IoCloseOutline } from 'react-icons/io5';

// Routing:
import { useNavigate, createSearchParams } from 'react-router-dom';

// Swipeable
import { useSwipeable } from 'react-swipeable';

const Sorter = ({ setParams, catalog: { perpage, params } }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const swiperHandlers = useSwipeable({
        onSwipedDown: () => {
            setOpen(false);
        },
    });

    const handleOpen = (opt = {}) => {
        localStorage.removeItem('scrollY');
        setOpen(!open);
        if(open){
            setParams({
                'start' : 0,
                'end' : perpage,
                'order_var': (opt.order_var) ? opt.order_var : (params.order_var) ? params.order_var : '',
                'order_dir': (opt.order_dir) ? opt.order_dir : (params.order_dir) ? params.order_dir : '',
            }).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`
                });
            });
        }
    }

    const sorters =  {
        label : 'Ordernar por',
        group : 'ordering',
        multiple: false,
        options : [
            {
                'label' : 'Precio menor a mayor',
                'order_var' : 'price',
                'order_dir' : 'asc',
            },
            {
                'label' : 'Precio mayor a menor',
                'order_var' : 'price',
                'order_dir' : 'desc',
            },
            {
                'label' : 'Primeros modelos',
                'order_var' : 'date',
                'order_dir' : 'asc',
            },
            {
                'label' : 'Modelos recientes',
                'order_var' : 'date',
                'order_dir' : 'desc',
            },
        ],
    };

    let checked = false;


    return (
        <React.Fragment>
            { open ? <div className='overlay-layer' onClick={handleOpen} {...swiperHandlers}></div> : null }
            <div className='item'>
                <button onClick={handleOpen}>
                    <span>{sorters.label}</span>
                    <TbChevronRight size={22} color={'#1289C5'}/>
                    <MdOutlineSort size={22} color={'#1289C5'}/>
                </button>
                {open ?
                    <div className={'box'}>
                        {sorters.options.map((opt) => {
                            checked = (params.order_var === opt.order_var && params.order_dir === opt.order_dir) ? true : false;

                            // When no param is clear, defaults to "modelos recientes"
                            if(!params.order_var && !params.order_dir && opt.order_var === 'date' && opt.order_dir === 'desc'){
                                checked = true;
                            }

                            return <Radio checked={checked} callBack={() => handleOpen(opt) } name={`sorting`} value={opt.order_var+':'+opt.order_dir} key={UUID()} text={opt.label}/>
                        })}
                    </div>
                : null}
            </div>
        </React.Fragment>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setParams,
        getCatalog,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Sorter);
