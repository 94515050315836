import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Components =>
import Button from '@Shared/Button';

// Routing
import { Link } from 'react-router-dom';

// Icons
import { MdLocationOn } from 'react-icons/md';

const Punch = ({ global: { phones } }) => {
    return (
        <div className='about-punch'>
            <div className='closer'>
                <h2>Cerca de ti</h2>
                <p>Contamos con sucursales estrategicas y envíos a toda la República.</p>
                {['GDL', 'CDMX'].map((city) => {
                    return (
                        <button className='city-item' key={UUID()}>
                            <MdLocationOn size={30} color={'#EB6B2F'}/>
                            <big>{city}</big>
                            <Link to={'/branches/'+city}>Ver sucursales</Link>
                        </button>
                    )
                })}
            </div>
            <div className='likeit'>
                <h2>¿Te gusta lo que ves?</h2>
                <h3>Llámanos para mayor información, será un placer conocerte.</h3>
                <a href={'tel:'+phones.tel.clean} className='btn primary'>Contáctanos</a>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Punch);
