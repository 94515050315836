import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { storeById } from '@Src/Global/Actions';


const Banner = ({ global: { store }, storeById }) => {
    const isOutlet = ([6,9].includes(store)) ? true : false;
    return (
        <div className={(isOutlet) ? 'banner is-outlet' : 'banner'}>
            <span>{(isOutlet) ? 'OUTLET' : 'CATÁLOGO'}</span>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        storeById,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Banner);
