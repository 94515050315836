import React, { useRef, useCallback } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';

// Components
import PopDetail from '@Src/Product/PopDetail';

// Zoom
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

// Actions
import { imagePathResolver, setModal } from '@Src/Global/Actions';

const Gallery = ({ children, product, setModal, global: { wsize }, zoom = true, large = false }) => {
    const navigate = useNavigate();
    let pid = (product.rid) ? product.rid : product.id;

    // Zoom stuff
    const imgRef = useRef();
    const IMG_URL = imagePathResolver(product.image_url, large);
    const quickPinchZoomRef = useRef();

    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;
        if (img) {
            const value = make3dTransformValue({ x, y, scale });
            img.style.setProperty("transform", value);
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        if(quickPinchZoomRef.current){
            quickPinchZoomRef.current.scaleTo({ x: 0, y: 0, scale: 1 });
        }
    });

    const handleNavigate = (pid) => {
        setModal(false);
        if(wsize.w > 1080){
            navigate(`/product/${pid}`, {state: {product: product}});
        } else {
            setTimeout(() => {
                setModal(<PopDetail pid={pid} data={product}/>);
            }, 100);
        }
    }

    const renderWrapper = (children) => {
        if(zoom){
            return <QuickPinchZoom onUpdate={onUpdate} ref={quickPinchZoomRef}>{children}</QuickPinchZoom>
        } else {
            return <React.Fragment>{children}</React.Fragment>
        }
    }

    return (
        <div className={'product-gallery'}>
            { renderWrapper(
                <picture>
                    <source srcSet={IMG_URL}></source>
                    <img ref={imgRef} src={IMG_URL.replace('.webp', '.jpg')} onMouseLeave={onMouseLeave}  onClick={() => handleNavigate(pid) }/>
                </picture>
            )}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Gallery);
