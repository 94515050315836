import {API} from "../Global/Actions";

// Get orders
export function getOrders(){
    return (dispatch, getState) => {
        var state = getState();
        var {client} = state.global;
        return new Promise((resolve, reject) => {
            API(`client_history/index.json?client=${client.data.id}`, "GET").then((res) => {
                dispatch({
                    type 	: "MERGE-ORDERS-STATES",
                    payload	: {
                        "items" : res.baskets,
                    }
                });
                resolve(res.baskets);
            });
        });
    }
}



export function setCurrentOrder(current){
    return (dispatch, getState) => {
        let state = getState();
        let currentVal = (state.orders.current === current) ? false : current;
        dispatch({
            type : 'MERGE-ORDERS-STATES',
            payload : {
                current : currentVal,
            }
        });

        let targets = document.getElementsByClassName('collapser-content');
        Array.from(targets).forEach((target) => {
            target.removeAttribute('style');
        });

        if(currentVal){
            let ref = document.getElementById('order-'+current);
            ref.style.height = ref.scrollHeight+'px';
        }
    }
}