import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, changeStore } from '@Src/Global/Actions';
import { setParams, clearParams, handleOutlet } from '@Src/Catalog/Actions';

// Components =>
import Item from '@Src/Catalog/FilterItem';
import Button from '@Shared/Button'
import Linex from '@Shared/Linex';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

// Icons
import { RiEqualizerLine } from 'react-icons/ri';
import { IoCloseOutline } from 'react-icons/io5';

// Swipeable
import { useSwipeable } from 'react-swipeable';

const Filters = ({ setParams, clearParams, catalog: { categories, materials, colors, perpage, params }, global: { store }, handleOutlet }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)

    const swiperHandlers = useSwipeable({
        onSwipedDown: () => {
            setOpen(false);
        },
    });

    const filters = [
        {
            label: 'Categorías',
            group: 'category',
            multiple: true,
            options: categories,
        },
        {
            label: 'Materiales',
            group: 'material',
            multiple: true,
            options: materials,
        },
        {
            label: 'Colores',
            group: 'color',
            multiple: true,
            options: colors,
        },
    ];

    const handleOpen = () => {
        setOpen(!open);
        if(open){
            let checkboxes = document.querySelectorAll('[type="checkbox"][name*="filters."]'); //document.querySelectorAll('[type="checkbox"][name*="filters."]:checked');
            let key = ''; //item.group;
            let val = '';
            checkboxes.forEach((chk) => {
                key = chk.getAttribute('name').split('.')[1];
                val += (chk.checked) ? chk.getAttribute('value') + ',' : '';
            });
            val =  val.replace(/(^,)|(,$)/g, ""); // Remove trailing commas
            setParams({
                [key]: val,
                start: 0,
                end: perpage
            }).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`,
                });
            });
        }
    }

    const clearFilters = () => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`,
            });
            setOpen(!open);
        });
    }

    const filterableItemsStyle = (open) ? 'filterable-items open' : 'filterable-items';

    const hasFilters = (params.category || params.material || params.color || params.keyword || params.brand || [6,9].includes(store)) ? true : false;

    return (
        <React.Fragment>
            {open ?
                <div className='overlay' onClick={handleOpen} {...swiperHandlers}>
                    <button className={'close-btn'}>
                        <IoCloseOutline size={30}/>
                    </button>
                </div>
            : null }
            <h2 className={hasFilters ? 'active-filters' : ''} onClick={handleOpen}>{hasFilters ? 'Filtros activos' : 'Filtrar por'} <RiEqualizerLine size={20} color={'#1289C5'}/></h2>
            <div className={filterableItemsStyle}>
                <div className={'other-filters'}>
                    {params.brand ? <a onClick={() => setParams({ brand: '' }) }><IoCloseOutline size={14}/> Línea X</a> : null }
                    {params.keyword ? <a onClick={() => setParams({ keyword: '' }) }><IoCloseOutline size={14}/> "{params.keyword}"</a> : null }
                    {[6,9].includes(store) ? <a onClick={handleOutlet}><IoCloseOutline size={14}/> Outlet</a> : null }
                </div>
                <div className={'overflow'}>
                    {filters.map((item) => {
                        return <Item key={UUID()} item={item}/>
                    })}
                    <div className='linex-holder'>
                        <Linex/>
                    </div>
                </div>
                <div className='buttons-mobile'>
                    <Button variant={'primary'} onClick={handleOpen}>Aplicar filtros</Button>
                    <Button variant={'link'} onClick={clearFilters}>Borrar filtros</Button>
                </div>
            </div>
        </React.Fragment>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setParams,
        clearParams,
        handleOutlet,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Filters);
