import {API} from "../Global/Actions";

// Save opinion
export function saveOpinion(values){
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            var state = getState();
            var {client} = state.global;
            let type, message;
            values.client = client.data.id;
            API(`client_product_rating/create.json`, 'POST', values).then((res) => {
                if(res.res_value === -1){
                    type = 'error';
                    message = 'Lo sentimos, hubo un error al almacenar la opinión.';
                } else {
                    type = 'success';
                    message = '¡Muchas gracias por tu opinión!';
                }
                resolve({
                    message,
                    type,
                });
            });
        });
    }
}