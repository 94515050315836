import React, { useEffect, useState, useRef } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setCurrentOrder } from '@Src/Orders/Actions';
import { UUID, currencyFormat } from '@Src/Global/Actions';

// Icons
import { TiLocationOutline } from 'react-icons/ti';
import { FiChevronRight } from 'react-icons/fi';

const Order = ({ order, orders: { current }, setCurrentOrder }) => {
    const ref = useRef(null);
    const [height, setHeight] = useState('0px');
    useEffect(() => {
        if(current === order.id){
            setHeight(ref.current.scrollHeight+'px');
        }
    }, []);

    return (
        <div className='order-box'  onClick={() => setCurrentOrder(order.id) }>
            <div className={'custom-collpaser'}>
                <div className='collapser-header'>
                    <div className='content'>
                        <div className='table-row'>
                            <div className='table-col'>
                                <label>ID de pedido</label>
                                <span>{order.code}</span>
                            </div>
                            <div className='table-col'>
                                <label>Fecha de compra</label>
                                <span>{order.requested_on.split(' ')[0]}</span>
                            </div>
                            <div className='table-col'>
                                <label>Descuento total</label>
                                <span>{currencyFormat(order.sum_discount)}</span>
                            </div>
                            <div className='table-col'>
                                <label>Total de compra</label>
                                <span>{currencyFormat(order.sum_price)}</span>
                            </div>
                            <div className='table-col'>
                                <label>Total a pagar:</label>
                                <span>{currencyFormat(order.total)}</span>
                            </div>
                        </div>
                    </div>
                    <button type={'button'}>
                        <FiChevronRight size={24}/>
                    </button>
                </div>
                <div className={'collapser-content'} id={'order-'+order.id} style={{ height : height }} ref={ref}>
                    <div className='order-detail'>
                        <div className='order-detail-title'>
                            <span><TiLocationOutline color={'#1B97D5'} size={16}/> Productos de <b>Tienda CDMX</b></span>
                            <span>Entrega <b>{order?.requested_on?.split(' ')[0]}</b></span>
                            <span>Status <b>{order.status}</b></span>
                        </div>
                        {order.items.map((item) => {
                            return (
                                <div className='order-row' key={UUID()}>
                                    <div className='img-holder' style={{ backgroundImage: `url(${item.image_url})`}}></div>
                                    <div className='sections'>
                                        <section>
                                            <label>{item.description}</label>
                                            <span>SKU: {item.sale_code}</span>
                                        </section>
                                        <section>
                                            <label>Talla</label>
                                            <span></span>
                                        </section>
                                        <section>
                                            <label>Piezas</label>
                                            <span>{item.quantity}</span>
                                        </section>
                                        <section>
                                            <label>Precio por pieza</label>
                                            <span>{currencyFormat(item.sum_price)}</span>
                                        </section>
                                        <section>
                                            <label>Descuento</label>
                                            <span>{currencyFormat(item.discount)}</span>
                                        </section>
                                        <section>
                                            <label>Precio final</label>
                                            <span>{currencyFormat(item.subtotal)}</span>
                                        </section>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setCurrentOrder,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Order);