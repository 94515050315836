import React, { useState } from 'react';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { handleExpandPanel, submitOrder } from '@Src/Cart/Actions';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components =>
import Template from '@Src/Cart/Template';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import Summary from '@Src/Cart/Summary';
import Motivation from '@Src/Cart/Motivation';
import Minicart from '@Src/Cart/Minicart';
import Addresses from '@Src/Addresses/Addresses';
import Stores from '@Src/Stores/Stores';

// Routing
import { useNavigate } from 'react-router-dom';

// Toast
import { toast } from 'react-toastify';

const Step1 = ({ cart: { expandedPanel, delivery }, handleExpandPanel, submitOrder }) => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const handleNavigate = (path) => {
        if(expandedPanel){
            handleExpandPanel();
        }
        navigate(path);
    }

    const handleSubmit = () => {
        if(!delivery){
            toast.error('Es necesario seleccionar una dirección de entrega o tienda para recolección.');
        } else {
            setSubmitting(true);
            submitOrder().then((res) => {
                setSubmitting(false);
                if(!res.success){
                    toast.error(res.message);
                } else {
                    navigate('/cart/step-4');
                    toast.success(res.message);
                }
            });
        }
    }

    return (
        !submitting ?
            <Template
                title={'Selecciona el lugar de entrega'}
                className={'cart-step-2'}
                step={1}
                left={[
                    <Addresses selectable={true} key={UUID()} title={'Entrega a domicilio'}/>,
                    <h2 key={UUID()}>Ó recoger en sucursal</h2>,
                    <Stores key={UUID()}/>
                ]}
                right={[
                    <Minicart key={UUID()}/>,
                    <Summary key={UUID()}/>,
                    <Motivation key={UUID()}/>,
                    <div className={'buttons-holder'} key={UUID()}>
                        {delivery ?
                            <Button onClick={handleSubmit} variant={'primary'}>Finalizar</Button>
                        : null }
                        <Button onClick={() => handleNavigate('/cart')} variant={'link'}>Regresar</Button>
                        <Button onClick={handleExpandPanel} variant={'link'}>Ver resumen</Button>
                    </div>
                ]}
            />
        : <Loading/>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleExpandPanel,
        submitOrder
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Step1);
