import {combineReducers} from 'redux';

import { global } from '@Src/Global/Reducer';
import { catalog } from '@Src/Catalog/Reducer';
import { cart } from '@Src/Cart/Reducer';
import { addresses } from '@Src/Addresses/Reducer';
import { stores } from '@Src/Stores/Reducer';
import { orders } from '@Src/Orders/Reducer';
import { calculator } from '@Src/Business/Reducer';

// Combine Them All! =>
const all = combineReducers({
    global,
    catalog,
    cart,
    addresses,
    stores,
    orders,
    calculator,
});

export default all;