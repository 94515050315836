import { API } from '@Src/Global/Actions';
import { getCatalog } from '@Src/Catalog/Actions';


// Indicates (for gropuped products) which is the selected variant:
export function setVariant(id, variant){
    return (dispatch, getState) => {
        const state = getState();
        let products = JSON.parse(JSON.stringify(state.catalog.products));
        products.forEach((product, idx) => {
            if (product.id === id){
                // Se reemplazan los valores coincidentes del objeto con los de la variante:
                var replaced = Object.assign({}, product,  variant);
                products[idx] = replaced;
                dispatch({
                    type 	: 'MERGE-CATALOG-STATES',
                    payload	: {
                        'products' : products,
                    },
                });
            }
        });
    };
}


// Create or get basket:
export function createBasket(){
    return (dispatch, getState) => {
        var state = getState();
        var {client, store, fingerprint} = state.global;
        store = Number(store);
        var cid    = (client) ? client.data.id : state.global.guest;

        return new Promise((resolve) => {
            // Check if there is a promo in localStorage:
            var coupon = (localStorage.getItem('coupon')) ? localStorage.getItem('coupon') : false;
            new Promise((resolve) => {
                if (coupon){
                    // Try to transfer coupon:
                    API(`cart/apply_coupon.json?client=${cid}&store=${store}&user_text=${coupon}&fingerprint=${fingerprint}`).then((res) => {
                        if (res.res_value === -1){
                            // Coupon is not valid, remove it:
                            localStorage.removeItem('coupon');
                        }
                        resolve();
                    });
                } else {
                    resolve();
                }
            }).then(() => {
                // Get/Create Basket: this method handle both, create whhen it doesn't exists, and retrieve otherwise
                API(`cart/create.json?client=${cid}&store=${store}`).then((basket) => {
                    var discounts = {};

                    // Decides and store the discount, also check if promo is applied to be recovered:
                    new Promise((resolve) => {
                        if (basket.discount_type === 'Descuento por promoción' && basket.discounts.max_discount === 'promo'){
                            // Store promo name in localStorage
                            API(`discounts/promos.json?store=${store}`).then((res) => {
                                if (res.promo){
                                    res.promo.forEach((promo) => {
                                        if (Number(promo.id) === Number(basket.discounts.promo)){
                                            localStorage.setItem('coupon', promo.coupon_text);
                                        }
                                    });
                                }

                                // Handle Discounts:
                                discounts = {
                                    'steel' : basket.steel_discount,
                                    'lamin' : basket.lamin_discount,
                                    'type'  : 'promo',
                                };
                                resolve(discounts);
                            });
                        } else {
                            // Handle Discounts:
                            discounts = {
                                'steel' : basket.discounts.steel[basket.discounts.max_discount],
                                'lamin' : basket.discounts.laminated[basket.discounts.max_discount],
                                'type'  : basket.discounts.max_discount,
                            };
                            resolve(discounts);
                        }
                    }).then((discounts) => {
                        API(`cart/items_table.json?client=${cid}&store=${store}`).then((table) => {
                            // Add items to basket
                            // Basket is now our only source of truth
                            basket.items = table.items;
                            dispatch({
                                type 	: 'MERGE-CART-STATES',
                                payload	: {
                                    'basket' : basket,
                                },
                            });
                            dispatch({
                                type 	: 'MERGE-CATALOG-STATES',
                                payload	: {
                                    'untilnextdiscount' : basket.discounts, //untilnextdiscount
                                },
                            });
                            dispatch({
                                type 	: 'MERGE-GLOBAL-STATES',
                                payload	: {
                                    'discounts' : discounts,
                                },
                            });

                            localStorage.setItem('basket', JSON.stringify(basket));
                            localStorage.setItem('discounts', JSON.stringify(discounts));
                            resolve(basket);
                        });
                    });
                });
            });
        });
    };
}

// Set product quantity directly:
// When silent equals true, the notification is not shown, this is useful for when cart is transferred from guest to client.
export function setCartQty(product, qty){
    return (dispatch, getState) => {
        qty = Number(qty);
        const state   = getState();
        // Define the product to add by its sale_code/sku:
        // When it's a variant, we need to make sure we are sending the proper code:
        var SKU = (product.code) ? product.code : product.sale_code;

        if(qty === 0){
            dispatch({
                type : 'MERGE-CATALOG-STATES',
                payload : {
                    'loading' : true,
                }
            });
        }
        return new Promise((resolve, reject) => {
            // Client or Guest
            var {client, store} = state.global;
            var cid    = (client) ? client.data.id : state.global.guest;
            var basket = state.cart.basket;

            // Check if the product is already in basket:
            var exists = (basket && basket.items) ? basket.items.filter( p => p.sale_code === SKU)[0] : false;
            let message = '';
            let type = 'info';

            new Promise((resolve) => {
                if (qty === 0){
                    if (exists){
                        API(`cart/remove_item.json?client=${cid}&store=${store}&item=${exists.rid}`).then(() => {
                            message = 'El producto ha sido retirado de tu canasta';
                            resolve();
                        });
                    } else {
                        message = 'No se han agregado productos a tu canasta.';
                        resolve();
                    }
                } else {
                    API(`cart/add_item.json?client=${cid}&code=${SKU}&store=${store}&quant=${qty}`).then((res) => {
                        type = 'success';
                        if (res.res_value !== 0){
                            message = 'Lo sentimos, el producto no ha podido agregarse, motivo: ' + res.res_text;
                            type = 'error';

                            //  'No hay piezas disponibles
                            if (res.res_text === 'No hay piezas disponibles'){
                                message = 'No contamos con el número de piezas disponibles para este producto, sin embargo, hemos agregado todas las disponibles a tu canasta.';
                                type = 'info';
                            }
                        } else {
                            message = `${product.description} ha sido agregado a la canasta.`;
                            if (res.res_text === 'Producto actualizado correctamente'){
                                message = `${product.description} ha sido actualizado en la canasta.`;
                            }
                        }
                        resolve();
                    });
                }
            }).then(() => {
                dispatch(reCalculate()); // reCalculate at the end
                resolve({ type, message });
            }).catch((e) => {
                reject({ type: 'error', message: 'Error al momento de agregar el producto a la canasta.' });
            });
        });
    };
}

// Re-Calculate:
export function reCalculate(){
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(createBasket()).then(() => {
                /*
                dispatch(getCatalog()).then(() => {
                    resolve();
                });
                */
                resolve();
            });
        });
    };
}

// Especifica el método de envío y tienda/dirección para la orden actual:
export function setDelivery(method, location = undefined){
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            var state = getState();
            var delivery = (state.cart.delivery) ? JSON.parse(JSON.stringify(state.cart.delivery)) : {};
            delivery.method = method;
            // Asignación directa de la dirección/tienda:
            if (location !== undefined){
                delivery.location = location;
            } else {
                if (method === 'address'){
                    // Buscamos la dirección predeterminada:
                } else {
                    // Buscamos la tienda predeterminada:
                }
            }

            dispatch({
                type : 'MERGE-CART-STATES',
                payload : {
                    'delivery' : delivery,
                },
            });
            resolve(delivery);
        });
    };
}



// Asigna la dirección predeterminada:
export function defaultAddress(){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var state = getState();
            var addresses = state.addresses.items;
            var {delivery} = state.cart;
            if (addresses && addresses.length > 0){
                addresses.forEach((item, idx) => {
                    // Si la dirección es predeterminada, se registra para el pedido actual:
                    if (item.default_address){
                        dispatch(setDelivery('address', item)).then(() => {
                            resolve();
                        });
                    }
                    // Si ninguna dirección está marcada como pre-definida, usamos la primera:
                    if (idx === (addresses.length - 1) && !delivery.location){
                        dispatch(setDelivery('address', addresses[0])).then(() => {
                            resolve();
                        });
                    }
                });
            } else {
                resolve();
            }
        });
    };
}



// Submit order
export function submitOrder(){
    return (dispatch, getState) => {
        var state = getState();
        var { delivery, basket} = state.cart;
        var { client, store, fingerprint } = state.global;
        var cid    = (client) ? client.data.id : state.global.guest; // Client id depending on guest or loged user...
        var fields = {};

        return new Promise((resolve) => {
            // Process according delivery type:
            new Promise((resolve) => {
                // Append fields to send:
                Object.keys(client.data).forEach((field) => {
                    if (client.data[field] && client.data[field] !== null){
                        if (field === 'phone'){
                            client.data[field] = client.data[field].toString();
                        }
                        fields[field] = client.data[field];
                    }
                });
                fields.rid = basket.id;
                fields.store = store;

                // Store or Adress id depending on delivery method:
                fields.delivery_reference = Number(delivery.location.id);

                // With address?
                if (delivery.method === 'address'){
                    fields.delivery_method =  2;

                    // Get address data:
                    var i = 0;
                    Object.keys(delivery.location).forEach((field) => {
                        i++;
                        fields[`address_${field}`] = delivery.location[field];
                        if (i === Object.keys(delivery.location).length){
                            fields.text = JSON.stringify(delivery.location); // Add addres to generic text field for order
                            resolve(fields);
                        }
                    });
                } else {
                    // With-Pickup
                    fields.delivery_method = 1;
                    fields['pickup-store'] = delivery.location.name + ' - ' + delivery.location.address;
                    resolve(fields);
                }
            }).then((fields) => {
                // Send client to purchase_request:
                fields.client = cid;
                fields.fingerprint = fingerprint;

                API('cart/purchase_request.json', 'POST', fields).then((res) => {
                    // Always expect for 0 as res_value:
                    if (res.res_value.toString() === '0'){
                        API('client/order.json', 'POST', fields).then((res) => {
                            if (res.error){
                                resolve({
                                    success: false,
                                    message: res.error,
                                }); // Inform it didn't work...
                            } else {
                                // Guardamos la canasta en localStorage para poder mostrarla en la pantalla de "success", al movernos de pantalla se borrará.
                                localStorage.setItem('lastBasket', localStorage.getItem('basket'));
                                //dispatch(isValidToken()).then(() => {
                                    dispatch(reCalculate()).then(() => {
                                        resolve({
                                            success: true,
                                            message: '¡Gracias! El pedido ha sido completado'
                                        });
                                    });
                                //});
                            }
                        });
                    } else {
                        new Promise((resolve) => {
                            if (res.res_text === 'Estatus incorrecto'){
                                // Re-activate basket:
                                API(`cart/reactivate.json?client=${cid}&store=${store}`).then(() => {
                                    resolve('Lo sentimos has excedido el tiempo de espera para confirmar tu pedido, es posible que algunos podructos ya no esten disponibles, por favor revisa tu carrito antes de continuar');
                                });
                            } else {
                                resolve(res.res_text);
                            }
                        }).then((message) => {
                            resolve({
                                success: false,
                                message: message,
                            });
                        });
                    }
                });
            });
        });
    };
}


// Transfer cart from guest to client
export function transferCart(){
    return (dispatch, getState) => {
        var state = getState();
        var { client, guest, store } = state.global;
        var cid    = (client) ? client.data.id : state.global.guest; // Client id depending on guest or loged user...
        return new Promise((resolve) => {
            var cbasket = JSON.parse(JSON.stringify(state.cart.basket)); // Clone current guest basket before get rid of it
            var counter = 0;
            // Free stock from cart
            if (cbasket.pieces > 0){
                // If guest basket has items on it, we will transfer them to client basket:
                API(`cart/return_pieces_to_stock.json?client=${guest}&store=${store}`).then(() => {
                    // Loop through all items of guest cart:
                    cbasket.items.forEach((item) => {
                        var qty = item.quantity;
                        // Add to cart with silent = true to avoid notifications
                        API(`cart/add_item.json?client=${cid}&store=${store}&code=${item.sale_code}&quant=${qty}`).then(() => {
                            counter++;
                            // Resolve when all items where moved to client's cart:
                            if (counter === cbasket.items.length){
                                dispatch(reCalculate()).then(() => {
                                    resolve(true);
                                });
                            }
                        });
                    });
                });
            } else {
                // The guest user doesnt have items to transfer, continue:
                dispatch(reCalculate()).then(() => {
                    resolve(false);
                });
            }
        });
    };
}


export function clearCart() {
    return (dispatch, getState) => {
        var state = getState();
        var { client, store, guest } = state.global;
        var { basket } = state.cart;
        var cid    = (client) ? client.data.id : guest;

        return new Promise((resolve) => {
            var counter = 0;
            basket.items.forEach((item) => {
                API(`cart/remove_item.json?client=${cid}&store=${store}&item=${item.rid}`).then(() => {
                    counter++;
                    if (counter === basket.items.length){
                        API(`cart/return_pieces_to_stock.json?client=${cid}&store=${store}`).then(() => {
                            localStorage.removeItem('basket');
                            dispatch({
                                type 	: 'MERGE-CART-STATES',
                                payload	: {
                                    'basket' : false,
                                },
                            });
                            resolve();
                        });
                    }
                });
            });
        });
    };
}


// Validate and Activate a promo:
export function activatePromo(values){
    return (dispatch, getState) => {
        var state = getState();
        var {store, client, fingerprint} = state.global
        var cid    = (client) ? client.data.id : state.global.guest;

        // Waiting message ...

        return new Promise((resolve, reject) => {
            // 1) Check if current store has active promos:
            API(`discounts/promos.json?store=${store}`).then((res) => {
                if(res.error){
                    resolve({
                        type: 'error',
                        message: <p>Lo sentimos, la tienda actual no cuenta con códigos promocionales activos</p>,
                    });
                } else {
                    // 2) Check if give code exists and is valid:
                    API(`cart/apply_coupon.json?client=${cid}&store=${store}&user_text=${values.code}&fingerprint=${fingerprint}`).then((res) => {
                        var error;
                        if(res.res_value === -1){
                            if(res.res_text === 'Coupon text mismatch')
                            error = "Tu código no es válido";
                            else if(res.res_text === 'Parametros incorrectos')
                            error = "Tu código no es válido";
                            else
                            error = "Tu código no es válido";

                            resolve({
                                type: 'error',
                                message: error,
                            });
                        } else {
                            dispatch(reCalculate()).then(() => {
                                localStorage.setItem('coupon', values.code); // Stores accepted promo in local storage...

                                resolve({
                                    type: 'success',
                                    message: <p><b>¡Felicidades!</b>Tu descuento ha sido aplicado.</p>,
                                });
                            });
                        }
                    });
                }
            });
        });
    }
}

// Determines if the mobile bottom-to-top panel is expanded or not
export function handleExpandPanel(){
    return (dispatch, getState) => {
        let state = getState();

        dispatch({
            type : 'MERGE-CART-STATES',
            payload : {
                'expandedPanel' : !state.cart.expandedPanel,
            }
        });


    }
}


// Set payment method:
export function setMethod(method){
    return (dispatch) => {
        dispatch({
            type : 'MERGE-CART-STATES',
            payload : {
                'method' : method,
            }
        });
    }
}