import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

// Icons
import { RiQuestionnaireLine } from 'react-icons/ri';
import { IoDiamondOutline } from 'react-icons/io5';
import { TbBulb } from 'react-icons/tb';
import { GiPayMoney } from 'react-icons/gi';

const Start = ({ }) => {
    return (
        <div className='business-start'>
            <h2>Inicia tu negocio y triunfa de inmediato</h2>
            <h3>Te recomendamos seguir los siguientes pasos para iniciar y ver resultados desde tu inversión</h3>
            <div className='blocks'>
                <section>
                    <div className='icon-holder'>
                        <RiQuestionnaireLine size={50} color={'#EB6B2F'}/>
                    </div>
                    <h2><b>1</b>Asesórate</h2>
                    <p>Nada es mas valioso que tu tiempo e inviersión</p>
                </section>
                <section>
                    <div className='icon-holder'>
                        <IoDiamondOutline size={50} color={'#EB6B2F'}/>
                    </div>
                    <h2><b>2</b>Diversifica</h2>
                    <p>Tu modelo de negocio, no necesita ser un local</p>
                </section>
                <section>
                    <div className='icon-holder'>
                        <TbBulb size={50} color={'#EB6B2F'}/>
                    </div>
                    <h2><b>3</b>Identifica</h2>
                    <p>Tu modelo de negocio, no necesita ser un local</p>
                </section>
                <section>
                    <div className='icon-holder'>
                        <GiPayMoney size={50} color={'#EB6B2F'}/>
                    </div>
                    <h2><b>4</b>Invierte y reinvierte</h2>
                    <p>Asigna un presupuesto y reinvierte los primeros meses</p>
                </section>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Start);
