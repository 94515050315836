import './reset.css';
import './styles.css';
import React from 'react';

import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// Service Worker
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Redux =>
import { createStore, applyMiddleware } from 'redux';

// Thunk =>
import thunk from 'redux-thunk';

// Reducers =>
import CombineReducers from '@Src/combine-reducers';

// Provider =>
import {Provider} from 'react-redux';

// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Routing
import { BrowserRouter } from 'react-router-dom';

// Fingerprint
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Components
import Navigator from '@Src/Global/Navigator';
import Header from '@Src/Header/Header';
import Footer from '@Src/Footer/Footer';

// Actions
import { setFingerprint, windowSize } from "@Src/Global/Actions";

// Redux Store is initialized here =>
const store = createStore(CombineReducers, applyMiddleware(thunk));

// Whatch window resize:
if(window) {
	store.dispatch(windowSize());
	window.addEventListener('resize', () => {
		store.dispatch(windowSize());  // Stores widnow size in state
	});
}

// FingerprintJS
(async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    store.dispatch(setFingerprint(result.visitorId));
})();


// Escucha por actualizaciones al PWA:
window.addEventListener("message", (event) => {
	if(event.origin === window.location.origin && event.data.type === 'PWA-NEWER-VERSION'){
		toast.success('Elements of Steel se ha actualizado');
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	}
}, false);

// Escuchar por prommpt de instalación PWA y almacena el evento en variable:
window.addEventListener('beforeinstallprompt', (e) => {
	e.preventDefault();
	document.deferredPrompt = e;
});

// Remove this later!!
if(localStorage.getItem('client') && !JSON.parse(localStorage.getItem('client')).data){
	console.log('Clear localStorage');
	localStorage.removeItem('client');
}

function reqListener () {
	let version = this.responseText.split('data-version="');
	if(version[1]){
		version = version[1].split('"')[0];
		if(!localStorage.getItem('version')){
			localStorage.setItem('version', version);
		} else {
			if(localStorage.getItem('version') !== version){
				localStorage.setItem('version', version);
				clearInterval(document.checkVersion);
				window.location.reload();
			}
		}
	}
}
document.checkVersion = setInterval(() => {
	const req = new XMLHttpRequest();
	req.addEventListener("load", reqListener);
	req.open("GET", window.location.origin+'/ts='+Date.now());
	req.send();
}, 5000);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.Fragment>
		<Provider store={store}>
			<BrowserRouter>
				<main>
					<Header/>
					<Navigator/>
					<Footer/>
				</main>
			</BrowserRouter>
			<ToastContainer
				autoClose={1000}
			/>
		</Provider>
	</React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();