import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { verifyAccount } from '@Src/Register/Actions';

// Components =>
import Loading from '@Shared/Loading';

// Icons
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { VscError } from 'react-icons/vsc';

// Routing
import { useNavigate, useParams } from 'react-router-dom';

// Toast
import { toast } from 'react-toastify';

const Verify = ({ verifyAccount }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [icon, setIcon] = useState(<Loading size={16}/>);
    const [message, setMessage] = useState('Un momento, estamos verificando la información de la cuenta...');

    useEffect(() => {
        verifyAccount(params.token).then((res) => {
            if(res.type === 'success'){
                setIcon(<AiOutlineCheckCircle size={32} color={'#34C240'}/>);
                setMessage('¡La cuenta ha sido verificada exitósamente! Ingresando a tu cuenta...');
                setTimeout(() => {
                    toast.success(res.message);
                    navigate('/profile');
                }, 1000);
            } else {
                setIcon(<VscError size={32} color={'#CF0043'}/>);
                setMessage('Lo sentimos, no ha sido posible verificar la cuenta, por favor intenta ponerte en contacto con nosotros.');
            }
        });
    }, []);

    return (
        <div className='verify-account'>
            <div className='content'>
                <h1>{icon} <span>Verificación de cuenta</span></h1>
                <p>{message}</p>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        verifyAccount,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Verify);
