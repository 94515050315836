import React, { useRef } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Icons
import { BiSearchAlt2 } from 'react-icons/bi';

// Actions
import { setFlyout, setPanel } from '@Src/Global/Actions';
import { setParams, getCatalog, clearParams } from '@Src/Catalog/Actions';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Keyword = ({ setParams, setFlyout, setPanel, clearParams, catalog: { perpage, params }, focus = true, callBack = false }) => {
    const navigate = useNavigate();
    const inpRef = useRef();
    const handleEnter = (e) => {
        // Handle "Enter"
        if(e.keyCode === 13){
            handleSumbit();
        }
    }

    const handleSumbit = () => {
        let value = inpRef.current.value;
        let obj = {
            'keyword': value,
            'start' : 0,
            'end'   : perpage,
        };

        if(params.brand){
            obj.brand = params.brand;
        }

        obj = { ...params, ...obj };

        clearParams().then(() => {
            setParams(obj).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`,
                });
                setFlyout(false);
                setPanel(false);
            });
        });
    }

    const handleCallBack = (e) => {
        if(callBack){
            callBack(e);
        }
    }

    return (
        <fieldset className='search-keyword'>
            <input autoFocus={focus} ref={inpRef} type="text" name={'keyword'} onChange={handleCallBack} onKeyUp={handleEnter} placeholder={'Buscar en Elements of Steel'}/>
            <button type="button" onClick={handleSumbit}>
                <BiSearchAlt2 size={24} color={'#898989'}/>
            </button>
        </fieldset>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setParams,
        getCatalog,
        setFlyout,
        clearParams,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Keyword);
