import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal } from '@Src/Global/Actions';

// Components =>
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

const Confirm = ({ title, subtitle, callBack, setModal }) => {
    const [executing, setExecuting] = useState(false);
    const handleCallBack = () => {
        setExecuting(true);
        callBack();
    }

    return (
        <div className='modal-confirm'>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
            {!executing ?
                <div className='buttons-holder'>
                    <Button variant={'secondary'} onClick={handleCallBack}>Aceptar</Button>
                    <Button variant={'link'} onClick={() => setModal(false)}>Cancelar</Button>
                </div>
            : <Loading size={16}/>}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Confirm);
