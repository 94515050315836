import update from 'immutability-helper';

export const addresses = (state = {
    'items'  : false,
    'current' : (localStorage.getItem('address')) ? JSON.parse(localStorage.getItem('address')) : false, // La dirección actual que se está viendo o editando
}, action) => {
    switch (action.type) {
		case 'MERGE-ADDRESSES-STATES':
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state;
};
