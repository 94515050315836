import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { getAddresses } from '@Src/Addresses/Actions';
import { getStores } from '@Src/Stores/Actions';
import { createBasket } from '@Src/Cart/Actions';

// Rputing
import { useMatch } from 'react-router-dom';

// Components =>
import Loading from '@Shared/Loading';
import Auth from '@Src/Cart/Auth';
import Step1 from '@Src/Cart/Step1';
import Step2 from '@Src/Cart/Step2';
import Step3 from '@Src/Cart/Step3';
import Step4 from '@Src/Cart/Step4';
import Empty from '@Src/Cart/Empty';

const Cart = ({ global: { client }, cart: { basket }, getAddresses, getStores, createBasket }) => {
    const matcher = useMatch('/cart/:step');
    const [Step, setStep] = useState(<Loading/>);
    const steps = {
        'step-1': <Step1/>,
        'step-2': <Step2/>,
        'step-3': <Step3/>,
        'step-4': <Step4/>,
    };

    useEffect(() => {
        // 1) Cart is empty, let use know:
        if((!basket.pieces || basket.pieces < 1) && matcher?.params?.step !== 'step-4'){
            setStep(<Empty/>);
        } else {
            // 2) Show steps accordignly
            if(!matcher?.params?.step){
                setStep(<Step1/>);
            } else {
                if(!client){
                    setStep(<Auth/>);
                } else {
                    setStep(steps[matcher?.params?.step]);
                }
            }
        }

        // Get user's addresses:
        getAddresses();

        // Get stores
        getStores();
    }, [basket]);

    useEffect(() => {
        createBasket();
    }, []);

    return (
        <div id={'cart'}>
            {Step}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getAddresses,
        getStores,
        createBasket,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Cart);
