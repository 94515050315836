import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal } from '@Src/Global/Actions';
import { setDelivery } from '@Src/Cart/Actions';
import { deleteAddress } from '@Src/Addresses/Actions';

// Components
import Confirm from '@Shared/Confirm';
import Form from '@Src/Addresses/Form';

// Icons
import { AiOutlineDelete } from 'react-icons/ai';

// Toast
import { toast } from 'react-toastify';

const Address = ({ address, selectable = false, setDelivery, setModal, cart : { delivery }, deleteAddress }) => {
    const handleSelect = () => {
        setDelivery('address', address);
    }

    const isSelected = (delivery?.method === 'address' && delivery?.location?.id === address.id) ? 'selected' : '';

    const handleDelete = () => {
        deleteAddress(address.id).then(() => {
            toast.success(`La dirección "${address.alias}" ha sido eliminada`);
            setModal(false);
        });
    }

    const handleConfirm = () =>{
        setModal(<Confirm title={'Confirma tu acción'} subtitle={'Estás a punto de eliminar esta dirección. ¿Deseas continuar?'} callBack={handleDelete}/>);
    }

    return (
        <div className='address-card'>
            <h2>{address.locality}</h2>

            {selectable ?
                <button type={'button'} className={'btn-radio '+isSelected} onClick={handleSelect}></button>
            :
                <button type={'button'} className={'btn-delete'} onClick={handleConfirm}>
                    <AiOutlineDelete size={18} color={'#C4C4C4'}/>
                </button>
            }
            <label>Dirección</label>
            <p>{address.street} Col. {address.area} {address.city}, {address.state}</p>
            <div className='table-row'>
                <div className='table-col'>
                    <label>Exterior</label>
                    <span>{address.exterior || '-'}</span>
                </div>
                <div className='table-col'>
                    <label>Interior</label>
                    <span>{address.interior || '-'}</span>
                </div>
                <div className='table-col'>
                    <label>C.P.</label>
                    <span>{address.cp}</span>
                </div>
            </div>
            <button onClick={() => setModal(<Form address={address}/>)} type='button' className='btn-edit'>Editar</button>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setDelivery,
        setModal,
        deleteAddress,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Address);
