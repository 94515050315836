import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { getStores } from '@Src/Stores/Actions';

// Components =>
import Loading from '@Shared/Loading';
import Button from '@Shared/Button';

// Google maps
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

// Icons
import { RiArrowRightSLine } from 'react-icons/ri';
import { IoLocation } from 'react-icons/io5';

// Pin for Google Maps
import Pin from '@Assets/map-pin.png';

//
import { useParams } from 'react-router-dom';

const Branches = ({ stores: { items }, getStores, global: { store }}) => {
	const params = useParams();
	const [zoom, setZoom] = useState(6);
	const [selected, setSelected] = useState(false);
	const [infoWindow, setInfoWindow] = useState(false);

	const center = {
		lat: 20.4720316,
		lng: -101.3012764
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyA6POtYsNTohwUoBSqMkeJik8tklv3St9w"
	});

	const [map, setMap] = React.useState(null)

	const onLoadMap = React.useCallback(function callback(map) {
		setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	useEffect(() => {
		new Promise((resolve) => {
			getStores(true).then((res) => {
				resolve(res);
			});
		}).then((res) => {
			if(params.city && ['GDL','CDMX'].includes(params.city)){
				let done = false;
				Object.keys(res[params.city]).forEach((key) => {
					let s = res[params.city][key];
					if(s.latitude && s.longitude && !done){
						handleInfoWindow(s);
						done = true;
					}
				});
			}
		});
	}, [store]);

	const handleInfoWindow = (store) => {
		setSelected(store);
		if(store.latitude && store.longitude){
			setInfoWindow(
				<InfoWindow
					open={true}
					position={{
						lat: store.latitude,
						lng: store.longitude,
					}}
				>
					<div className="map-info-window">
						<b>{store.name}</b>
						<b>{store.address}</b>
					</div>
				</InfoWindow>
			);

			let main = document.getElementsByTagName('main')[0];
			main.scrollTo({top: 0, behavior: 'smooth'});

			setTimeout(() => {
				if(map){
					map.setCenter({
						lat: store.latitude,
						lng: store.longitude,
					});
					setZoom(17);
				}
			}, 100);
		}
	}

	return (
		<div id="branches">
			<h1>Nuestras sucursales y puntos de venta</h1>
			{items && items.CDMX && items.GDL ?
				<div className='content'>
					<aside>
						{Object.keys(items).map((region) => {
							return(
								<section key={UUID()}>
									<h2><IoLocation size={24} color={'#FFFFFF'}/> {region}</h2>
									{Object.keys(items[region]).map((k) => {
										let s = items[region][k];
										s.id = k;
										let isSelected = (s.id === selected.id) ? 'selected' : '';
										return (
											(s.latitude && s.longitude) ?
												<address key={UUID()} onClick={() => handleInfoWindow(s)} className={isSelected}>
													<h3>{s.name} <RiArrowRightSLine color={'#898989'} size={28}/> </h3>
													<p>{s.address}</p>
													<Button variant={'primary'}>Cómo llegar</Button>
												</address>
											: null
										)
									})}
								</section>
							);
						})}
					</aside>
					<div className='map-holder'>
						{isLoaded ?
							<GoogleMap
								mapContainerStyle={{
									width: '100%',
									height: '100%'
								}}
								center={center}
								zoom={zoom}
								onLoad={onLoadMap}
								onUnmount={onUnmount}
							>
								{ /* Child components, such as markers, info windows, etc. */ }
								{Object.keys(items).map((region) => {
									return(
										Object.keys(items[region]).map((k) => {
											let store = items[region][k];

											return (
												store.latitude && store.longitude ?
													<Marker key={UUID()}
														animation={'BOUNCE'}
														position={{
															lat: store.latitude,
															lng: store.longitude
														}}
														icon={Pin}
														onClick={() => handleInfoWindow(store) }
													/>
												: null
											)
										})
									);
								})}
								{infoWindow}
							</GoogleMap>
						: null }
					</div>
				</div>
			: <Loading/>}
		</div>
	)
};

// mapStateToProps =>
function mapStateToProps(state){
	return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
	return bindActionCreators({
		// Actions
		getStores,
	}, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Branches);
