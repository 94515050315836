import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Framer Motion
import { motion as Motion } from 'framer-motion';

// Components =>
import PanelUser from '@Src/Panels/User';

const Template = ({ children }) => {
    return (
        <Motion.Div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='user-template'
        >
            <div className='user-template-side'>
                <PanelUser/>
            </div>
            <div className='user-template-content'>
                <div className='content'>
                    {children}
                </div>
            </div>
        </Motion.Div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Template);
