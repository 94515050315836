import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Components =>

// Swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// Image
import Banner1 from '@Assets/about/banners/banner-1.jpg';

const Banners = ({ }) => {
    const banners = [
        {
            banner: Banner1,
            title: {
                orange: 'DISEÑO',
                black: '& PASIÓN',
            },
            subtitle: 'Desde 2011 nuestra pasión por los metales y el diseño nos llevo por el camino del arte.',
            text: <React.Fragment>
                <p>En nuestra búsqueda del accesorio perfecto, nos dimos cuenta de que... No existe!</p>
                <p>Es decir, no existe sin la identidad y energía que le trasmitimos nosotros mismos.</p>
                <p>Cada elemento o accesorio que elegimos, proyecta nuestros gustos, nuestra esencia, lo que somos, por lo que se convierte en un reflejo de nosotros mismos.</p>
            </React.Fragment>
        }
    ];

    return (
        <Swiper
            className='about-banners'
            spaceBetween={0}
            slidesPerView={'auto'}
            autoplay={true}
            modules={[Navigation]}
            navigation={{ nextEl: "#swiper-next", prevEl: "#swiper-back" }}
        >
            {banners.map((banner) => {
                return (
                    <SwiperSlide className={'banner-item'} key={UUID()}>
                        <div className='img-holder' style={{backgroundImage: `url(${banner.banner})`}}></div>
                        <div className='texts'>
                            <h1 data-black={banner.title.black}>{banner.title.orange}</h1>
                            <h3>{banner.subtitle}</h3>
                            {banner.text}
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Banners);
