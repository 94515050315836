import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions
import { setModal, setPanel } from '@Src/Global/Actions';
import { validatePOS } from '@Src/Login/Actions';

// Components =>
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

// Toast
import { toast } from 'react-toastify';

// Routing
import { useNavigate } from 'react-router-dom';

const Pos = ({ validatePOS, setModal, setPanel }) => {
    const [modalStyles, setModalStyles] = useState('forgot-password');
    const navigate = useNavigate();

    useEffect(() => {
        setModalStyles('forgot-password slide');
    }, []);

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        username: Yup.string().required('Es necesario indicar un e-mail o celular.'),
    });

    return (
        <Formik
            initialValues={{
                username: '',
            }}
            validationSchema={YupSchema}
            onSubmit={(values, { setSubmitting, resetForm }) =>{
                validatePOS(values).then((r) => {
                    var res = r.res;
                    setSubmitting(false);
                    if(r.success){
                        if(res.active  === "T"){
                            toast.success('Tu cuenta ya ha sido activada y está lista para usarse. Ahora puedes iniciar sesión.');
                        } else {
                            navigate('/register', { state: res });
                            setPanel(false);
                        }
                    } else {
                        toast.error(r.message);
                    }

                    setModal(false);
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div className={modalStyles}>
                    <h2>Completa tu registro</h2>
                    <p>Escribe tu e-mail ó número de celular:</p>
                    <Input
                        label        = {'E-mail o celuar'}
                        placeholder  = {'E-mail o celuar:'}
                        name         = {'username'}
                        values       = {values}
                        onChange     = {handleChange}
                        errors       = {errors}
                        onFocus      = {handleBlur}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    { !isSubmitting ? <Button variant={'secondary'} onClick={handleSubmit}>Validar informacion</Button> : <Loading/> }
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        validatePOS,
        setModal,
        setPanel
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Pos);
