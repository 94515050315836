import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect, useDispatch} from 'react-redux';

// Actions =>
import { setFlyout, setPanel, storeById, setModal, setHeaderVisibility, getInitials } from '@Src/Global/Actions';
import { clearParams, setParams, handleOutlet } from '@Src/Catalog/Actions';

// Components =>
import Flyout from '@Shared/Flyout';
import Closer from '@Shared/Closer';
import Linex from '@Shared/Linex';
import InStore from '@Shared/InStore';
import Categories from '@Src/Flyouts/Categories';
import Collections from '@Src/Flyouts/Collections';
import Search from '@Src/Flyouts/Search';
import Login from '@Src/Login/Login';

// Side Panels
import PanelUser from '@Src/Panels/User';
import PanelMenu from '@Src/Panels/Menu';
import PanelSearch from '@Src/Panels/Search';
import PanelLogin from '@Src/Panels/Login';

// Icons
import { BiUser } from 'react-icons/bi';
import { BiSearchAlt2 } from 'react-icons/bi';
import { IoCartOutline } from 'react-icons/io5';
import { AiOutlineMenu } from 'react-icons/ai';
import { IoArrowBack } from 'react-icons/io5';
import { IoStorefrontOutline } from 'react-icons/io5';

// Routing
import { createSearchParams, Link, useNavigate, useLocation } from 'react-router-dom';

const Header = ({ catalog: { untilnextdiscount }, global: { store, flyout, client, wsize, hideHeader }, cart: { basket }, setFlyout, setPanel, handleOutlet, clearParams, setHeaderVisibility }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const _handleOutlet = () => {

        handleOutlet().then(() => {
            clearParams().then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`
                });
            });
        });
    }

    const handleNavigate = () => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            });
        });
    }

    // Force to get out of outlet if user is already there
    useEffect(() => {
        if([6,9].includes(store)){
            _handleOutlet();
        }
    }, []);

    const main =  document.getElementsByTagName('main')[0];
    const [position, setPosition] = useState(main?.scrollTop || 0);
    useEffect(()=> {
        if(wsize.w < 1080 && main && ['/catalog', '/'].includes(location.pathname)){
            const handleScroll = () => {
                let moving = main?.scrollTop;
                if(position > 180){
                    setHeaderVisibility(position > moving);
                }
                setPosition(moving);
            };
            main.addEventListener("scroll", handleScroll);
            return(() => {
                main.removeEventListener("scroll", handleScroll);
            });
        }
    })
    const cls = hideHeader ? "" : "hidden";

    return (
        <React.Fragment>
            {/*<small className='fixed'>V.{process.env.REACT_APP_PWA_VERSION}</small>*/}
            <header className={cls}>
                <div className={'gray-bar'}>
                    <button className='global-back' onClick={() => navigate(-1) }>
                        <IoArrowBack size={20}/>
                    </button>
                    <span>{ (untilnextdiscount && untilnextdiscount.next_step_diff > 0 ) ? `¡Agrega ${untilnextdiscount.next_step_diff} piezas más a tu compra y recibe ${untilnextdiscount.next_step_disc_one}% de descuento!` : '¡Agrega piezas al carrito para subir de descuento!' }</span>
                    <div className={'buttons-holder'}>
                        <InStore/>
                        <Linex/>
                        <button type="button" className={(client) ? 'logged-in' : ''} onClick={() => setPanel(!client ? <Login/> : <PanelUser/> ) }>
                           {!client ? <BiUser size={22} color={'#505050'}/> : <div className='auth-initials'>{dispatch(getInitials(client?.data?.name || client?.alias))}</div> }
                            <span>{!client ? 'Iniciar sesión' : 'Mi cuenta'}</span>
                        </button>
                    </div>
                </div>
                <nav>
                    <Link to={'/'} className={'logo-holder'}></Link>
                    <section>
                        <Link to={'/about'}>Nosotros</Link>
                        <a onClick={handleNavigate}>Tienda</a>
                        <a onClick={() => setFlyout(<Categories/>) }>Productos</a>
                        {/*
                        {[1,6].includes(store) ?
                            <a className={[6,9].includes(store) ? 'in-outlet' : ''} onClick={_handleOutlet}>{[6,9].includes(store) ? 'Salir de ' : ''}Outlet</a>
                        : null }
                        */}
                        <a onClick={() => setFlyout(<Collections/>) }>Colecciones</a>
                        <Link to={'/business'}>Vende con nosotros</Link>

                        <button onClick={() =>  (wsize.w > 1080) ? setFlyout(<Search/>) : setPanel(<PanelSearch/>) }>
                            <BiSearchAlt2 size={30}/>
                        </button>

                        <button onClick={handleNavigate}>
                            <IoStorefrontOutline size={30} color={'#505050'}/> {/* Tienda */}
                        </button>

                        <button onClick={() => navigate('/cart') } className='cart-icon'>
                            <IoCartOutline size={30}/>
                            {basket && basket.pieces ? <span>{basket.pieces}</span> : null }
                        </button> {/* Carrito */}

                        <button onClick={() => setPanel(!client ? <PanelLogin/> : <PanelUser/>)}>
                            {!client ? <BiUser size={30} color={'#505050'}/> : <div className='auth-initials'>{dispatch(getInitials(client?.data?.name || client?.alias))}</div> } {/* User */}
                        </button>

                        <button onClick={() => setPanel(<PanelMenu/>)}>
                            <AiOutlineMenu size={30} color={'#505050'}/> {/* Hamburguer */}
                        </button>
                    </section>
                </nav>
                { flyout ? <Flyout/> : null }

                {/* Far from store location? */}
                {location.pathname.search('/catalog') !== -1 ? <Closer/> : null }
            </header>
        </React.Fragment>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setFlyout,
        setPanel,
        storeById,
        handleOutlet,
        clearParams,
        setParams,
        setModal,
        setHeaderVisibility,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Header);
