import update from 'immutability-helper';

export const cart = (state = {
    'basket'  : (localStorage.getItem('basket')) ? JSON.parse(localStorage.getItem('basket')) : false,
    'delivery' : false,
    'expandedPanel' : false, // Determines if the mobile bottom-to-top panel is expanded or not
    'method': false, // Payment method
}, action) => {
    switch (action.type) {
		case 'MERGE-CART-STATES':
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state;
};
