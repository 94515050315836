import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { setParams, handleOutlet } from '@Src/Catalog/Actions';

// Components =>
import Filters from '@Src/Catalog/Filters';
import Sorter from '@Src/Catalog/Sorter';
import Button from '@Shared/Button';

// Icons
import { IoCloseOutline } from 'react-icons/io5';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const FiltersSorters = ({ catalog: { params, perpage }, global: { hideHeader, store }, setParams, handleOutlet }) => {
    const navigate = useNavigate();

    const activeFilters = () => {
        let ret = [];
        let values;
        ['material', 'color', 'category'].forEach((g) => {
            values = (params[g]) ? params[g].split(',') : [];
            values.forEach((f) => {
                ret.push(<Button variant={'secondary'} onClick={() => handleRemoveFilter(g, f)} key={UUID()}><IoCloseOutline size={16}/> {f}</Button>);
            });
        });
        return ret;
    }

    const handleRemoveFilter = (g, f) => {
        let regex = new RegExp(`,{0,1}${f}`);
        let replacement = (typeof params[g] === 'string') ? params[g].replace(regex, '') : f;
        if(f === ''){
            replacement = '';
        }
        replacement = replacement.replace(/(^,)|(,$)/g, ""); //Removes leading and trailing commas
        localStorage.removeItem('scrollY');
        setParams({
            [g]: replacement,
            start: 0,
            end: perpage,
        }).then((nparams) => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams(nparams)}`
            });
        });
    }

    const cls = (hideHeader) ? 'filters-and-sorters' : 'filters-and-sorters hidden';

    return (
        <div className={cls}>
            <div className={'content'}>
                <div className='filters-holder'>
                    <Filters/>
                </div>
                <div className='sorters-holder'>
                    <Sorter/>
                </div>
            </div>
            {/* Active filters */}
            <div className='applied-filters'>
                {activeFilters()}
                {params.brand ? <Button variant={'secondary'} onClick={() => handleRemoveFilter('brand', '')} key={UUID()}><IoCloseOutline size={16}/> Línea X</Button> : null }
                {params.keyword ? <Button variant={'secondary'} onClick={() => handleRemoveFilter('keyword', '')} key={UUID()}><IoCloseOutline size={16}/> "{params.keyword}"</Button> : null }
                {[6,9].includes(store) ? <Button variant={'secondary'} onClick={handleOutlet} key={UUID()}><IoCloseOutline size={16}/> Outlet</Button> : null }
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setParams,
        handleOutlet,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(FiltersSorters);
