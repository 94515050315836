import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";


const Colors = ({ product, callBack }) => {
    const variants = product?.grouping?.groups[0]?.items || {};
    const type = product?.grouping?.groups[0]?.group_type || false;

    return (
        Object.keys(variants).length > 0 ?
            <React.Fragment>
                <div className='product-variants'>
                    <label>{(type === 'color') ? 'Color:' : 'Talla:'}</label>
                    <Swiper
                        className='variants'
                        spaceBetween={10}
                        slidesPerView={'auto'}
                    >
                        {Object.keys(variants).map((v) => {
                            let variant = variants[v];
                            let className = (type === 'color') ? 'color' : 'size';
                            className += (product.id === variants[v].id || product.id === variants[v].rid) ? ' selected' : '';
                            let size = (type === 'color') ? '' : variants[v].size;

                            // Color gradient variants:
                            var mainColor = null;
                            if(type === 'color') {
                                if(variant.css_colors.accent_color.count === 0 && variant.css_colors.main_color.count === 0 && variant.css_colors.model_color.count === 0){
                                    mainColor = { backgroundImage: 'linear-gradient(to left, '+variant.css_colors.default.join()+')' };
                                } else {
                                    if(variant.css_colors.model_color.count > 0)
                                    mainColor = { backgroundImage: 'linear-gradient(to left, '+variant.css_colors.model_color.colors.join()+')' };
                                    else
                                    mainColor = { backgroundImage: 'linear-gradient(to left, '+variant.css_colors.main_color.colors.join()+')' };
                                }
                            }

                            return (
                                <SwiperSlide className={className} key={UUID()} onClick={() => callBack(variants[v])} style={mainColor}>
                                    {size}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </React.Fragment>
        : null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Colors);
