import React, { useEffect, useState } from 'react';

// Redux =>
import {useSelector, useDispatch} from 'react-redux';

// Actions =>
import { UUID, API } from '@Src/Global/Actions';
import { clearParams, setParams } from '@Src/Catalog/Actions';

// Components =>

// Swiper
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Banners = () => {
    const [banners, setBanners] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNavigate = (category) => {
        dispatch(clearParams()).then(() => {
            dispatch(setParams({'category' : category})).then((params) => {
                navigate({
                    pathname: `/catalog`,
                    search: `?${createSearchParams(params)}`
                });
            });
        });
    }

    const setUrlImage = (b) => {
        console.log(window.innerWidth)
        if (window.innerWidth <= 768) {
            return b.mobile_hosted_url
        }
        return b.hosted_url
    }

    useEffect(() => {
        API(`/images/index.json`).then((res) => {
            setBanners(res.items);
        });
    }, [])

    return (
        banners ?
            <Swiper
                className='home-banners'
                spaceBetween={0}
                slidesPerView={'auto'}
                autoplay={{
                    delay: 5000,
                }}
                modules={[Autoplay]}
            >
                {banners.map((b) => {
                    let path = b.url.split('category=');
                    path = path[1];
                    return (
                        <SwiperSlide
                            className={'banner-item'}
                            onClick={() => window.location.href = b.url}
                            key={UUID()}
                            style={{ backgroundImage: `url(${setUrlImage(b)})`}}
                        />
                    );
                })}
            </Swiper>
        : null
    );
};

export default Banners
