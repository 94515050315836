import update from 'immutability-helper';

// Get params in url when arriving to Catalog from sahred urls in social media:
const urlParams = {};
for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
    urlParams[key] = value;
}

export const catalog = (state = {
    'loading'     : false,
    'products'    : false,
    'current'     : false, // Producto actual en memoria
    'perpage'     : 50,  // Controla cuántos productos se muestran "por página"
    'categories'  : false,
    'collections' : false,
    'untilnextdiscount' : false,
    'allow_brand' : false,
    'params'      : (Object.keys(urlParams).length > 0) ? urlParams : {
        start: 0,
        end: 50,
        order_dir: "desc",
        order_var: "date",
    },
    'end' : false, // End of results
    'menu'        : false, // Active filtering/sorting menu
    // Menu Refs (para obtener posicionamiento)
    menuRefs: {},
}, action) => {
    switch (action.type) {
		case 'MERGE-CATALOG-STATES':
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state;
};
