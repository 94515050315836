import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Button from '@Shared/Button';

// Icons
import { IoIosSearch } from 'react-icons/io';


const Search = ({ callBack, keyword }) => {
    return (
        <div className='search-area'>
            <div className='content'>
                <h1>¿Cómo podemos ayudarte?</h1>
                <p>También puedes explorar los temas a continuación para encontrar lo que estás buscando.</p>
                <fieldset>
                    <input value={keyword} onChange={(e) => callBack(e.target.value)} type="text" placeholder='Buscar pregunta'></input>
                    <Button variant={'primary'} onClick={() => callBack(keyword)}>
                        <IoIosSearch size={20}/>
                    </Button>
                </fieldset>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Search);
