import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Icons
import { AiFillHeart } from 'react-icons/ai';

/*
<div className='product-hearth'>
    <AiFillHeart color={'#34C240'} size={22}/>
</div>
*/

const Hearth = ({ }) => {
    return (
        null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Hearth);
