import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { handleExpandPanel, setMethod } from '@Src/Cart/Actions';

// Components =>
import Button from '@Shared/Button';
import Template from '@Src/Cart/Template';
import Complete from '@Src/Cart/Complete';
import Summary from '@Src/Cart/Summary';
import Minicart from '@Src/Cart/Minicart';

const Step4 = ({ }) => {

    useEffect(() => {
        return () => {
            // Remueve la canasta inactiva de localStorage al abandonar la pantalla, ya no la necesitamos:
            localStorage.removeItem('lastBasket');
        }
    }, [])

    return (
        <Template
            title={'¡Compra completada!'}
            className={'cart-step-4'}
            step={4}
            left={[
                <Complete key={UUID()}/>,
            ]}
            right={[
                <Minicart key={UUID()}/>,
                <Summary key={UUID()}/>,
            ]}
        />
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleExpandPanel,
        setMethod,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Step4);
