import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

// Icons
import { AiOutlineCheckCircle } from 'react-icons/ai';

// Assets
import Banner from '@Assets/register/banner.png';

const Success = ({ global:  { phones } }) => {
    return (
        <div className='register-success'>
            <div className='content'>
                <div className='column'>
                    <AiOutlineCheckCircle color={'#34C240'} size={56} />
                    <h2>Te damos la bienvenida a Elements of Steel!</h2>
                    <p>Hemos enviado un mensaje por correo y/o SMS con las instrucciones necesarias para que verifiques tu cuenta</p>

                    <b>¿No recibiste las instrucciones?</b>
                    <a href={`https://wa.me/${phones.whatsapp.clean}`} target={'_blank'} rel="noreferrer">Ponte en contacto con nosotros</a>
                </div>
                <div className='column'>
                    <img src={Banner} alt={'Banner de bienvenida'}/>
                </div>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Success);
