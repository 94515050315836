import { API, isValidToken, randStr } from '@Src/Global/Actions';
import { transferCart, reCalculate } from '@Src/Cart/Actions';


// Login
export function logIn(values){
    return (dispatch, getState) => {
        var state = getState();
        var { route } = state.global;
        return new Promise((resolve) => {
            // Redirect to?
            let redirect = false;
            let message;
            API(`client/login.json`, 'POST', values).then((res) => {
                message = (res.error) ? res.error : JSON.stringify(res['api-error']);
                if(res.error || res['api-error']){
                    // Toast:
                    resolve({
                        type: 'Error',
                        title: 'Error de acceso',
                        message,
                        redirect
                    });
                } else {
                    // We can't just trust in this response, we process the token instead:
                    dispatch(isValidToken(res.token)).then((tokenRes) => {
                        resolve({
                            message: `Hola ${tokenRes.alias}. Te damos la bienvenida`,
                        });

                        // Let the following process to run in background as they takes too long
                        // Attempt to transfer cart:
                        dispatch(transferCart()).then((transfered) => {
                            dispatch(reCalculate()).then(() => {

                            });
                        });
                    });
                }
            });
        });
    }
}

// LogOut
export function logOut(){
    return (dispatch) => {
        return new Promise((resolve) => {
            localStorage.removeItem('token');
            localStorage.removeItem('client');
            localStorage.removeItem('basket');
            dispatch({
                type 	: "MERGE-GLOBAL-STATES",
                payload	: {
                    "client" : false,
                }
            });
            dispatch({
                type 	: "MERGE-CART-STATES",
                payload	: {
                    "basket" : false,
                }
            });
            resolve({
                type: 'Info',
                title: 'Sesión concluída',
                message: 'Se ha cerrado tu sesión de cliente.',
            });
            dispatch(reCalculate());
        });
    }
}

// Recuperación de contraseña:
export function passRecover(values){
    return () => {
        return new Promise((resolve) => {
            let type;
            let message;
            API(`client/passrecover.json?username=${values.username}`).then((res) => {
                if(res.error){
                    type = 'error';
                    message = res.error;
                } else {
                    type = 'success';
                    message = 'Te hemos enviado un correo y/o SMS con las instrucciones para la recuperación de tu acceso.';
                }
                resolve({
                    message,
                    type,
                });
            });
        });
    }
}


// Defines a new password:
export function setPassword(values){
    return () => {
        return new Promise((resolve) => {
            API(`client/setpassword.json`, 'POST', values).then((res) => {
                resolve({
                    type: (res.error) ? 'error' : 'success',
                    message: res.error ? res.error : 'Se ha establecido tu nueva contraseña',
                });
            });
        });
    }
}


// Handle users that purchase as guest users (for database integrity, a deactivated account needs to be created)
export function handleGuest(values){
    return (dispatch) => {
        let pwd = "__GUEST__";
        return new Promise((resolve) => {
            values.password = pwd;
            values.cpassword = pwd;
            values.username = randStr(10);
            API(`client/register.json`, 'POST', values).then((res) => {
                if(res.error){
                    resolve({
                        success: false,
                        message: res.error,
                    });
                } else {
                    var loginForm = {
                        username : values.username,
                        password : pwd,
                    };
                    // Run login process:
                    API(`client/login.json`, 'POST', loginForm).then((res) => {
                        // Validate and register the token
                        dispatch(isValidToken(res.token)).then((token) => {
                            // Transfer cart
                            dispatch(transferCart()).then(() => {
                                // Setting guest flag:
                                localStorage.setItem('guest', true);
                                resolve({
                                    success: true,
                                });
                            });
                        });
                    });
                }
            });
        });


    }
}


export function validatePOS(values){
    return () => {
        return new Promise((resolve) => {
            API(`client/checklogin.json`, 'POST', values).then((res) => {
                if(res.error){
                    resolve({
                        success: false,
                        message: res.error,
                    });
                } else {
                    resolve({
                        success: true,
                        res: res,
                    });
                }
            });
        });
    }
}