import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { getCatalog } from '@Src/Catalog/Actions';

// Components =>
import Ribbon from '@Src/Home/Ribbon';
import Banners from '@Src/Home/Banners';
import Categories from '@Src/Home/Categories';
import Special from '@Src/Home/Special';
import Promote from '@Src/Home/Promote';
import Related from '@Src/Product/Related';
import Subanner from '@Src/Home/Subanner';
import Promo from '@Src/Home/Promo';


const Home = ({ getCatalog }) => {
    const [products, setProducts] = useState(false);

    useEffect(() => {
        // Sending true to make the catalog to retrieve catalog without filters:
        getCatalog(true).then((res) => {
            setProducts(res.items);
        });
    }, []);


    return (
        <div id="home">
            <Promo/>
            <Ribbon/>
            <Banners/>
            <Categories/>
            <Special/>
            <Promote/>
            <Related products={products} title={'Nuevos productos'}/>
            <Subanner/>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getCatalog,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Home);
