import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Button from '@Shared/Button';
import Promo from '@Src/Promo/Promo';
import Social from '@Src/Footer/Social';

// Icons
import { AiOutlineLock } from 'react-icons/ai';
import { BsShieldCheck, BsTruck, BsShopWindow } from 'react-icons/bs';
import { BsChevronDown } from 'react-icons/bs';

// Assets:
import Elements from '@Assets/logo-grayscale.png';

// Routing
import { Link, useLocation, useNavigate, createSearchParams } from 'react-router-dom';

// Actions
import { UUID } from '@Src/Global/Actions';
import { clearParams, setParams } from '@Src/Catalog/Actions';


const Footer = ({ setParams, clearParams, catalog: { end }, global: { wsize } }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleCategorySelection = (c) => {
        clearParams().then(() => {
            setParams({
                'category': c,
            }).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`
                });
            });
        });
    }

    const handleExpand = (e) => {
        let h2 = e.target;
        let section = h2.nextSibling;
        section.classList.toggle("expanded");
    }

    return (
        (location.pathname.search('/cart') !== -1) ?
            null
        : (location.pathname.search('/catalog') !== -1 && !end) ?
            null
        :
            <footer>
                <div className='footer-perks'>
                    <div className='title'>Beneficios</div>
                    <section>
                        <h2><AiOutlineLock color={'#1289C5'} size={26}/> Compra segura</h2>
                        <p>Contamos con protocolos y certificados para garantizar la privacidad y seguridad de tus datos y transacciones.</p>
                    </section>
                    <section>
                        <h2><BsShieldCheck color={'#1289C5'} size={26}/> Garantía</h2>
                        <p>Más de 10 años de experiencia respaldan tu compra.</p>
                    </section>
                    <section>
                        <h2><BsTruck color={'#1289C5'} size={26}/> Entrega a domicilio</h2>
                        <p>Tú envío vía DHL será de $200. Si tu compra es mayor a $3,000 el envío será de $150.</p>
                        <small>*Consulta condiciones (Peso, Zona Extendida y seguro)</small>
                    </section>
                    <section>
                        <h2><BsShopWindow color={'#1289C5'} size={26}/> Entrega en tienda</h2>
                        <p>Solicita la entrega de tu pedido en nuestra tienda más cercana.</p>
                        <Button variant={'link'} onClick={() => navigate('/branches') }>Encuentra tu sucursal más cercana</Button>
                    </section>
                </div>

                { wsize.w < 1080 ? <Promo/> : null }

                <div className='footer-sitemap'>
                    <nav>
                        <h2 onClick={handleExpand}>Nosotros <BsChevronDown color={'#FFFFFF'} size={18}/></h2>
                        <section>
                            <img src={Elements}/>
                            <Link to={'/about'}>Acerca de Elements</Link>
                            <Link to={'/faq'}>Preguntas frecuentes</Link>
                            <Link to={'/branches'}>Sucursales</Link>
                            <a href={'/files/POLITICAS-DEL-SITIO.pdf'} rel="noreferrer" target="_blank">Política del sitio</a>
                            <a href={'/files/AVISO-DE-PRIVACIDAD.pdf'} rel="noreferrer" target="_blank">Aviso de privacidad</a>
                            <a href={'/files/TERMINOS-Y-CONDICIONES.pdf'} rel="noreferrer" target="_blank">Términos y Condiciones</a>
                            { wsize.w > 1080 ? <Social/> : null }
                        </section>
                    </nav>
                    <nav>
                        <h2 onClick={handleExpand}>Productos <BsChevronDown color={'#FFFFFF'} size={18}/></h2>
                        <section>
                            {['Arete','Brazalete','Anillo','Cadena','Collar','Juego','Pendiente','Tobillera','Accesorios'].map((c) => {
                                return <a onClick={() => handleCategorySelection(c) } key={UUID()}>{c}</a>
                            })}
                        </section>
                    </nav>
                    <nav>
                        <h2 onClick={handleExpand}>Tu negocio <BsChevronDown color={'#FFFFFF'} size={18}/></h2>
                        <section>
                            <Link to={'/business/calculator'}>Descubre tu ganancia</Link>
                            <Link to={'/business/why'}>¿Por qué vender joyería?</Link>
                            <Link to={'/business/reasons'}>Razones para iniciar con Elements</Link>
                        </section>
                    </nav>
                    <nav>
                        <h2 onClick={handleExpand}>Tu cuenta <BsChevronDown color={'#FFFFFF'} size={18}/></h2>
                        <section>
                            <Link to={'/profile'}>Mi perfil</Link>
                            <Link to={'/orders'}>Pedidos</Link>
                            <Link to={'/addresses'}>Direcciones</Link>
                        </section>
                    </nav>
                    { wsize.w > 1080 ? <Promo/> : null }
                    { wsize.w < 1080 ? <Social/> : null }
                </div>
                <div className='footer-copyright'>Elements of Steel - Derechos reservados 2020</div>
            </footer>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        clearParams,
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Footer);
