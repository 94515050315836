import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions =>
import { handleGuest } from '@Src/Login/Actions';
import { setModal } from '@Src/Global/Actions';

// Toastify:
import { toast } from "react-toastify";

// Components:
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

// Router
import { useNavigate } from 'react-router-dom';

// Icons

const Guest = ({ handleGuest, setModal }) => {
    const [panelStyles, setPanelstyles] = useState('guest-user box');
    const navigate = useNavigate();

    useEffect(() => {
        setPanelstyles('guest-user box slide');
    }, []);

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        name: Yup.string().required('Es necesario indicar un número de cliente, email o celular.'),
        phone: Yup.string().test('phone', function(value) {
            const phoneValidation = Yup.string().required("Es necesario proporcionar una forma de comuncación e-mail o teléfono").min(10, 'Es necesario ingresar un teléfono válido de 10 dígitos').max(10, 'Es necesario ingresar un teléfono válido de 10 dígitos');
            if(this.parent.email && this.parent.email.length > 0){
                // Si el campo de correo ya fue proporcionado, el teléfono sólo se valida si no corresponde a 10 dígitos:
                if (!!value) {
                    return phoneValidation.isValidSync(value);
                }
            } else {
                // Si no se ha proporcionado campo de correo, el teléfono es requerido:
                return phoneValidation.isValidSync(value);
            }
            return true;
        }),
        email: Yup.string().test('email', function(value) {
            const emailValidation = Yup.string().required().email('Es necesario indicar una cuenta de correo válida');
            if(!this.parent.phone || this.parent.phone.length > 0){
                // Si el campo de teléfono ya fue proporcionado, se valida el e-mail como válido sólo si es proporcionado
                if (!!value) {
                    return emailValidation.isValidSync(value);
                }
            } else {
                // Si no se ha proporcionado campo de teléfono, el e-mail es requerido:
                return emailValidation.isValidSync(value);
            }
            return true;
        }),
    });

    return (
        <Formik
            initialValues={{
                name: '',
                phone: '',
                email: '',
            }}
            validationSchema={YupSchema}
            onSubmit={(values,  { setSubmitting }) =>{
                handleGuest(values).then((res) => {
                    setSubmitting(false);
                    if(res.success){
                        navigate('/cart');
                        setModal(false);
                        toast.success('Comprando como invitado');
                    } else {
                        toast.error(res.message);
                    }
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div className={panelStyles}>
                    <h2>¿Cómo podemos contactarte respecto a este pedido?</h2>
                    <p>Por favor ingresa los datos a continuació, estos nos permitirán ponernos en contacto para dar seguimiento a tu pedido.</p>
                    <Input
                        label        = {'Ingresa tu nombre'}
                        placeholder  = {'Ingresa tu nombre:'}
                        name         = {'name'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'Ingresa tu email'}
                        placeholder  = {'Ingresa tu email:'}
                        name         = {'email'}
                        values       = {values}
                        onChange     = {handleChange}
                        errors       = {errors}
                        onFocus      = {handleBlur}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'Ingresa tu celular'}
                        placeholder  = {'Ingresa tu celular:'}
                        name         = {'phone'}
                        values       = {values}
                        onChange     = {handleChange}
                        errors       = {errors}
                        onFocus      = {handleBlur}
                        touched      = {touched}
                        autoComplete = 'new-password'
                        maxLength    = {10}
                    />
                    <div className={'btn-holder'}>
                        {!isSubmitting ?
                            <Button onClick={handleSubmit} variant={'secondary'}>
                                Continuar como invitado
                            </Button>
                        :
                            <Loading/>
                        }
                    </div>
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleGuest,
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Guest);
