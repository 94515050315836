import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Components =>

const Stepper = ({ step }) => {
    return (
        <div className='cart-stepper'>
            {['','',''].map((s, idx) => {
                let iscurrent = '';
                if(step >= idx){
                    iscurrent = 'current';
                }
                return <span key={UUID()} className={iscurrent}></span>;
            })}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Stepper);
