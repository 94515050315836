import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Components =>

// Icons
import { BsFillStarFill } from 'react-icons/bs';

const Stars = ({ stars, callBack }) => {
    let color;
    return (
        <div className='product-stars'>
            {[5,4,3,2,1].map((idx) => {
                color = (idx <= stars) ? '#F6C000' : '#C4C4C4';
                return <BsFillStarFill key={UUID()} color={color} size={18} onClick={() => callBack(idx)}/>
            })}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Stars);
