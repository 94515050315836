import { API, changeStore } from '@Src/Global/Actions';

/*
    Obtiene información del catálogo, se usa una funcion centralizada que actuará acordemente
    con filtros, sesión de ususario, ordenamientos y cualquier otro parámetro; todo en un mismo lugar.
*/
export function getCatalog(noparams = false){
    return (dispatch, getState) => {
        var state = getState();
        var { store, client, guest} = state.global;
        var { params, perpage, products } = state.catalog;

        dispatch({
            type : 'MERGE-GLOBAL-STATES',
            payload : {
                'loading' : true,
            }
        });

        // Merge Parameters:
        let parameters = Object.assign({}, params, {store: parseInt(store)});

        if(!params.start){
            parameters.start = 0;
        }
        if(!params.end){
            parameters.end = perpage;
        }

        // Use this when we need results without params (related productos in home slider)
        let cleanParams = {
            store: parseInt(store),
            start: 0,
            end: perpage,
        }

        // Client ID:
        parameters.client = (client && client.data) ? client.data.id : guest;

        return new Promise((resolve) => {
            API(`catalog/index.json`, "POST", (!noparams) ? parameters : cleanParams ).then((res) => {
                dispatch({
                    type : 'MERGE-CATALOG-STATES',
                    payload : {
                        'params'     : parameters,
                        'categories' : res.categories,
                        'collections': res.web_catalogs,
                        'products'   : (parameters.start === 0) ? res.items : (!products) ? res.items : [...products, ...res.items],
                        'total'      : res.total, // Cantidad de productos que tiene el catálogo y filtros consultado
                        'colors'     : res.colors,
                        'materials'  : res.materials,
                        'allow_brand': res.allow_brand,
                    }
                });
                dispatch({
                    type : 'MERGE-GLOBAL-STATES',
                    payload : {
                        'loading' : false,
                    }
                });
                resolve(res);
            });
        });
    }
}


// Get catalog in case there is no categories to show in footer or flyout:
export function getCleanCatalog(){
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(clearParams()).then(() => {
                dispatch(getCatalog()).then(() => {
                    resolve();
                })
            });
        });
    }
}

export function setParams(obj){
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            var state = getState();
            let params = Object.assign({}, state.catalog.params, obj);

            // Remove empty params:
            Object.keys(params).forEach((k) => {
                if(obj[k] === ''){
                    delete params[k];
                }
            });

            dispatch({
                type 	: "MERGE-CATALOG-STATES",
                payload	: {
                    "params" : params,
                    "end" :  false,
                }
            });
            localStorage.setItem('params', JSON.stringify(params));
            resolve(params);
        });
    }
}


/*
    Elimina todos los parámetros que se envían al catálogo
    El parámetro "shouldNavigate" determina si debe realizar navegación o cierre de flyout después de limpiar
*/
export function clearParams(){
    return (dispatch, getState) => {
        let state = getState();
        localStorage.removeItem('scrollY');
        return new Promise((resolve) => {
            dispatch({
                type 	: "MERGE-CATALOG-STATES",
                payload	: {
                    "params" : {},
                    "end" :  false,
                }
            });
            localStorage.removeItem('params');
            resolve(state.catalog.params);
        });
    }
}



// Especifica el tipo de ordenamiento:
export function setSorting(opt){
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(setParams({
                'order_var': opt.order_var,
                'order_dir': opt.order_dir,
            })).then((nparams) => {
                resolve(nparams);
            })
        });
    }
}

// Set active filtering/sorting menu:
export function setMenu(menu){
    return (dispatch) => {
        dispatch({
            type : 'MERGE-CATALOG-STATES',
            payload : {
                'menu' : menu,
            }
        });
    }
}


// Set active filtering/sorting menu:
export function setMenuRef(name, ref){
    return (dispatch, getState) => {
        var state = getState();
        let menuRefs = state.catalog.menuRefs;
        menuRefs[name] = ref;

        dispatch({
            type : 'MERGE-CATALOG-STATES',
            payload : {
                menuRefs : menuRefs,
            }
        });
    }
}


/*
    Al seleccionar una categoría nueva desde el menú (no desde los filtros),
    realiza el limpiado de parámetros:
*/
export function handleCategoryFilter(c){
    return (dispatch, getState) => {
        let state = getState();
        let { params }  = state.catalog;

        dispatch({
            type 	: "MERGE-CATALOG-STATES",
            payload	: {
                'loading' : true,
            }
        });

        // Conserva el parámtro "web_catalog"
        let web_catalog = params.web_catalog;
        // Conserva el parámtro "brand"
        let brand = params.brand;

        dispatch(clearParams(false)).then(() => {
            dispatch({
                type 	: "MERGE-CATALOG-STATES",
                payload	: {
                    "params" : {
                        'brand' : brand,
                        'web_catalog': web_catalog,
                        'category' : c.ext_name,
                    }
                }
            });
        });
    }
}

/*
    Al seleccionar una colección desde el menú realiza el limpiado de parámetros:
*/
export function handleCollectionFilter(c){
    return (dispatch, getState) => {
        let state = getState();
        let { params }  = state.catalog;

        dispatch({
            type 	: "MERGE-CATALOG-STATES",
            payload	: {
                'loading' : true,
            }
        });

        // Conserva el parámtro "brand"
        let brand = params.brand;

        dispatch(clearParams(false)).then(() => {
            dispatch({
                type 	: "MERGE-CATALOG-STATES",
                payload	: {
                    "params" : {
                        'brand' : brand,
                        'web_catalog': c.id,
                    },
                    "loading" : false,
                }
            });
        });

    }
}



export function loadMore(e){
    return (dispatch, getState) => {
        let state = getState();
        let { params, perpage, total, products } = state.catalog;
        const bottom = Math.ceil(e.target.scrollHeight - e.target.scrollTop) === Math.ceil(e.target.clientHeight); // Increases 200 so we can start loadMore at 200px from bottom
        var nextStart = parseInt(params.end);
        var nextEnd = parseInt(params.end) + parseInt(perpage);
        if(products.length < total){
            dispatch(setParams({
                start: nextStart,
                end: nextEnd
            })).then(() => {
                dispatch(getCatalog());
            });
        } else {
            dispatch({
                type : 'MERGE-CATALOG-STATES',
                payload : {
                    'end' : true,
                }
            });
        }
    }
}


export function handleOutlet(){
    return (dispatch, getState) => {
        let state = getState();
        let { store } = state.global;
        return new Promise((resolve, reject) => {
            var outletStore; // = (store === 1) ? 6 : 9; // 1 E-Store CDMX | 6 Tienda Fábrica CDMX | 9 Tienda Fábrica GDL | 10 E-Store GDL
            if([6,9].includes(store)){
                // Estamos en outlet, la acción debería sacar al usuario de la tienda Outlet:
                outletStore = (store === 6) ? 1 : 10;
            } else {
                // Estamos en tienda normal, debería resolver a Outlet
                outletStore = (store === 1) ? 6 : 9;
            }
            dispatch(changeStore(outletStore)).then(() => {
                resolve();
            })
        });

    }
}