import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Component
import Hearth from '@Src/Product/Hearth';
import Discount from '@Src/Product/Discount';

// Actions
import { currencyFormat } from '@Src/Global/Actions';

const Prices = ({ product, global: { discounts, materialgroups } }) => {
    // Decides the discount depending on the product's material:
    var percent;
    var discount;
    var matDiscount = (materialgroups["steel"].includes(product.material)) ? 'steel' : 'lamin';
    if(!product.discount){
        percent = 0;
        discount = product["price"];
    } else {
        percent = discounts[matDiscount];
        discount = (product.price - (product.price * (percent / 100 )))
    }

    return (
        <div className='product-prices'>
            <big>{currencyFormat(discount)}</big>
            <Hearth product={product}/>
            <small>{ (discount < product.price) ? currencyFormat(product.price) : '' }</small>
            <Discount product={product}/>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Prices);
