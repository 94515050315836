import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>
import Button from '@Shared/Button';
import { useNavigate } from 'react-router-dom';

const Subanner = ({ }) => {
    const navigate = useNavigate();
    return (
        <div className='home-subanner'>
            <div className='texts'>
                <h2>¿Te interesa vender joyería?</h2>
                <p>Comienza tu historia con nosotros.</p>
                <Button variant={'primary'} onClick={() => navigate('/business') }>Leer más</Button>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Subanner);
