import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Icon
import { BsCreditCard, BsEyeSlash } from 'react-icons/bs';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Toastify:
import { toast } from "react-toastify";

// Components:
import Holder from '@Src/Cart/Methods/Holder';
import Input from '@Shared/Input';
import Checkbox from '@Shared/Checkbox';

const Conekta = ({ ...props }) => {
    // Form validation schema:
    const YupSchema = Yup.object().shape({
        card: Yup.string().required(' '),
        expiry: Yup.string().required(' '),
        cvv: Yup.string().required(' '),
    });

    return (
        <Holder
            {...props}
            header={
                <h2><BsCreditCard size={22} color={'#EB6B2F'}/> Pago en linea con Conekta</h2>
            }
            content={
                <Formik
                    initialValues={{
                        card: '',
                        expiry: '',
                        cvv: '',
                    }}
                    validationSchema={YupSchema}
                    onSubmit={(values,  {setSubmitting, setFieldValue}) =>{
                        alert('Conekta!');
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <React.Fragment>
                            <div className='method-block'>
                                <h2>Ingresa los datos de tu tarjeta:</h2>

                                <div className='inputs-row'>
                                    <Input
                                        label        = {'Número de tarjeta'}
                                        icon         = {<BsCreditCard/>}
                                        placeholder  = {'0000-0000-0000-0000'}
                                        name         = {'card'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        onFocus      = {handleBlur}
                                        errors       = {errors}
                                        touched      = {touched}
                                        autoComplete = 'new-password'
                                        mask={'9999-9999-9999-9999'}
                                    />
                                </div>

                                <div className='inputs-row'>
                                    <Input
                                        label        = {'Vencimiento'}
                                        placeholder  = {'MM/AAAA'}
                                        name         = {'expiry'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        onFocus      = {handleBlur}
                                        errors       = {errors}
                                        touched      = {touched}
                                        autoComplete = 'new-password'
                                        mask={'99/9999'}
                                    />

                                    <Input
                                        label        = {'CVV'}
                                        placeholder  = {'***'}
                                        icon         = {<BsEyeSlash/>}
                                        name         = {'cvv'}
                                        values       = {values}
                                        onChange     = {handleChange}
                                        onFocus      = {handleBlur}
                                        errors       = {errors}
                                        touched      = {touched}
                                        autoComplete = 'new-password'
                                        mask={'999'}
                                    />
                                </div>

                                <Checkbox text="Guardar tarjeta para futuras referencias"/>
                                <Input
                                    label        = {'Alias de la tarjeta'}
                                    placeholder  = {'Nómina, Pagos, etc... '}
                                    name         = {'cvv'}
                                    values       = {values}
                                    onChange     = {handleChange}
                                    onFocus      = {handleBlur}
                                    errors       = {errors}
                                    touched      = {touched}
                                    autoComplete = 'new-password'
                                />
                            </div>
                            <div className='method-block'></div>
                        </React.Fragment>
                    )}
                </Formik>
            }
        />
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Conekta);
