import React, { useRef } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setFlyout } from '@Src/Global/Actions';

const Flyout = ({ setFlyout, global: { flyout } }) => {
    let overlayRef = useRef(null);

    const handlOverlayClick = (e) => {
        if(e.target === overlayRef.current){
            setFlyout(false);
        }
    }


    return (
        <div id="flyout" ref={overlayRef} onClick={handlOverlayClick}>
            <div className='content'>
                {flyout}
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setFlyout,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Flyout);
