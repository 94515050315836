import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Framer Motion
import { motion as Motion } from 'framer-motion';

// Actions
import { setModal } from '@Src/Global/Actions';

// Components
import Banners from '@Src/About/Banners';
import VMV from '@Src/About/VMV';
import Subanner from '@Src/About/Subanner';
import Punch from '@Src/About/Punch';

const About = () => {
    return (
        <Motion.Div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            id={'about'}
        >
            <Banners/>
            <VMV/>
            <Subanner/>
            <Punch/>
        </Motion.Div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(About);
