import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Toast
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

const Unauth = () => {
    useEffect(() => {
        toast.error('Es necesario iniciar sesión para acceder a esta sección');
    }, []);
    return <Navigate to={'/'}/>;
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Unauth);
