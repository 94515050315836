import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Actions =>
import { saveAddress, getAddresses } from '@Src/Addresses/Actions';
import { setModal } from '@Src/Global/Actions';

// Toastify:
import { toast } from "react-toastify";

// Components:
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

// Router
import { useNavigate } from 'react-router-dom';

// Icons

const Login = ({ saveAddress, getAddresses, setModal, address = false }) => {
    const navigate = useNavigate();

    // Form validation schema:
    const YupSchema = Yup.object().shape({
        locality: Yup.string().required(' '),
        street: Yup.string().required(' '),
        exterior: Yup.string().required(' '),
        interior: Yup.string().required(' '),
        area: Yup.string().required(' '),
        cp: Yup.string().required(' '),
        state: Yup.string().required(' '),
        city: Yup.string().required(' '),
    });

    let initialValues = {
        locality: '',
        street  : '',
        exterior: '',
        interior: '',
        area    : '',
        cp      : '',
        state   : '',
        city    : '',
    };

    return (
        <Formik
            initialValues={(address) ? address : initialValues}
            validationSchema={YupSchema}
            onSubmit={(values,  {setSubmitting, setFieldValue}) =>{
                setSubmitting(true);
                saveAddress(values).then((res) => {
                    if(res.type === 'Success'){
                        getAddresses().then(() => {
                            toast.success(res.message);
                            setModal(false);
                        });
                    } else {
                        toast.error(res.message);
                    }
                    setSubmitting(false);
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div className={'addresss-form'}>
                    <h2>Administrar dirección</h2>
                    <Input
                        label        = {'Alias'}
                        placeholder  = {'Alias:'}
                        name         = {'locality'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'Calle'}
                        placeholder  = {'Calle:'}
                        name         = {'street'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'Exterior'}
                        placeholder  = {'Exterior:'}
                        name         = {'exterior'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'Interior'}
                        placeholder  = {'Interior:'}
                        name         = {'interior'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'Colonia'}
                        placeholder  = {'Colonia:'}
                        name         = {'area'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'C.P.'}
                        placeholder  = {'C.P.'}
                        name         = {'cp'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />

                    <Input
                        label        = {'Estado'}
                        placeholder  = {'Estado'}
                        name         = {'state'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <Input
                        label        = {'Ciudad'}
                        placeholder  = {'Ciudad'}
                        name         = {'city'}
                        values       = {values}
                        onChange     = {handleChange}
                        onFocus      = {handleBlur}
                        errors       = {errors}
                        touched      = {touched}
                        autoComplete = 'new-password'
                    />
                    <div className='btn-holder'>
                        { isSubmitting ? <Loading/> : <Button variant={'primary'} onClick={handleSubmit}>Guardar dirección</Button> }
                    </div>
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
        saveAddress,
        getAddresses,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Login);
