import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, storeById, setModal } from '@Src/Global/Actions';
import { setDelivery } from '@Src/Cart/Actions';

// Components =>
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import Location from '@Shared/Location';

// Icons
import { GoLocation } from 'react-icons/go';

const Stores = ({ stores: { items }, cart: { delivery }, global: { store }, storeById, setDelivery, setModal }) => {
    return (
        <div id='stores'>
            <GoLocation size={32} color={'#C4C4C4'}/>
            <p>Selecciona la tienda de tu preferencia</p>
            <h2>{storeById(store)}</h2>
            {items ?
                Object.keys(items).map((sid) => {
                    let s   = items[sid];
                    s.id = sid;
                    let variant = (delivery?.method === 'store' && delivery?.location?.id === sid) ? 'secondary' : 'default';
                    return (
                        <Button key={UUID()} variant={variant} onClick={() => setDelivery('store', s) }>{s.name}</Button>
                    )
                })
            : <Loading/> }

            <Button variant={'link'} onClick={() => setModal(<Location/>)}>Cambiar de zona</Button>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        storeById,
        setDelivery,
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Stores);
