import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Router
import { Route, Routes, useLocation } from 'react-router-dom';

// Components
import Modal from '@Shared/Modal';
import Panel from '@Shared/Panel';
import WhatsApp from '@Shared/WhatsApp';
import Loading from '@Shared/Loading';
import Home from '@Src/Home/Home';
import About from '@Src/About/About';
import Business from '@Src/Business/Business';
import Faq from '@Src/Faq/Faq';
import Branches from '@Src/Stores/Branches';
import Catalog from '@Src/Catalog/Catalog';
import Detail from '@Src/Product/Detail';
import Profile from '@Src/User/Profile';
import Orders from '@Src/Orders/Orders';
import Addresses from '@Src/Addresses/Index';
import Cart from '@Src/Cart/Cart';
import Register from '@Src/Register/Register';
import RegisterSuccess from '@Src/Register/Success';
import Verify from '@Src/Register/Verify';
import Recover from '@Src/Login/Recover';
import Unauth from '@Shared/Unauth';

// Framer Motion
import { AnimatePresence } from 'framer-motion';

// Actions
import { setPreivousRoute, setHeaderVisibility } from '@Src/Global/Actions';


const Navigator = ({ global: { modal, panel, loading, prevRoute, client }, setPreivousRoute, setHeaderVisibility }) => {
    const location = useLocation();

    useEffect(() => {
        /*
            Comparamos el primer segmento de la ruta anterior vs el 1er segmento de la ruta actual
            Si son iguales, no es necesario ejecutar scroll top
        */
        //if(prevRoute && prevRoute.split('/')[1].search(location.pathname.split('/')[1]) === -1 || (location.pathname.search('/catalog') !== -1)){
            setTimeout(() => {
                let main = document.getElementsByTagName('main')[0];
                main.scrollTo({top: 0, behavior: 'smooth'});
            }, 200);
        //}
        return () => {
            setHeaderVisibility(true);
            setPreivousRoute(location.pathname); // Store route before change, so we can have a reference of previous route
        }
    }, [location.search, location.pathname]);

    return (
        <React.Fragment>
            <AnimatePresence>
                <Routes location={location} key={location.pathname}>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route path="/business">
                        <Route index element={<Business />} />
                        <Route path=":section" element={<Business />} />
                    </Route>
                    <Route exact path="/faq" element={<Faq/>}/>
                    <Route path="/branches">
                        <Route index element={<Branches />} />
                        <Route path=":city" element={<Branches />} />
                    </Route>
                    <Route exact path="/catalog" element={<Catalog/>} scrollMode={"pathname"}/>
                    <Route exact path="/product/:pid" element={<Detail/>}/>
                    <Route exact path="/profile" element={(client) ? <Profile/> : <Unauth/>}/>
                    <Route exact path="/orders" element={(client) ? <Orders/> : <Unauth/>}/>
                    <Route exact path="/addresses" element={(client) ? <Addresses/> : <Unauth/>}/>
                    <Route exact path="/cart/*" element={<Cart/>}/>
                    <Route exact path="/register" element={<Register/>}/>
                    <Route exact path="/register/success" element={<RegisterSuccess/>}/>
                    <Route exact path="/verify/:token" element={<Verify/>}/>
                    <Route exact path="/recover/:token" element={<Recover/>}/>
                </Routes>
            </AnimatePresence>
            <WhatsApp/>
            {modal ? <Modal/> : null }
            {panel ? <Panel/> : null }
            {loading ? <Loading fullsize={true}/> : null }
        </React.Fragment>

    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setPreivousRoute,
        setHeaderVisibility,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Navigator);
