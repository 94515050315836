import { API } from '@Src/Global/Actions';

export function investCalculator(inv = false){
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var state = getState();
            let calculator = state.calculator;
            if(inv === false)
            inv = calculator.investment;

            if(inv === ""){
                dispatch({
                    type 	: "MERGE-CALCULATOR-STATES",
                    payload	: {
                        "investment" : "",
                        "percent" : 0,
                        "earnings" : 0,
                    }
                });
            }

            if(inv !== false && inv !== ""){
                inv = parseFloat(inv);

                new Promise((resolve) => {
                    if(calculator.discounts){
                        resolve(calculator.discounts);
                    } else {
                        API('/discounts/amount.json?store=1', 'GET').then((res) => {
                            resolve(res.ranges);
                        });
                    }
                }).then((discounts) => {
                    var percent;
                    discounts.forEach((r) => {
                        if(inv >= r.minimal_amount){
                            percent = r.steel_discount;
                        }
                    });

                    var earnings = ((inv * 100) / (100 - percent));
                    var tostr = earnings.toString().split(".");
                    tostr = tostr[0];
                    earnings = parseInt(tostr);

                    let obj = {
                        "discounts" : discounts,
                        "percent" : percent,
                        "earnings" : earnings,
                        "investment" : inv
                    };

                    dispatch({
                        type 	: "MERGE-CALCULATOR-STATES",
                        payload	: obj
                    });

                    resolve(obj);
                });
            }
        });
    }
}