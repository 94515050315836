import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

// Banner
import Banner from '@Assets/about/subanner/subanner.jpg';
import BannerMobile from '@Assets/about/subanner/subanner-mobile.jpg';

const Subanner = ({ global: { wsize } }) => {
    const [banner, setBanner] = useState(Banner);

    useEffect(() => {
        if(wsize.w < 1080){
            setBanner(BannerMobile);
        } else {
            setBanner(BannerMobile);
        }
    }, [wsize])

    return (
        <div className='about-subanner'>
            <div className='texts'>
                <h2>Nuestro crecimiento</h2>
                <h1>Con mirada al futuro</h1>
                <p>Desde hace más de 10 años en Elements fusionamos creatividad, técnica y pasión para transformar metales, y así darle vida a colecciones únicas de joyería, para que cuando elijas alguna pieza, ésta exprese lo que necesitas expresar, como un artista lo hace en cada obra.</p>
                <h3>¡Tú eres parte de nuestra historia!</h3>
            </div>
            <img src={banner}/>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Subanner);
