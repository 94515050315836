import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { storeById, setModal } from '@Src/Global/Actions';

// Components =>
import Location from '@Shared/Location';

// Icons
import { IoLocationOutline } from 'react-icons/io5';

const InStore = ({ global: { store }, storeById, setModal }) => {
    return (
        <button type="button" onClick={() => setModal(<Location/>) } className={'in-store'}>
            <IoLocationOutline size={22} color={'#505050'}/>
            <span>Navegando en <b>Tienda {storeById(store)}</b></span>
        </button>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        storeById,
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(InStore);
