import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions
import { UUID, setModal } from '@Src/Global/Actions';
import { clearParams } from '@Src/Catalog/Actions';

// Swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// Components =>
import Product from '@Src/Product/Product';
import Button from '@Shared/Button';

// Icons
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Related = ({ products, title = 'Tal vez te pueda interesar', global: { wsize }, clearParams, setModal }) => {
    const navigate = useNavigate();
    const [itemWidth, setItemWidth] = useState('18vw');

    useEffect(() => {
        if(wsize.w > 1380){
            setItemWidth('18vw');
        }
        if(wsize.w < 1180 && wsize.w > 1090){
            setItemWidth('23vw');
        }
        if(wsize.w < 1090 && wsize.w > 700){
            setItemWidth('31.2vw');
        }
        if(wsize.w < 700 && wsize.w > 320){
            setItemWidth('48vw');
        }
    }, [wsize]);


    const handleNavigate = () => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            });
            setModal(false);
        });
    }

    return (
        <div className='related-products'>
            <div className='title-and-controllers'>
                <h2>{title}</h2>
                <div className='controllers'>
                    <button id={'swiper-back'}>
                        <HiOutlineChevronLeft color={'#C4C4C4'} size={32}/>
                    </button>
                    <button id={'swiper-next'}>
                        <HiOutlineChevronRight color={'#C4C4C4'} size={32}/>
                    </button>
                </div>
            </div>
            <Swiper
                className='product-related-swiper'
                spaceBetween={0}
                slidesPerView={'auto'}
                modules={[Navigation]}
                navigation={{ nextEl: "#swiper-next", prevEl: "#swiper-back" }}
            >
                {Object.keys(products).map((key, idx) => {
                    let item  = products[key];
                    return (
                        <SwiperSlide className={'slide-item'} key={UUID()} style={{ width: itemWidth }}>
                            <Product product={item}/>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <Button onClick={handleNavigate} variant={'secondary'}>Ver todos los productos</Button>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        clearParams,
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Related);
