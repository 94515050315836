import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components
import Button from '@Shared/Button';

// Icons
import { BsCheckCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const Complete = ({ }) => {
    const navigate = useNavigate();
    return (
        <div className='cart-compeleted'>
            <BsCheckCircle color={'#34C240'} size={40}/>
            <h1>¡Tu pedido está listo!</h1>
            <p>Te hemos enviado los detalles de tu pedido a tu email<br/>Gracias por comprar en Elements of Steel.</p>
            <Button onClick={() => navigate('/orders') } variant={'secondary'}>Ir a mis pedidos</Button>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Complete);
