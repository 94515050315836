import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { getProduct } from '@Src/Product/Actions';

// Components =>
import Loading from '@Shared/Loading';
import Gallery from '@Src/Product/Gallery';
import Variants from '@Src/Product/Variants';
import Increaser from '@Src/Product/Increaser';
import Texts from '@Src/Product/Texts';
import Prices from '@Src/Product/Prices';
import Opinions from '@Src/Opinions/Opinions';
import Related from '@Src/Product/Related';

// Routing:
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const Detail = ({ getProduct, pid = false, data = false, global: { modal, client }, catalog: { allow_brand } }) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    // Obtenemos información básica del producto para mostrar inmediatamente:
    let initProduct = (location?.state?.product) ? {'item' : location.state.product} : (data) ? {'item' : data } : false;
    const [product, setProduct] = useState(initProduct);  //useState(params.product || false);

    useEffect(() => {
        handleGetProduct();
    }, []);

    const handleGetProduct = () => {
        return new Promise((resolve) => {
            if(!modal){
                setTimeout(() => {
                    let main = document.getElementsByTagName('main')[0];
                    main.scrollTo({top: 0, behavior: 'smooth'});
                }, 200);
            }

            let productID = (pid) ? pid : params.pid;
            getProduct(productID).then((p) => {
                // Verify if user should be able to see it:
                if(p.item.brand && (!client || !allow_brand )){
                    navigate('/catalog');
                    toast.error('Lo sentimos, parece que el producto no existe o no cuentas con acceso.');
                } else {
                    let merged = {...product, ...p};
                    setProduct(merged);
                    resolve(p);
                }
            });
        });
    }

    // For some reason we need to re-create this callBack inside popup
    const callBack = (replacement) => {
        let clon = JSON.parse(JSON.stringify(product));
        // Clean rid from object
        if(replacement.rid){
            replacement.id = replacement.rid;
            delete replacement.rid;
        }
        clon.item = Object.assign({}, clon.item,  replacement);
        setProduct(clon);
    }

    return (
        <div id={'detail'}>
            {product && product.item ?
                <React.Fragment>
                    <div className='content'>
                        <div className='holder-gallery'>
                            <Gallery product={product.item} large={true}/>
                        </div>
                        <div className='holder-info'>
                            <Texts product={product.item}/>
                            <Prices product={product.item}/>
                            <Variants product={product.item} callBack={callBack}/>
                            <div className='increaser-and-button'>
                                <Increaser product={product.item}/>
                            </div>
                        </div>

                        {product.item && product.item.characteristics ?
                            <div className='holder-characteristics'>
                                <h2>Detalle de producto</h2>
                                <div className='table'>
                                    {Object.keys(product.item.characteristics).map((k) => {
                                        let c = product.item.characteristics[k];
                                        return(
                                            <div className='item' key={UUID()}>
                                                <label>{c.label}</label>
                                                <span>{c.value}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        : null }
                    </div>
                    <Opinions product={product} callBack={handleGetProduct}/>
                    {product.related ? <Related products={product.related}/> : <Loading size={16}/>}
                </React.Fragment>
            : <Loading/> }
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getProduct,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Detail);
