import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setFlyout, setPanel } from '@Src/Global/Actions';
import { clearParams, getCatalog, setParams } from '@Src/Catalog/Actions';

// Components =>
import Keyword from '@Src/Search/Keyword';
import Button from '@Shared/Button';

// Icons
import { BiMessageAltError } from 'react-icons/bi';

// Routing:
import { createSearchParams, useNavigate } from 'react-router-dom';

const Empty = ({ clearParams, getCatalog, setParams, catalog: { perpage } }) => {
    const navigate = useNavigate();

    const handleBackToCatalog = () => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`,
            });
        });
    }

    const handleSearch = () => {
        setParams({
            start: 0,
            end: perpage
        }).then((nparams) => {
            setFlyout(false);
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams(nparams)}`
            });
            setPanel(false);
        });
    }

    return (
        <div className='catalog-empty'>
            <BiMessageAltError size={80} color={'#CCC'}/>
            <h2>No fue posible encontrar resultados con tus parámetros de búsqueda.</h2>
            <Keyword/>
            <div className='buttons-holder'>
                <Button variant={'primary'} onClick={handleSearch}>Buscar de nuevo</Button>
                <Button variant={'link'} onClick={handleBackToCatalog}>Volver al catálogo</Button>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        clearParams,
        getCatalog,
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Empty);
