import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Switch
import Switch from "react-switch";

// Toastify:
import { toast } from "react-toastify";

// Actions =>
import { setPanel } from '@Src/Global/Actions';
import { setParams } from '@Src/Catalog/Actions';

// Routing
import { createSearchParams, useNavigate } from 'react-router-dom';

const Linex = ({ catalog: { allow_brand, web_catalog, params }, global: { client }, setParams, setPanel }) => {
    const navigate = useNavigate();

    const handleSwitch = () => {
        let objParams = {'brand': !params.brand, start: 0, end: 50}; // Defining start and end after a change in Line X
        if(web_catalog){
            objParams['web_catalog'] = web_catalog;
        }
        setParams(objParams).then((nparams) => {
            toast.success(<React.Fragment><p>Se han <b style={{ fontWeight: 500 }}>{(!nparams.brand) ? 'desactivado' : 'activado'}</b> productos de línea.</p></React.Fragment>);
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams(nparams)}`
            });
            setPanel(false);
        });
    }

    return (
        allow_brand && client ?
            <label>
                <Switch
                    onChange={handleSwitch}
                    checked={(!params.brand) ? false : (params.brand === 'false') ? false : true}
                    offColor={'#F9FCFE'}
                    onColor={'#F9FCFE'}
                    offHandleColor={'#898989'}
                    onHandleColor={'#34C240'}
                    className={'linex-switch'}
                />
                <span>Activar Línea-X</span>
            </label>
        : null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setParams,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Linex);
