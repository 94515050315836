import React from 'react';

// Routing
import { Link } from 'react-router-dom';

// Icons
import { IoStorefrontOutline } from 'react-icons/io5';
import { FaBoxOpen } from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';
import { BsCreditCard2Back } from 'react-icons/bs';

export const faqs = [
    {
        icon: <IoStorefrontOutline color={'#1289C5'} size={20}/>,
        title: 'Productos y tiendas',
        questions: [
            {
                q: '¿Cómo puedo ver más productos?',
                a: <p>Nuestra colección se actualiza cada mes con nuevos modelos, pero si no quieres esperar, regístrate y te contactaremos para darte más opciones.<br/><br/>Te toma 1 minuto, <Link to="/register">regístrate aquí</Link>.</p>,
            },
            {
                q: '¿Tienen tiendas físicas?',
                a: <p>Si, <Link to="/branches">consulta aquí nuestras sucursales</Link>,Te esperamos! </p>,
            },
        ],
    },


    {
        icon: <FaBoxOpen color={'#1289C5'} size={20}/>,
        title: 'Pedidos y devoluciones',
        questions: [
            {
                q: '¿Cómo entregan los pedidos?',
                a: <p>Hacemos entregas a través de DHL o Uber Flash en CDMX, en la compra de cualquier cantidad. Y no pagas el envío en una compra mínima de $3,000.00 MXN* *Consulta condiciones con tu asesor</p>,
            },
            {
                q: '¿Puedo comprar en línea y me pueden entregar en una tienda física?',
                a: <p>¡Claro! <Link to={'/branches'}>Consulta aquí la sucursal más cercana</Link>, una vez concretado tu pedido te contactaremos para pactar el día de entrega.</p>,
            },
        ],
    },
    {
        icon: <BiUser color={'#1289C5'} size={20}/>,
        title: 'Mi cuenta y socios',
        questions: [
            {
                q: ' ¿Cuál es el monto mínimo para inverir y vender?',
                a: <p>No tenemos un monto mínimo, pero tu historial y tu acumulado de compras incrementa tu descuento, de esa forma podrás también incrementar tus utilidades! <Link to={"/about"}>Consulta nuestra calculadora deutilidades aquí</Link></p>,
            },
            {
                q: ' ¿Cómo me convierto en socio?',
                a: <p>Para ser socio debes recibir una invitación. Tú historial de compras te respalda, después de 3 meses como cliente podrías convertirte en socio.</p>,
            },
        ],
    },
    {
        icon: <BsCreditCard2Back color={'#1289C5'} size={20}/>,
        title: 'Compras y pagos',
        questions: [
            {
                q: '¿Cómo pago mi pedido?',
                a: <p>Puedes pagar en efectivo a contra-entrega en nuestras tiendas físicas o con una transferencia</p>,
            },
            {
                q: '¿Tienen catálogos para vender?',
                a: <p>Sí, vende a través de nuestro portal exclusivo de socios e incrementa tus ventas.</p>,
            },
            {
                q: '¿Cómo puedo comprar?',
                a: <p>Una vez realizado tu pedido en linea te contactaremos por los medios que nos proporciones para concretar tu pago y darte los detalles de tu entrega.</p>,
            },
            {
                q: '¿Qué opciones de compra tienen para mayoristas?',
                a: <p><Link to="/business">Conoce el detalle de nuestros descuentos para mayoristas aquí</Link>. Además al convertirte en socio podrás tener acceso a beneficios como créditos y mercancía en consignación, así como catálogos exclusivos.  </p>,
            },
        ],
    },
];
