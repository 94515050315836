import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { getCatalog, clearParams, setParams } from '@Src/Catalog/Actions';

// Components =>
import Loading from '@Shared/Loading';

// Swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// Icons
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Categories = ({ catalog: { categories }, global: { wsize }, getCatalog, clearParams, setParams }) => {
    const navigate = useNavigate();
    const [slidesPerView, setSlidesPerView] = useState(9);

    useEffect(() => {
        if(!categories){
            getCatalog();
        }
    }, []);

    useEffect(() => {
        if(wsize.w > 1280){
            setSlidesPerView(9)
        }
        if(wsize.w < 1280 && wsize.w > 1080){
            setSlidesPerView(7)
        }
        if(wsize.w < 1080 && wsize.w > 820){
            setSlidesPerView(7)
        }
        if(wsize.w < 820 && wsize.w > 640){
            setSlidesPerView(5)
        }
        if(wsize.w < 640 && wsize.w > 500){
            setSlidesPerView(4)
        }
        if(wsize.w < 500 && wsize.w > 320){
            setSlidesPerView(3)
        }
    }, [wsize]);

    const handleCategorySelection = (c) => {
        clearParams().then(() => {
            setParams({
                'category': c,
            }).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`
                });
            });
        });
    }

    return (
        <div className='home-categories'>
            <h2>Categorías</h2>
            <div className='controllers'>
                <button id={'home-category-back'}>
                    <HiOutlineChevronLeft color={'#C4C4C4'} size={32}/>
                </button>
                <button id={'home-category-next'}>
                    <HiOutlineChevronRight color={'#C4C4C4'} size={32}/>
                </button>
            </div>
            {categories ?
                <Swiper
                    className='slider'
                    spaceBetween={0}
                    slidesPerView={slidesPerView}
                    modules={[Navigation]}
                    centerInsufficientSlides={true}
                    navigation={{ nextEl: "#home-category-next", prevEl: "#home-category-back" }}
                >
                    {categories.map((c) => {
                        let imgSrc = (c.image_url.search('NO_IMAGE.jpg') !== -1) ? c.image_url : c.image_url+'/0_medium.webp';
                        return (
                            <SwiperSlide className={'item'} key={UUID()} onClick={() => handleCategorySelection(c.ext_name) }>
                                <div className='category-item' style={{ backgroundImage: 'url('+imgSrc+')'}}></div>
                                <h2>{c.ext_name}</h2>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            : <Loading/>}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getCatalog,
        clearParams,
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Categories);
