import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Assets
import EoS from '@Assets/eos.png';

// Icons
import { AiOutlineWhatsApp } from 'react-icons/ai';

const Seller = ({ global: { phones } }) => {
    return (
        <div className='card seller-card'>
            <div className='img-holder' style={{ backgroundImage: `url(${EoS})`}}></div>
            <div className='info-holder'>
                <div className='titles'>
                    <h2>Elements of Steel</h2>
                    {/*<a>giovanna@elementsofsteel.com</a>*/}
                </div>
                <a className='whatsapp' href={`https://wa.me/${phones.whatsapp.clean}`} target={'_blank'} rel="noreferrer"><AiOutlineWhatsApp color={'#34C240'} size={18}/> {phones.whatsapp.format}</a>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Seller);
