import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>
import Banner from '@Src/Business/Banner';
import Why from '@Src/Business/Why';
import Calculator from '@Src/Business/Calculator';
import Start from '@Src/Business/Start';
import Reasons from '@Src/Business/Reasons';
import Subanner from '@Src/Business/Subanner';

// Routing
import { useParams } from 'react-router-dom';

const Business = ({ }) => {
    const params = useParams();

    useEffect(() => {
        setTimeout(() => {
            let top = 0;
            if(params.section){
                top = document.getElementsByClassName('business-'+params.section)[0].getBoundingClientRect().top;
                document.getElementsByTagName('main')[0].scrollTo({top: (top - 50), behavior: 'smooth'});
            }
        }, 100);
    }, [])
    return (
        <div id="business">
            <Banner/>
            <Why/>
            <Calculator/>
            <Start/>
            <Reasons/>
            <Subanner/>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Business);
