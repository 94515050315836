import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal } from '@Src/Global/Actions';

// Components =>
import Hearth from '@Src/Product/Hearth';

// Routing
import { useNavigate } from 'react-router-dom';

const Texts = ({ product, setModal }) => {
    const navigate = useNavigate();
    let pid = (product.rid) ? product.rid : product.id;

    const handleNavigate = () => {
        setModal(false);
        navigate(`/product/${pid}`);
    }

    return (
        <div className='product-texts'>
            <span>SKU: {product.code || product.sale_code}</span>
            <Hearth/>
            <h2>{product.description}</h2>
            <h3>{product.material}</h3>
            <a onClick={handleNavigate}>Ver detalle de producto</a>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Texts);
