import update from 'immutability-helper';

export const global = (state = {
    'route'    : 'Home',
    'loading'  : false,
    'redirect' : false, // {'path' : '/', params: {...}}
    'wsize'    : false,
    'modal'    : false,
    'flyout'   : false,
    'panel'    : false,
    'guest'    : 2,
    'store'    : (localStorage.getItem('store')) ? parseInt(localStorage.getItem('store')) : 1,       // 1 E-Store CDMX | 6 Tienda Fábrica CDMX | 9 Tienda Fábrica GDL | 10 E-Store GDL
    'region'   : 'CDMX',                                                                           // CDMX | GDL
    'client'   : (localStorage.getItem('client')) ? JSON.parse(localStorage.getItem('client')): false,
    'discounts': (localStorage.getItem('discounts')) ? JSON.parse(localStorage.getItem('discounts')) : { 'steel' : 10, 'lamin' : 10, 'type' : 'Table' },
    'materialgroups': {
        'steel' : [
            'ACERO',
            'CERAMICA',
            'M.ACERO',
            'TUNGSTENO',
            'BISUTERIA',
            'OTRO',
        ],
        'lamin' : [
            'LAMINADO',
            'M.LAMINADO',
            'RODIO',
        ],
    },
    'phones' : {
        'tel' : {
            format: '55 5512-2325',
            clean: '5555122325',
        },
        'whatsapp' : {
            clean  : '5215584053109',
            format  : '55 8405 3109',
        }
    },
    'hideHeader'  : true, // Determina si el header debe ocultarse, esto sucede sólo en mobile y en el catálogo (también hace lo mismo con los filtros)
}, action) => {
    switch (action.type) {
		case 'MERGE-GLOBAL-STATES':
			state = update(state, {$merge: action.payload});
		break;

        default:
            return state;
    }

    return state;
};
