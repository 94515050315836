import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Icons
import { BsChevronLeft } from 'react-icons/bs';
import { MdOutlineMobileFriendly } from 'react-icons/md';

// Actions
import { setPanel } from '@Src/Global/Actions';

// Components:
import PanelMenu from '@Src/Panels/Menu';

// Assets
import iOS from '@Assets/pwa/ios.jpeg';

const Pwa = ({ setPanel }) => {
    return (
        <div id={'panel-pwa'}>
            <h1 onClick={() => setPanel(<PanelMenu/>) }><MdOutlineMobileFriendly size={26}/> Instrucciones de instalación <BsChevronLeft size={18}/></h1>
            <div className='instructions-holder'>
                <img src={iOS}/>
            </div>
            {/*
            <div className='video-holder'>
                <video controls={false} autoPlay={true} width={'75%'} playsInline={true} loop={true}>
                    <source src={'/pwa-install-demo.mp4'} type="video/mp4"/>
                </video>
            </div>
            */}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Pwa);
