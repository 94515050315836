import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Components =>
import Template from '@Src/Cart/Template';
import List from '@Src/Cart/List';
import Summary from '@Src/Cart/Summary';
import Motivation from '@Src/Cart/Motivation';
import Promo from '@Src/Cart/Promo';

const Step0 = ({ cart: { basket }}) => {
    return (
        <Template
            title={'Tu Carrito'}
            step={0}
            left={[
                <List key={UUID()}/>
            ]}
            right={[
                <Summary key={UUID()}/>,
                basket.discount_type !== 'Descuento por promoción' ? null : <Promo key={UUID()}/>,
                <Motivation key={UUID()}/>
            ]}
        />
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Step0);
