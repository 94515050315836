import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';

// Swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// Icons
import { BsChevronCompactLeft } from 'react-icons/bs';
import { BsChevronCompactRight } from 'react-icons/bs'

const Promos = ({ variant = '' }) => {
    return (
        <div id={'promos-swiper'} className={variant || ''}>
            <button type="button" className='controls' id={'swiper-back'}><BsChevronCompactLeft  size={40} color={'#898989'}/></button>
            <Swiper
                className='slider'
                spaceBetween={30}
                slidesPerView={'auto'}
                autoplay={true}
                modules={[Navigation]}
                pagination={true}
                navigation={{ nextEl: "#swiper-next", prevEl: "#swiper-back" }}
            >
                {['','',''].map(() => {
                    return (
                        <SwiperSlide className={'slide-item'} key={UUID()}>
                            <div className='card'>
                                <div className='img-holder'></div>
                                <div className='texts-holder'>
                                    <h2>Esta navidad regala algo especial</h2>
                                        <big>25%</big>
                                        <span>en todos nuestros anillos</span>
                                </div>
                                <button>Comprar ahora</button>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <button type="button" className='controls' id={'swiper-next'}><BsChevronCompactRight size={40} color={'#898989'}/></button>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Promos);
