import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setPanel } from '@Src/Global/Actions';

// Components =>
import Card from '@Src/User/Card';
import Promo from '@Src/Promo/Promo';
import Button from '@Shared/Button';

// Routing:
import { useNavigate } from 'react-router-dom';

// Icons:
import { FaUser } from 'react-icons/fa';
import { FaBoxOpen } from 'react-icons/fa';
import { ImHome3 } from 'react-icons/im';
import { BsChevronRight } from 'react-icons/bs';

const User = ({ setPanel, global:{ phones } }) => {
    const navigate = useNavigate();

    const handleNavigation = (route) => {
        navigate(route);
        setPanel(false);
    }

    return (
        <div id="user-panel">
            <h1 onClick={() => setPanel(false) }>Mi Cuenta</h1>
            <Card/>
            <hr/>
            <nav>
                <a onClick={ () => handleNavigation('/profile') }>
                    <FaUser color={'#898989'} size={22}/>
                    <span>Mi perfil</span>
                    <BsChevronRight/>
                </a>
                <a onClick={ () => handleNavigation('/orders') }>
                    <FaBoxOpen color={'#898989'} size={22}/>
                    <span>Mis pedidos</span>
                    <BsChevronRight/>
                </a>
                <a onClick={ () => handleNavigation('/addresses') }>
                    <ImHome3 color={'#898989'} size={22}/>
                    <span>Mis direcciones</span>
                    <BsChevronRight/>
                </a>
            </nav>
            {/*<Promos variant={'compact'}/>*/}
            <a target={'_blank'} href={`https://wa.me/${phones.whatsapp.clean}`} className='btn link'>
                ¿Necesitas ayuda?
            </a>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(User);
