import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>
import PromoForm from '@Src/Promo/Promo';

// Icons
import { BsChevronDown } from 'react-icons/bs';

const Promo = ({ }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    }

    const promoStyles = (open) ? 'home-promo open' : 'home-promo';

    return (
        <div className={promoStyles}>
            <div className='band' onClick={handleOpen}>
                <div className='text'>¿Tienes un código de promoción? <BsChevronDown/></div>
            </div>
            <div className='content'>
                <PromoForm icon={true}/>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Promo);
