import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

// Icons
import { IoLocationOutline } from 'react-icons/io5';

// Actions
import { storeById, changeStore, UUID, setModal } from '@Src/Global/Actions';
import { getStores } from '@Src/Stores/Actions';

// Routing
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';

// Toastify:
import { toast } from "react-toastify";

const Location = ({ global: { store }, storeById, changeStore, setModal, getStores }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const btnStores = [
        {
            'location' : 'CDMX',
            'id': 1,
        },
        {
            'location' : 'GDL',
            'id': 10,
        },
    ];

    const handleStoreChange = (id) => {
        setLoading(true);
        changeStore(id).then(() => {
            getStores().then(() => {
                setModal(false);
                if(location.pathname.search('/catalog') !== -1){
                    navigate({
                        pathname: '/catalog',
                        search: `?${createSearchParams({store: id})}`,
                    });
                }
                toast.info(<p>Ahora navegas en <b style={{ fontWeight: 700 }}>Tienda {storeById(id)}</b></p>);
            });
        });
    }


    return (
        <div className={'modal-location'}>
            <IoLocationOutline size={40}/>
            <h2>Selecciona tu ubicación</h2>
            { !loading ?
                <React.Fragment>
                    {btnStores.map((s) => {
                        let btnVariant = (s.id === store) ? 'secondary' : 'default';
                        return (
                            <Button key={UUID()} onClick={() => handleStoreChange(s.id) } variant={btnVariant}>{s.location}</Button>
                        )
                    })}
                    <small>Tu ubicación actual es <b>{storeById(store)}</b></small>
                </React.Fragment>
            : <Loading/> }
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        storeById,
        changeStore,
        setModal,
        getStores,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Location);
