import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, setModal, setPanel } from '@Src/Global/Actions';
import { getAddresses } from '@Src/Addresses/Actions';

// Components =>
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import Address from '@Src/Addresses/Address';
import Form from '@Src/Addresses/Form';
import PanelUser from '@Src/Panels/User';

// Icons
import { BsPlusCircle, BsChevronLeft } from 'react-icons/bs';


const Addresses = ({ title = 'Mis direcciones', selectable=false, addresses: { items }, global: { wsize }, getAddresses, setModal, setPanel } ) => {

    useEffect(() => {
        if(!items){
            getAddresses();
        }
    }, []);

    const handleBack = () => {
        if(wsize.w < 1080){
            setPanel(<PanelUser/>);
        } else {
            return false;
        }
    }

    return (
        <div id="addresses">
            <div className='title-and-button'>
                <h1 onClick={handleBack}>{title} <BsChevronLeft size={18}/></h1>
                <Button variant={'primary'} onClick={() => setModal(<Form/>) }><BsPlusCircle size={18}/> Añadir dirección</Button>
            </div>
            <div className='addresses-holder'>
                { items ?
                    items.map((address) => {
                        return <Address selectable={selectable} key={UUID()} address={address}/>
                    })
                : <Loading/> }
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getAddresses,
        setModal,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Addresses);
