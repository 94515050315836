import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setModal } from '@Src/Global/Actions';

// Components =>
import Button from '@Shared/Button'
import Login from '@Src/Login/Login';
import Guest from '@Src/Login/Guest';

// Routing
import { useNavigate } from 'react-router-dom';

const Auth = ({ setModal }) => {
    const navigate = useNavigate();

    return (
        <div className='cart-step-auth'>
            <div className='content'>
                <div className='header'>
                    <h1>Iniciar tu compra</h1>
                    <Button variant={'link'} onClick={() => navigate(-1) }>Regresar</Button>
                </div>
                <div className='login-holder'>
                    <Login/>
                </div>
                <Button onClick={() => setModal(<Guest/>)} variant={'link'}>Continuar como invitado</Button>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setModal,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Auth);
