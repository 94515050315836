import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Range
import { Range, getTrackBackground } from 'react-range';

const Prices = () => {
    const STEP = 10;
    const MIN = 0;
    const MAX = 1000;
    const [values, setValues] = React.useState([200, 800]);
    return (
        <div className='search-prices'>
            <h2>Rango de precios</h2>
            <div className='range-holder'>
                <Range
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => setValues(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            className={'range-slider-rail'}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '5px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values,
                                        colors: ['#ccc', '#548BF4', '#ccc'],
                                        min: MIN,
                                        max: MAX,
                                    }),
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ index, props, isDragged }) => (
                        <div {...props} className={'slider-thumb'}>
                            <div className={'slider-thumb-label'}>
                                $ {values[index].toFixed(0)}
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Prices);
