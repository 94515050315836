import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

// Icons
import { IoCheckmarkSharp } from "react-icons/io5";

const Checkbox = ({ ...props }) => {
    const [checked, setChecked] = useState(props?.checked || false);
    const checkStyle = (checked) ? 'custom-checkbox checked' : 'custom-checkbox';

    const handleOnChange = () => {
        setChecked(!checked);
        if(props.callBack){
            props.callBack(props);
        }
    }

    return (
        <React.Fragment>
            <label className={checkStyle} onClick={handleOnChange}>
                <div className='chkbox'>
                    <IoCheckmarkSharp size={21} color={'#FFFFFF'}/>
                </div>
                <span>{props.text}</span>
            </label>
            <input type="checkbox" name={props?.name} value={props.text} style={{ display: 'none' }} onChange={handleOnChange} checked={checked}/>
        </React.Fragment>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Checkbox);
