import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID, storeById } from '@Src/Global/Actions';

// Components =>
import Button from '@Shared/Button';
import Item from '@Src/Cart/Item';

// Iconst
import { IoStorefrontOutline } from 'react-icons/io5';

// Routing
import { useNavigate } from 'react-router-dom';

const Minicart = ({ cart: { basket }, global: { store }, storeById }) => {
    const  [minicart, setMinicart] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(parseInt(basket.total) > 0){
            setMinicart(basket)
        } else {
            if(localStorage.getItem('lastBasket')){
                setMinicart(JSON.parse(localStorage.getItem('lastBasket')))
            } else {
                setMinicart(false)
            }
        }
    }, []);

    return (
        minicart ?
            <div className='cart-minicart'>
                <h2>
                    Tu carrito
                    <Button onClick={() => navigate('/cart') } variant={'link'}>Editar</Button>
                </h2>
                <h3>
                    <IoStorefrontOutline color={'#0086D0'}/>
                    Productos de <b>Tienda {storeById(store)}</b>
                </h3>
                <div className='scroll-area'>
                    {minicart.items.map((product) => {
                        return <Item product={product} key={UUID()}/>
                    })}
                </div>
            </div>
        : null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        storeById,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Minicart);
