import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { handleExpandPanel, setMethod, submitOrder } from '@Src/Cart/Actions';

// Components =>
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import Template from '@Src/Cart/Template';
import Summary from '@Src/Cart/Summary';
import Minicart from '@Src/Cart/Minicart';
import Conekta from '@Src/Cart/Methods/Conekta';
import Cash from '@Src/Cart/Methods/Cash';
import Deposit from '@Src/Cart/Methods/Deposit';

// Routing
import { useNavigate } from 'react-router-dom';

// Toast
import { toast } from 'react-toastify';

const Step2 = ({ handleExpandPanel, cart: { expandedPanel, method }, setMethod, submitOrder }) => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const handleChosen = (c) => {
        setMethod(c);
    }

    const handleNavigate = (path) => {
        if(expandedPanel){
            handleExpandPanel();
        }
        navigate(path);
    }

    const handleSubmit = () => {
        setSubmitting(true);
        submitOrder().then((res) => {
            setSubmitting(false);
            if(!res.success){
                toast.error(res.message);
            } else {
                navigate('/cart/step-4');
                toast.success(res.message);
            }
        });
    }

    return (
        !submitting ?
            <Template
                title={'Elige como pagar tu compra'}
                className={'cart-step-3'}
                step={2}
                left={[
                    <Cash key={UUID()} type={'Cash'} chosen={method} callBack={handleChosen}/>,
                    <Deposit key={UUID()} type={'Deposit'} chosen={method} callBack={handleChosen}/>,
                    //<Conekta  key={UUID()}type={'Conekta'} chosen={method} callBack={handleChosen}/>,
                ]}
                right={[
                    <Minicart key={UUID()}/>,
                    <Summary key={UUID()}/>,
                    <div className='buttons-holder' key={UUID()}>
                        {method ? <Button onClick={handleSubmit} variant={'primary'}>Finalizar</Button> : null }
                        <Button onClick={() => handleNavigate('/cart/step-2')} variant={'link'}>Regresar</Button>
                        <Button onClick={handleExpandPanel} variant={'link'}>Ver resumen</Button>
                    </div>
                ]}
            />
        : <Loading/>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        handleExpandPanel,
        setMethod,
        submitOrder,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Step2);
