import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setPanel, changeStore } from '@Src/Global/Actions';
import { clearParams } from '@Src/Catalog/Actions';

// Components =>
import Linex from '@Shared/Linex';
import InStore from '@Shared/InStore';
import Promo from '@Src/Promo/Promo';

// Panels
import PanelProducts from '@Src/Panels/Products';
import PanelCollections from '@Src/Panels/Collections';
import Pwa from '@Src/Panels/Pwa';

// Icons
import { IoDiamond } from 'react-icons/io5';
import { AiOutlinePercentage } from 'react-icons/ai';
import { GiDiamondTrophy } from 'react-icons/gi';
import { GiReceiveMoney } from 'react-icons/gi';
import { BsChevronRight } from 'react-icons/bs';
import { BsInfoSquare  } from 'react-icons/bs';
import { IoStorefrontOutline } from 'react-icons/io5';
import { MdOutlineMobileFriendly } from 'react-icons/md';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Menu = ({ setPanel, global: { store }, changeStore, clearParams }) => {
    const navigate = useNavigate();
    const switchPanel = (cmp) => {
        setPanel(false);
        setTimeout(() => {
            setPanel(cmp);
        }, 10);
    }

    const handleNavigate = (route) => {
        setPanel(false);
        navigate({
            pathname: route
        })
    }

    const handleOutlet = () => {
        var outletStore; // = (store === 1) ? 6 : 9; // 1 E-Store CDMX | 6 Tienda Fábrica CDMX | 9 Tienda Fábrica GDL | 10 E-Store GDL
        if([6,9].includes(store)){
            // Estamos en outlet, la acción debería sacar al usuario de la tienda Outlet:
            outletStore = (store === 6) ? 1 : 10;
        } else {
            // Estamos en tienda normal, debería resolver a Outlet
            outletStore = (store === 1) ? 6 : 9;
        }
        changeStore(outletStore).then(() => {
            clearParams().then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`
                });
                setPanel(false);
            });
        });
    }

    const handleInstall = () => {
        if(document.deferredPrompt){
            document.deferredPrompt.prompt();
        } else {
            switchPanel(<Pwa/>);
        }
    }

    return (
        <div id="panel-menu">
            <InStore/>
            <Linex/>
            <nav>
                <a onClick={() => handleNavigate('/about') }>
                    <BsInfoSquare size={22}/>
                    <span>Nosotros</span>
                </a>
                <a onClick={() => handleNavigate('/business') }>
                    <GiReceiveMoney size={22}/>
                    <span>Vende con nosotros</span>
                </a>
                <a onClick={() => handleNavigate('/catalog') }>
                    <IoStorefrontOutline size={22}/>
                    <span>Tienda</span>
                </a>
                {/*
                {[1,6].includes(store) ?
                    <a onClick={handleOutlet}>
                        <AiOutlinePercentage size={22}/>
                        <span>{[6,9].includes(store) ? 'Salir de ' : ''}Outlet</span>
                    </a>
                : null }
                */}
                <a onClick={() => switchPanel(<PanelProducts withback={true}/>) }>
                    <IoDiamond size={22}/>
                    <span>Productos</span>
                    <BsChevronRight/>
                </a>
                <a onClick={() => switchPanel(<PanelCollections withback={true}/>) }>
                    <GiDiamondTrophy size={22}/>
                    <span>Colecciones</span>
                    <BsChevronRight/>
                </a>
                <a onClick={handleInstall} className={'pwa-isntall-btn'}>
                    <MdOutlineMobileFriendly size={22}/>
                    <span>Instala nuestra app</span>
                    <BsChevronRight/>
                </a>
            </nav>
            <Promo/>
            {/*<Promos variant={'compact'}/>*/}
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setPanel,
        changeStore,
        clearParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Menu);
