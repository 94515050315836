import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setPanel, setFlyout } from '@Src/Global/Actions';
import { setParams } from '@Src/Catalog/Actions';

// Components =>
import Button from '@Shared/Button';
import Keyword from '@Src/Search/Keyword';
import PanelAdvanced from '@Src/Panels/Advanced';

// Icons
import { AiOutlineSearch } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const PanelSearch = ({ setPanel, setParams, catalog: { perpage }, setFlyout }) => {
    const navigate = useNavigate();

    const switchPanel = (cmp) => {
        setPanel(false);
        setTimeout(() => {
            setPanel(cmp);
        }, 10);
    }

    const handleSearch = () => {
        setParams({
            keyword: document.getElementsByName('keyword')[0].value,
            start: 0,
            end: perpage
        }).then((nparams) => {
            setPanel(false);
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams(nparams)}`
            });
        });
    }

    return (
        <div id="panel-search">
            <h2 onClick={() => setPanel(false) }><AiOutlineSearch size={18} color={'#EB6B2F'}/> Buscar <BsChevronLeft style={{display: 'none'}}/></h2>
            <div className='search-area'>
                <Keyword/>
                <Button variant={'primary'} onClick={handleSearch}>Buscar</Button>
                <Button variant={'tertiary'} onClick={() => switchPanel(<PanelAdvanced/>)}>Búsqueda avanzada</Button>
                <Button variant={'link'} onClick={() => setPanel(false)}>Cancelar</Button>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setPanel,
        setFlyout,
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(PanelSearch);
