import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const Discount = ({ product, global: { materialgroups, discounts }}) => {
    var matDiscount = (materialgroups["steel"].includes(product?.material)) ? 'steel' : 'lamin';
    let percent = (!product.discount) ? 0 : discounts[matDiscount];

    // Esta propiedad sólo existe en los productos de la canasta:
    // Cuando exista debemos evaluar su valor para asigárselo al porcentaje
    if(product.disc_prct !== undefined){
        percent = product.disc_prct;
    }

    return (
        (percent > 0) ? <div className='product-discount'>-{percent}%</div> : null
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Discount);
