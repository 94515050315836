import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
import { setParams } from '@Src/Catalog/Actions';

// Components =>
import Checkbox from '@Shared/Checkbox';
import Loading from '@Shared/Loading';

const Materials = ({ catalog: { materials, params }, setParams }) => {

    const handleCallBack = (m) => {
        let selections = (params.material) ? params.material.split(',') : [];
        if(selections.includes(m)){
            var index = selections.indexOf(m);
            if (index !== -1) {
                selections.splice(index, 1);
            }
        } else {
            selections.push(m);
        }
        setParams({
            material: selections.join(',')
        });
    }

    return (
        <div className='search-materials'>
            <h2>Filtrar por material</h2>
            { materials ?
                materials.map((m) => {
                    let isChecked = (params.material && params.material !== '' && params.material.split(',').includes(m.name)) ? true : false;
                    return <Checkbox callBack={() => handleCallBack(m.name)} name={'material'} checked={isChecked} text={m.name} key={UUID()}/>
                })
            : <Loading/> }
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Materials);
