import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Component
import Promos from '@Shared/Promos';
import Loading from '@Shared/Loading';
import Button from '@Shared/Button';
import PanelMenu from '@Src/Panels/Menu';

// Actions =>
import { UUID, setFlyout, setPanel } from '@Src/Global/Actions';
import { getCleanCatalog, clearParams, setParams } from '@Src/Catalog/Actions';

// Icons
import { IoDiamond } from 'react-icons/io5';
import { BsChevronLeft } from 'react-icons/bs';

// Routing
import { createSearchParams, useNavigate } from 'react-router-dom';

const Categories = ({ compact = false, catalog: { categories }, getCleanCatalog, clearParams, setParams, setFlyout, setPanel }) => {
    const navigate = useNavigate();

    let imgSrc;
    useEffect(() => {
        if(!categories){
            getCleanCatalog();
        }
    }, []);

    const handleCategorySelection = (c) => {
        clearParams().then(() => {
            setParams({
                'category':  c,
            }).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`
                });
                setFlyout(false);
                setPanel(false);
            });
        });
    }

    const handleAllProducts = () => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            });
            setFlyout(false);
            setPanel(false);
        });
    }

    return (
        <div className='flyout-categories'>
            <h2 onClick={() => setPanel(<PanelMenu/>) }><IoDiamond color={'#F78721'} size={18}/> Nuestros productos <BsChevronLeft size={18}/></h2>
            <div className='categories-and-promos'>
                <div className='holder-categories'>
                    <p>Fusionamos creatividad, técnica y pasión para transformar metales, y así darle vida a colecciones únicas de joyería.</p>
                    { categories ?
                        categories.map((c) => {
                            imgSrc = (c.image_url.search('NO_IMAGE.jpg') !== -1) ? c.image_url : c.image_url+'/0_medium.webp';
                            return (
                                <a className='item' key={UUID()} onClick={() => handleCategorySelection(c.ext_name)}>
                                    <div className='img-holder' style={{ backgroundImage: 'url('+imgSrc+')'}}></div>
                                    <label>{c.ext_name}</label>
                                </a>
                            );
                        })
                    : <Loading/> }
                    <Button onClick={handleAllProducts} variant={'link'}>Ver todos los productos</Button>
                </div>
                {/*<Promos variant={(compact) ? 'compact' : false}/>*/}
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getCleanCatalog,
        clearParams,
        setParams,
        setFlyout,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Categories);
