import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Toastify:
import { toast } from "react-toastify";

// Actions
import { activatePromo } from '@Src/Cart/Actions';

// Components:
import Input from '@Shared/Input';
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';

const Promo = ({ activatePromo, icon = false }) => {
    // Form validation schema:
    const YupSchema = Yup.object().shape({
        code: Yup.string().required(' '),
    });

    return (
        <Formik
            initialValues={{
                code: '',
            }}
            validationSchema={YupSchema}
            onSubmit={(values,  { setSubmitting, setFieldValue, resetForm }) =>{
                activatePromo(values).then((res) => {
                    resetForm();
                    toast[res.type](res.message);
                    setSubmitting(false);
                });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <div className='promo-applier'>
                    <h2>¡Ingresa tu código de descuento!</h2>
                    <p>Aplica directo en todos los productos de la tienda.</p>

                    {! isSubmitting  ?
                        <React.Fragment>
                            <Input
                                label        = {'Ingresa tu código'}
                                placeholder  = {'ej. MAMA2021'}
                                name         = {'code'}
                                type         = {'text'}
                                value        = {values.code}
                                values       = {values}
                                onChange     = {handleChange}
                                errors       = {errors}
                                onFocus      = {handleBlur}
                                touched      = {touched}
                                autoComplete = 'new-password'
                                icon         = {(icon) ? <Button onClick={handleSubmit} variant={'primary'}>Aplicar</Button> : null }
                            />

                            { !icon ?
                                <Button onClick={handleSubmit} variant={'primary'}>Aplicar</Button>
                            : null }
                        </React.Fragment>
                    : <Loading/> }
                </div>
            )}
        </Formik>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        activatePromo,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Promo);
