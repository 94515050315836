import React, { useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Assets
import Icon from '@Assets/empty-box.gif';

// Components =>
import Keyword from '@Src/Search/Keyword';
import Button from '@Shared/Button';

// Actions
import { clearParams, setParams } from '@Src/Catalog/Actions';
import { createSearchParams, useNavigate } from 'react-router-dom';

const End = ({ clearParams, setParams, catalog: { perpage, params } }) => {
    const [keyword, setKeyword] = useState('');
    const navigate = useNavigate();
    const handleClear = () => {
        clearParams().then(() => {
            navigate({
                pathname: '/catalog',
                search: `?${createSearchParams({})}`
            })
        });
    }

    const callBack = (e) => {
        setKeyword(e.target.value);
    }

    const handleSearch = () => {
        let obj = {
            'keyword': keyword,
            'start' : 0,
            'end'   : perpage,
        };
        if(params.brand){
            obj.brand = params.brand;
        }
        clearParams().then(() => {
            setParams(obj).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`,
                });
            });
        });
    }

    const handleAction = () => {
        (!keyword) ? handleClear() : handleSearch()
    }

    return (
        <div className='catalog-end'>
            <h2>Estos son todos los productos  encontrados para tu búsqueda</h2>
            <img src={Icon}/>
            <Keyword focus={false} callBack={callBack}/>
            <Button onClick={handleAction} variant={(keyword) ? 'secondary' : 'default'}>{(keyword) ? 'Buscar' : 'Borrar búsqueda'}</Button>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        clearParams,
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(End);
