import React from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>

// Components =>

const Reasons = ({ }) => {
    return (
        <div className='business-reasons'>
            <h2>Razones para iniciar con Elements</h2>
            <h3>Te acompañamos en todo tu emprendimiento con nosotros</h3>
            <div className='blocks'>
                <section>
                    <h2>Coach</h2>
                    <p>Te asignamos un coach de negocios que siempre estará disponible para ti</p>
                </section>
                <section>
                    <h2>Catálogo</h2>
                    <p>Tendrás acceso a un nuevo catálogo y a colecciones exclusivas cada mes</p>
                </section>
                <section>
                    <h2>Asesoría</h2>
                    <p>Te asesoramos ya sea que tengas o no, un negocio establecido</p>
                </section>
                <section>
                    <h2>Historial</h2>
                    <p>Tu historial de compras te da beneficios para que impulses más tu negocio.</p>
                </section>
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Reasons);
