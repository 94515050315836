import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { UUID } from '@Src/Global/Actions';
 import { getCatalog, clearParams, setParams } from '@Src/Catalog/Actions';

// Components =>
import Button from '@Shared/Button';
import Loading from '@Shared/Loading';
import Search from '@Src/Search/Keyword';
import Product from '@Src/Product/Product';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// Routing
import { useNavigate, createSearchParams } from 'react-router-dom';

const Empty = ({ catalog: { products, perpage, params }, global: { wsize }, getCatalog, clearParams, setParams }) => {
    const navigate = useNavigate();
    const [shuffled, setShuffled] = useState(false);

    useEffect(() => {
        new Promise((resolve) => {
            if(products){
                resolve(products);
            } else {
                getCatalog().then((res) => {
                    resolve(res.items);
                });
            }
        }).then((items) => {
            setShuffled(items.sort(() => 0.5 - Math.random()).slice(0, 5));
        });
    }, []);



    const handleSearch = () => {
        let keyword = params.keyword;
        clearParams().then(() => {
            setParams({
                'keyword':  keyword,
                'start' : 0,
                'end'   : perpage,
            }).then((nparams) => {
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams(nparams)}`,
                });
            });
        });
    }

    return (
        <div className='cart-empty'>
            <div className={'cart-empty-message'}>
                <h1>Tu carrito está vacío</h1>
                <h2>Comienza a navegar en nuestra tienda y conoce nuestros productos</h2>
                <Search/>
                <Button variant={'primary'} onClick={handleSearch}>Buscar</Button>
            </div>
            <div className='shuffled-products'>
                <div className="slide-title">Tal vez te pueda interesar</div>
                { shuffled ?
                    <Swiper
                        className='holder'
                        spaceBetween={0}
                        //slidesPerView={'auto'}
                        slidesPerView={wsize.w > 1080 ? 5 : 2}
                    >
                        {shuffled.map((product) => {
                            return (
                                <SwiperSlide className={'slide-item'} key={UUID()}>
                                    <Product product={product}/>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                : <Loading/> }
            </div>
        </div>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getCatalog,
        clearParams,
        setParams,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Empty);
