import React from 'react';

// Redux =>
import { useSelector } from 'react-redux';

// Icons
import { FaWhatsapp } from 'react-icons/fa';

const WhatsApp = ({ }) => {
    const { phones }  = useSelector((state) => state.global);
    return (
        document.URL.search('/cart') === -1 ?
            <a target={'_blank'} href={`https://wa.me/${phones.whatsapp.clean}`} id="cta-whatsapp">
                <FaWhatsapp color={'#FFF'} size={26}/>
            </a>
        : null
    );
};

export default WhatsApp;
