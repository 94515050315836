import React, { useEffect, useState } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Actions =>
import { setStoreDistances, hasStore, changeStore, storeById } from "@Src/Global/Actions";

// Icons
import { IoClose } from 'react-icons/io5';

// Routing
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';

// Toastify:
import { toast } from "react-toastify";

// Components
import Loading from '@Shared/Loading';

const Closer = ({ setStoreDistances, hasStore, changeStore, storeById, global: { store } }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [closerStoreWarning, SetCloserStoreWarning] = useState(false);

    useEffect(() => {
        SetCloserStoreWarning(null);
        // Geolocation:
        function distance(lat1, lon1, lat2, lon2) {
            var p = 0.017453292519943295;    // Math.PI / 180
            var c = Math.cos;
            var a = 0.5 - c((lat2 - lat1) * p)/2 +
                    c(lat1 * p) * c(lat2 * p) *
                    (1 - c((lon2 - lon1) * p))/2;

            return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
        }
        const successCallback = (position) => {
            //const store = localStorage.getItem('store') ? parseInt(localStorage.getItem('store')) : false;
            var gdl = distance(position.coords.latitude, position.coords.longitude, 20.6738686, -103.3705184);
            var cdmx = distance(position.coords.latitude, position.coords.longitude, 19.3910036, -99.2840391);
            setStoreDistances(gdl, cdmx).then(() => {
                var closer = false;

                // I am in CDMX, but GDL distance is less to my geolocation
                if([1,6].includes(store) && (gdl < cdmx))
                closer = {"name" : "GDL", "id" : 10}

                // I am in GDL, but CDMX distance is less to my geolocation
                if([9,10].includes(store) && (gdl > cdmx))
                closer = {"name" : "CDMX", "id" : 1};

                console.log({'CDMX': cdmx, 'GDL': gdl, 'My Store': store});

                if(closer){
                    SetCloserStoreWarning(
                        <div id="closer-store-warning">
                                Parce que estás un poco lejos. Te recomendamos actualizar tu ubicación a
                                <b onClick={() => handleStoreChange(closer.id)  }>Tienda {closer.name}</b>
                                para tener una mejor experiencia de compra.
                            <button type="button" onClick={() => { SetCloserStoreWarning(false); hasStore(); }}><IoClose size={18} color={'#6C2300'}/></button>
                        </div>
                    );
                } else {
                    SetCloserStoreWarning(false);
                }
            });
        }
        const errorCallback = (error) => {
            console.log("Rejecetd Geolocation", error);
            hasStore();
        }
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    },[store, location.pathname, location.search]);


    const handleStoreChange = (id) => {
        id = parseInt(id);
        SetCloserStoreWarning(<div id="closer-store-warning"><Loading/></div>);
        changeStore(id).then(() => {
            if(location.pathname.search('/catalog') !== -1){
                navigate({
                    pathname: '/catalog',
                    search: `?${createSearchParams({store: id})}`,
                });
            }
            toast.info(<p>Ahora navegas en <b style={{ fontWeight: 700 }}>Tienda {storeById(id)}</b></p>);

            SetCloserStoreWarning(null);
        });
    }

    return (
        closerStoreWarning
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        setStoreDistances,
        changeStore,
        hasStore,
        storeById,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Closer);