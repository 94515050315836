import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components =>
import Order from '@Src/Orders/Order';
import Template from '@Src/User/Template';
import Card from '@Src/User/Card';
import Region from '@Src/User/Region';
import Seller from '@Src/User/Seller';
import PanelUser from '@Src/Panels/User';

// Actions
import { setPanel } from '@Src/Global/Actions';
import { getOrders } from '@Src/Orders/Actions';

// Icons
import { BsChevronLeft } from 'react-icons/bs';

const Profile = ({  orders: { items }, global: { wsize }, getOrders, setPanel }) => {

    useEffect(() => {
        getOrders();
    },[]);

    const handleBack = () => {
        if(wsize.w < 1080){
            setPanel(<PanelUser/>)
        } else {
            return false;
        }
    }

    return (
        <Template>
            <div className='user-profile'>
                <h1 onClick={handleBack}>Mi Perfil <BsChevronLeft size={18}/></h1>
                <div className='card'>
                    <Card/>
                </div>
                <div className='region-and-seller'>
                    <div className='holder-region is-holder'>
                        <h1>Tu región</h1>
                        <Region/>
                    </div>
                    <div className='holder-seller is-holder'>
                        <h1>Tu vendedor asignado</h1>
                        <Seller/>
                    </div>
                </div>
                <div className={'user-last-order'}>
                    <h1>Mi último pedido</h1>
                    { items ?
                        items.length > 0 ?
                            <Order order={items[0]}/>
                        :
                            <div className='card' style={{ backgroundColor: '#F2F2F2' }}>
                                <h2>Aún no cuentas con pedios realizados.</h2>
                            </div>
                    : null }
                </div>
            </div>
        </Template>
    );
};

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
        getOrders,
        setPanel,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Profile);
